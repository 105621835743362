import { Matrix } from "../types";
import fillArray from "../fillArray";
import getDimensions from "../getDimensions";
import getCol from "../getCol";

function rotateMatrix<T>(
  matrix: Matrix<T>,
  direction?: "clockwise" | "counter-clockwise",
): Matrix<T> {
  const { width } = getDimensions(matrix);

  switch (direction) {
    case "clockwise":
      return (
        fillArray(0, width)
          .map((_, index) => {
            const col = getCol(matrix, index);

            // We can only operate on a column that exists
            if (col) {
              return col.reverse();
            }

            return [];
          })
          // Filter out rows that are falsy. If a matrix's dimensions are
          // uneven, we'll have this occur when referencing a row or col
          // that does not exist.
          .filter((row) => Boolean(row) || row.length === 0)
      );
    case "counter-clockwise":
    default:
      return (
        fillArray(0, width)
          .map((_, index) => {
            // Grab the columns in reverse order
            const col = getCol(matrix, width - 1 - index);

            // We can only operate on a column that exists
            if (col) {
              return col;
            }

            return [];
          })
          // Filter out rows that are falsy. If a matrix's dimensions are
          // uneven, we'll have this occur when referencing a row or col
          // that does not exist.
          .filter((row) => Boolean(row) || row.length === 0)
      );
  }
}

export default rotateMatrix;
