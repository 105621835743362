import React from "react";
import { useRootStore, CurrentGameProvider } from "../../models/Root";
import { GameProps, GameType } from "./Game";
import { IGame } from "../../models/Game";

const withCurrentGame = (WrappedGame: GameType) => {
  return ({ game, ...rest }: { game?: IGame } & GameProps) => {
    const root = useRootStore();

    let currentGame = game;

    if (!currentGame) {
      currentGame = root.game;
    }

    return (
      <CurrentGameProvider value={currentGame}>
        <WrappedGame {...rest} />
      </CurrentGameProvider>
    );
  };
};

export default withCurrentGame;
