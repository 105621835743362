import addLocations from "./addLocations";
import areLocationsEqual from "./areLocationsEqual";
import { DIRECTIONS, DIRECTION_LOCATIONS } from "./directions";
import { Location } from "./types";

function getAreaClueNeighborDirections(
  location: Location,
  areaClueLocations: Location[],
): string[] {
  const neighborDirections: string[] = [];

  const upLocation = addLocations(DIRECTION_LOCATIONS.UP, location);
  const downLocation = addLocations(DIRECTION_LOCATIONS.DOWN, location);
  const leftLocation = addLocations(DIRECTION_LOCATIONS.LEFT, location);
  const rightLocation = addLocations(DIRECTION_LOCATIONS.RIGHT, location);

  if (
    areaClueLocations.some((otherLocation) =>
      areLocationsEqual(otherLocation, upLocation),
    )
  ) {
    neighborDirections.push(DIRECTIONS.UP);
  }

  if (
    areaClueLocations.some((otherLocation) =>
      areLocationsEqual(otherLocation, downLocation),
    )
  ) {
    neighborDirections.push(DIRECTIONS.DOWN);
  }

  if (
    areaClueLocations.some((otherLocation) =>
      areLocationsEqual(otherLocation, leftLocation),
    )
  ) {
    neighborDirections.push(DIRECTIONS.LEFT);
  }

  if (
    areaClueLocations.some((otherLocation) =>
      areLocationsEqual(otherLocation, rightLocation),
    )
  ) {
    neighborDirections.push(DIRECTIONS.RIGHT);
  }

  return neighborDirections;
}

export default getAreaClueNeighborDirections;
