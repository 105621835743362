import { ITile } from "../models/Tile";
import getLocation from "./getLocation";
import getNeighborLocations from "./getNeighborLocations";
import isPoi from "./isPoi";
import { Location, Matrix } from "./types";

function calcNeighboringPoiCount(matrix: Matrix<ITile>, location: Location) {
  const neighborLocations = getNeighborLocations(matrix, location);
  const neighboringPoiCount = neighborLocations.reduce(
    (count, neighborLocation) => {
      const neighbor = getLocation(matrix, neighborLocation);

      return isPoi(neighbor) ? count + 1 : count;
    },
    0,
  );

  return neighboringPoiCount;
}

export default calcNeighboringPoiCount;
