import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import { useRootStore } from "../../models/Root";
import Center from "../Center";

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 400px;

  font-family: ${(props) => props.theme.fonts.serifStack};

  @media (max-width: 600px) {
    transform: scale(0.8);
  }

  @media (max-height: 700px) {
    transform: scale(0.8);
  }

  @media (max-height: 520px) {
    transform: scale(0.7);
  }

  // Query to target iPhone 12 - scaling not
  // working as expected on this device
  @media only screen and (width: 390px) and (-webkit-device-pixel-ratio: 3) {
    transform: scale(0.7);
  }

  @media (max-height: 420px) {
    transform: scale(0.6);
  }
`;

const Modal = observer(({ children }: React.PropsWithChildren) => {
  const { app } = useRootStore();

  return (
    <ModalBackground
      onClick={() => {
        if (app.modal.state === "onboarding") {
          return;
        }

        app.modal.hide();
      }}
    >
      <Center>
        <ModalContent>{children}</ModalContent>
      </Center>
    </ModalBackground>
  );
});

export default Modal;
