function transformObject(object: object, transformer = (x: any) => x) {
  const entries = Object.entries(object);
  const transformedObject: any = {};

  for (let [key, value] of entries) {
    let transformedValue;

    if (typeof value === "object") {
      transformedValue = transformObject(value, transformer);
    } else {
      transformedValue = transformer(value);
    }

    transformedObject[key] = transformedValue;
  }

  return transformedObject;
}

export default transformObject;
