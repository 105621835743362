import { Location, Matrix } from "./types";

function mapMatrix<T1, T2>(
  cb: (value: T1, location: Location, matrix: Matrix<T1>) => T2,
  matrix: Matrix<T1>,
) {
  return matrix.map((array, row) =>
    array.map((value, col) => cb(value, { row, col }, matrix)),
  );
}

export default mapMatrix;
