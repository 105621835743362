import { IGameplaySettingsModel } from "../../models/Settings";

/**
 * Note: If you iframe a site on itself, the
 * parent will be the same as the current window.
 */
function isIFramedByOtherSite(settings?: IGameplaySettingsModel) {
  if (settings && settings.fakeAmu) {
    return true;
  }

  return window.location !== window.parent.location;
}

export default isIFramedByOtherSite;
