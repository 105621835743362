import * as React from "react";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../models/Root";
import Game from "./Game/Game";
import MainMenu from "./MainMenu/MainMenu";
import Loading from "./Loading";
import Splash from "./Splash";

type AppStateRouterProps = {
  setTheme: (theme: "loadingTheme" | "mainMenuTheme" | "defaultTheme") => void;
};

const AppStateRouter = observer(({ setTheme }: AppStateRouterProps) => {
  const root = useRootStore();

  React.useEffect(() => {
    root.app.startLoading();
  }, []);

  // TODO:
  // I get the sense this logic should probably be incorporated
  // into the App state model owned by MST. Not sure though
  // since it controls a React owned/managed Context. Maybe
  // this is a necessary translation layer between MST and React?
  React.useEffect(() => {
    switch (root.app.state) {
      case "splash":
      case "waitingForInitGame":
      case "loading":
        setTheme("loadingTheme");
        break;
      case "failed":
      case "mainMenu":
        setTheme("mainMenuTheme");
        break;
      default:
      case "resetting":
      case "game":
        setTheme("defaultTheme");
        break;
    }
  }, [root.app.state, root.game.state]);

  switch (root.app.state) {
    case "loading":
    case "waitingForInitGame":
      return <Loading />;
    case "splash":
      return <Splash />;
    case "failed":
    case "mainMenu":
      return <MainMenu />;
    case "resetting":
    case "game":
      return <Game onVictory={() => root.app.modal.show("stats")} />;
    default:
      return (
        <p>
          Something is wrong! This shouldn't render! Cannot render app state{" "}
          {root.app.state}.
        </p>
      );
  }
});

export default AppStateRouter;
