import { useEffect, useState } from "react";
import { useGameStore } from "../../models/Root";
import getDimensions from "../../utils/getDimensions";

const useIsVictoryFinished = ({ onVictory }: { onVictory?: () => void }) => {
  const game = useGameStore();
  const [victoryAnimFinishCount, setVictoryAnimFinishCount] = useState(0);

  useEffect(() => {
    const dimensions = getDimensions(game.grid.tiles);
    const tileCount = dimensions.width * dimensions.height;

    if (victoryAnimFinishCount >= tileCount) {
      setTimeout(() => game.hideVictory(onVictory), 250);
    }
  }, [victoryAnimFinishCount]);

  const finishAnimation = () => {
    setVictoryAnimFinishCount((prevCount) => prevCount + 1);
  };

  return finishAnimation;
};

export default useIsVictoryFinished;
