import getDimensions from "./getDimensions";
import { Location, Matrix } from "./types";

function isLocationInBounds<T>(matrix: Matrix<T>, location: Location) {
  const dimensions = getDimensions(matrix);

  if (location.row < 0) {
    return false;
  }

  if (location.row >= dimensions.height) {
    return false;
  }

  if (location.col < 0) {
    return false;
  }

  if (location.col >= dimensions.width) {
    return false;
  }

  return true;
}

export default isLocationInBounds;
