import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import images from "../../../utils/assets/images";
import BgContainer from "./BgContainer";
import ShrinkableFlexImage from "./ShrinkableFlexImage";
import BgRow from "./BgRow";

const TopRow = styled(BgRow)`
  align-items: start;
`;

const BottomRow = styled(BgRow)`
  align-items: end;
`;

const Sizer = styled.div`
  width: 80px;
`;

const BgVertical = observer(() => {
  return (
    <BgContainer>
      <TopRow>
        <ShrinkableFlexImage
          draggable={false}
          src={images["Caved-Title-Element-Mobile-TopLeft-NoNumbers.svg"]}
        />
        <Sizer />
      </TopRow>
      <BottomRow>
        <Sizer />
        <ShrinkableFlexImage
          draggable={false}
          src={images["Caved-Title-Element-Mobile-BottomRight-NoNumbers.svg"]}
        />
      </BottomRow>
    </BgContainer>
  );
});

export default BgVertical;
