async function loadFont(fontKey: string, fontSrc: string) {
  const fontFile = new FontFace(fontKey, `url(${fontSrc})`);

  return new Promise(async (resolve) => {
    try {
      await fontFile.load();
      document.fonts.add(fontFile);
      resolve(true);
    } catch (err) {
      console.error(`Font failed to load: ${fontKey} - ${fontSrc}`, err);
      resolve(false);
    }
  });
}

export default loadFont;
