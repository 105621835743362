import { IAmuState } from "./AmuMessage";
import messageAmu, {
  EventGameMessage,
  GameStateMessage,
  WindowLoadedGameMessage,
  messageAmuAsEvent,
} from "./messageAmu";
import { UserSettingsSnapshotType } from "./parseAmuMessage";

/**
 * Implementing the AMU messages listed here:
 * https://amuniversal.atlassian.net/wiki/spaces/PUZSOC/pages/3241411012/AMU+Game+Coding+Reference+Guide#solvedThemeWithGuesses-State
 */

// TODO:
// We should create a message context that bundles AMUState
// It can also handle the onEvent listener configured on AMU's end
// It will call all of these functions instead of us doing them directly

/**
 * Once the game is ready to start receiving data, it should
 * send its first communication to the page. No data will be
 * sent to the game before this confirmation message is sent.
 */
function sendAmuReadyMessage(state: IAmuState) {
  messageAmu(WindowLoadedGameMessage.create({ windowLoaded: true }));
}

/**
 * Triggered when the user starts a game session from the
 * menu (usually with a "Play" button). For this event,
 * details are optional.
 */
function sendAmuStartMessage(state: IAmuState) {
  messageAmuAsEvent(
    EventGameMessage.create({
      type: "start",
      timestamp: new Date(),
    }),
    state,
  );
}

// TODO:
// I think we don't have any concept of "paused" right now.
// Game has a .stop() that is unused right now.
// It could call into the time.stopTimer() as well?
// Then we could fire this event there?
// I'd prefer just to not use it at all! We'll see what's required.

/**
 * Triggered when the game is paused, typically because the
 * user interacted with the pause button, opened a menu, the
 * game window lost focus, or the timer stopped. For this
 * event, details are optional.
 */
function sendAmuPauseMessage(state: IAmuState) {
  messageAmuAsEvent(
    EventGameMessage.create({
      type: "pause",
      timestamp: new Date(),
    }),
    state,
  );
}

/**
 * Triggered when the game is playing again, typically because
 * the user interacted with the resume button, closed a menu,
 * the game window regained focus, or the timer started again.
 * For this event, details are optional.
 */
function sendAmuResumeMessage(state: IAmuState) {
  messageAmuAsEvent(
    EventGameMessage.create({
      type: "resume",
      timestamp: new Date(),
    }),
    state,
  );
}

/**
 * Triggered when the user finishes the game and the game over
 * screen is shown, or any other time when the game over screen
 * is shown (such as when reloading a completed game). After
 * finishing a game no other events are expected, except for
 * "replay". For this event, details are optional.
 *
 * When emitting the "end" event, isCompleted should be true.
 */
function sendAmuEndMessage(state: IAmuState) {
  messageAmuAsEvent(
    EventGameMessage.create({
      type: "end",
      timestamp: new Date(),
    }),
    state,
  );
}

/**
 * Triggered when the user chooses to replay a level from the
 * game over screen (usually with a "Reset" or "Play Again"
 * button). The game should set amuGame.state.resetLevel = true
 * and either:
 *
 * Return to the menu, allowing the user to initiate a new game
 * via the "Play" button and related "start" event.
 *
 * Reset the game board and data without returning to the menu,
 * and emit a new "start" event. When resetting the data, leave
 * amuGame.state.resetLevel = true.
 *
 * For this event, details are optional.
 */
function sendAmuReplayMessage(state: IAmuState) {
  state.setResetLevel(true);

  messageAmuAsEvent(
    EventGameMessage.create({
      type: "replay",
      timestamp: new Date(),
    }),
    state,
  );
}

// We do not implement "print" event

/**
 * Triggered when the user interacts with any help or tip in
 * the settings or menu. For this event, details are optional.
 */
function sendAmuHelpMessage(state: IAmuState) {
  messageAmuAsEvent(
    EventGameMessage.create({
      type: "help",
      timestamp: new Date(),
    }),
    state,
  );
}

// We do not implement "change mode" event

/**
 *  Triggered when the user updates the in-game settings, such
 * as to disable/enable sound. See loadSettings above for more
 * information about how this data is used.
 *
 * For this event, details are required if a setting should be
 * remembered and restored every time the user plays the game,
 * regardless of the current level. For instance, if the current
 * sound settings should persist between levels from one device
 * to the next, then it should be stored with this event.
 */
function sendAmuChangeSettingsMessage(
  state: IAmuState,
  settings: UserSettingsSnapshotType, // TODO: This should not be a snapshot if state is not?
) {
  messageAmuAsEvent(
    EventGameMessage.create({
      type: "change settings",
      timestamp: new Date(),
      details: {
        snapshot: settings,
      },
    }),
    state,
  );
}

/**
 * Triggered when the user finishes the in-game onboarding or
 * first time user experience.
 *
 * This should not trigger when onboarding is skipped, only
 * when it is fully completed.
 *
 * For this event, details are required if a setting should be
 * remembered and restored every time the user plays the game,
 * regardless of the current level. For instance, if the current
 * sound settings should persist between levels from one device
 * to the next, then it should be stored with this event.
 */
function sendAmuOnboardingCompleteMessage(
  state: IAmuState,
  settings: UserSettingsSnapshotType, // TODO: This should not be a snapshot if state is not?
) {
  messageAmuAsEvent(
    EventGameMessage.create({
      type: "onboarding complete",
      timestamp: new Date(),
      details: {
        snapshot: settings,
      },
    }),
    state,
  );
}

function sendAmuOnboardingSkipMessage(
  state: IAmuState,
  settings: UserSettingsSnapshotType, // TODO: This should not be a snapshot if state is not?
) {
  messageAmuAsEvent(
    EventGameMessage.create({
      type: "onboarding skip",
      timestamp: new Date(),
      details: {
        snapshot: settings,
      },
    }),
    state,
  );
}

function sendAmuStateMessage(state: IAmuState) {
  messageAmu(GameStateMessage.create({ state }));
}

// We do not implement "spot difference" event

// We do not implement "solve word" event

export {
  sendAmuReadyMessage,
  sendAmuStartMessage,
  sendAmuPauseMessage,
  sendAmuResumeMessage,
  sendAmuEndMessage,
  sendAmuReplayMessage,
  sendAmuHelpMessage,
  sendAmuChangeSettingsMessage,
  sendAmuOnboardingCompleteMessage,
  sendAmuOnboardingSkipMessage,
  sendAmuStateMessage,
};
