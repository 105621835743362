import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  flow,
  types,
} from "mobx-state-tree";
import getParentOrigin from "./getParentOrigin";
import requestJson from "../network/requestJson";
import requestText from "../network/requestText";
import GridModel from "../../models/Grid";
import ActionsModel from "../../models/Actions";
import TimerModel from "../../models/Timer";
import { MstUserSettingsSnapshot } from "./parseAmuMessage";

const AmuFile = types
  .model("AmuFile", {
    name: types.string,
    url: types.string,
    mimeType: types.string,
    originalFileName: types.string,
    state: types.optional(
      types.enumeration(["initial", "loading", "done", "error"]),
      "initial"
    ),
    contents: types.maybe(types.string),
  })
  .actions((self) => ({
    fetch: flow(function* fetch() {
      self.state = "loading";

      // TODO:
      // Assuming File URL is going to be relative to
      // parent origin for now.
      //
      // This might not be true in production. We might
      // need to detect between relative and absolute
      // URLs in production.
      //
      // Update on 9/9/23:
      // Looks like some changes happened and now URL
      // is a fully qualified URL with a protocol?
      // const parentOrigin = getParentOrigin();
      const result = yield requestText(self.url);

      if (!result.ok) {
        self.state = "error";
        console.error(result.error);
        return;
      }

      self.contents = result.value;
      self.state = "done";
    }),
    toJson() {
      if (self.state !== "done") {
        console.warn("Tried to JSONify a file in non-done status.");
        return null;
      }

      if (self.contents === undefined) {
        console.warn(
          "File is done, but contents are undefined. Cannot be turned to JSON."
        );
        return null;
      }

      try {
        return JSON.parse(self.contents);
      } catch (err) {
        console.error("File contents are not valid JSON.", err);
        return null;
      }
    },
  }));

export interface IAmuFile extends Instance<typeof AmuFile> {}

export const UserSettingsSnapshotDetails = types.model(
  "UserSettingsSnapshotDetails",
  {
    snapshot: MstUserSettingsSnapshot,
  }
);

export interface IUserSettingsSnapshotDetails
  extends Instance<typeof UserSettingsSnapshotDetails> {}

/**
 * Used to recreate current game progress. snapshot is
 * flexible and can store whatever is needed by the
 * game, including nested objects, arrays, and other
 * types of data.
 */
// TODO:
// Snapshotting this model is more information than needs
// to go over the wire to re-hydrate the game.
// This could benefit from a more "minimized" snapshot
// function like the level files have.
//
// MATCH TO parseAmuMessage.ts GameStateSnapshot
export const GameStateSnapshot = types.model("GameStateSnapshot", {
  appVersion: types.maybe(types.string),
  levelIssueDateString: types.maybe(types.string),
  hasDoneStartingRevealAnimation: types.optional(types.boolean, false),
  grid: GridModel,
  actions: ActionsModel,
  timer: TimerModel,
});

export interface IGameStateSnapshot
  extends Instance<typeof GameStateSnapshot> {}

export const AmuState = types
  .model("AmuState", {
    snapshot: types.maybe(GameStateSnapshot),
    /**
     * true if the user completed this level and the game
     * is final. The default value is false.
     *
     * When emitting the "end" event, isCompleted should
     * be true.
     */
    isCompleted: types.optional(types.boolean, false),
    /**
     * true if the user entered an incorrect value during
     * the game. The default value is false.
     */
    madeMistakes: types.optional(types.boolean, false),
    score: types.model({
      // TODO: I think _our_ score value will ONLY be a number
      value: types.union(types.string, types.number),
      // TODO: I think cluefinder will not use this? Unless it's like "12 clicks"?
      formattedScore: types.maybe(types.string),
    }),
    // In milliseconds
    totalPlayTime: types.number,
    resetLevel: types.optional(types.boolean, false),
    earnedPerfectScore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setResetLevel(newValue: boolean) {
      self.resetLevel = newValue;
    },
  }));

export interface IAmuState extends Instance<typeof AmuState> {}

export const EventGameMessageType = types.enumeration("EventGameMessageType", [
  "all",
  "start",
  "pause",
  "resume",
  "end",
  "replay",
  "print",
  "help",
  "change mode",
  "change settings",
  "spot difference",
  "solve word",
  "onboarding complete",
  "onboarding skip",
]);

const AmuMessage = types.model("AmuMessage", {
  initGame: types.maybe(types.literal(true)),
  loadLevel: types.maybe(
    types.model("LoadLevelCommand", {
      issueDate: types.string,
      files: types.array(AmuFile),
    })
  ),
  // TODO:
  // Config is AMU defined settings for
  // how the game plays.
  // This will be stuff like game mode
  // and visual theme.
  // ALL instances of the game will be
  // using this config.
  loadConfig: types.maybe(
    types.model("LoadConfigCommand", {
      name: types.string,
      files: types.array(AmuFile),
    })
  ),
  loadSettings: types.maybe(UserSettingsSnapshotDetails),
  loadState: types.maybe(AmuState),
  pauseGame: types.maybe(types.boolean),
  getState: types.maybe(types.boolean),
  onEvent: types.array(EventGameMessageType),
});

export interface IAmuMessage extends Instance<typeof AmuMessage> {}
export interface IAmuMessageSnapshotIn extends SnapshotIn<typeof AmuMessage> {}
export interface IAmuMessageSnapshotOut
  extends SnapshotOut<typeof AmuMessage> {}
export default AmuMessage;
