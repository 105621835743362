import { ITile } from "../models/Tile";
import calcNeighboringPoiCount from "./calcNeighboringPoiCount";
import getLocation from "./getLocation";
import { Location, Matrix } from "./types";

function isNumberClue(tiles: Matrix<ITile>, location: Location): boolean {
  const tile = getLocation(tiles, location);

  if (tile.type !== "empty") {
    return false;
  }

  const neighboringPoiCount = calcNeighboringPoiCount(tiles, location);

  return neighboringPoiCount > 0;
}

export default isNumberClue;
