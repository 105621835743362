import { Location, Matrix } from "./types";

function forEachMatrix<T1>(
  cb: (value: T1, location: Location, matrix: Matrix<T1>) => void,
  matrix: Matrix<T1>,
) {
  matrix.forEach((array, row) =>
    array.forEach((value, col) => cb(value, { row, col }, matrix)),
  );
}

export default forEachMatrix;
