const GAME_NAME = "Caved";
const STYLING = `
  background-color:purple;
  color:yellow;
  padding:0.2rem 0.4rem;
  border-radius:4px;
`;

function attachCavedLogger() {
  const boundLog = console.log.bind(console, "%c" + GAME_NAME, STYLING);
  console.log = boundLog;

  const boundError = console.error.bind(console, "%c" + GAME_NAME, STYLING);
  console.error = boundError;
}

export default attachCavedLogger;
