import { ITile } from "../models/Tile";
import doesClueIdentifyTreasure from "./doesClueIdentifyTreasure";
import getLocation from "./getLocation";
import getLocationsByType from "./getLocationsByType";
import { Location, Matrix } from "./types";

function getIdentifyingClueLocations(
  tiles: Matrix<ITile>,
  location: Location,
): Location[] {
  const tile = getLocation(tiles, location);

  if (tile.type !== "treasure") {
    return [];
  }

  const clueLocations = [
    ...getLocationsByType(tiles, "clue-arrow"),
    ...getLocationsByType(tiles, "clue-area"),
  ];

  return clueLocations.filter((clueLocation) =>
    doesClueIdentifyTreasure(tiles, clueLocation, location),
  );
}

export default getIdentifyingClueLocations;
