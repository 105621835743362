import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import createRandomString from "../utils/random/createRandomString";
import { ConfigGameplaySettings } from "../utils/amu/parseConfigFile";
import { areHintsEnabledDefaultValue } from "../utils/amu/constants";

const GenerationSettingsModel = types.model({
  seed: types.string,
  bombCount: types.number,
  treasureCount: types.number,
  width: types.number,
  height: types.number,
  startingArea: types.enumeration(["clue", "empty", "zero", "off"]),
  shouldGen: types.boolean,
  areaClueWeight: types.number,
});

const ParsModel = types
  .model({
    mon: types.number,
    tue: types.number,
    wed: types.number,
    thu: types.number,
    fri: types.number,
    sat: types.number,
    sun: types.number,
  })
  .actions((self) => ({
    setPar(
      key: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun",
      value: number
    ) {
      self[key] = value;
    },
  }));

export interface IParsModel extends Instance<typeof ParsModel> {}
export interface IParsSnapshotIn extends SnapshotIn<typeof ParsModel> {}

const GameplaySettingsModel = types
  .model({
    treasureReveal: types.boolean,
    bombPenalty: types.number,
    scoring: types.enumeration(["time", "clicks"]),
    revealAll: types.boolean,
    isDev: types.boolean,
    fakeAmu: types.boolean,
    showPause: types.boolean,
    hintsOptionDefault: types.boolean,
    showHintsOptionInSettingsMenu: types.boolean,
    pars: ParsModel,
    showScoreMedallion: types.boolean,
    showPlayWithFriendsModal: types.boolean,
  })
  .actions((self) => ({
    setTreasureReveal(value: boolean) {
      self.treasureReveal = value;
    },
    setBombPenalty(value: number) {
      self.bombPenalty = value;
    },
    setScoring(value: "time" | "clicks") {
      self.scoring = value;
    },
    setHintsOptionDefault(value: boolean) {
      self.hintsOptionDefault = value;
    },
    setShowHintsOptionInSettingsMenu(value: boolean) {
      self.showHintsOptionInSettingsMenu = value;
    },
    setPars(pars: IParsSnapshotIn) {
      self.pars.setPar("mon", pars.mon);
      self.pars.setPar("tue", pars.tue);
      self.pars.setPar("wed", pars.wed);
      self.pars.setPar("thu", pars.thu);
      self.pars.setPar("fri", pars.fri);
      self.pars.setPar("sat", pars.sat);
      self.pars.setPar("sun", pars.sun);
    },
    setShowScoreMedallion(value: boolean) {
      self.showScoreMedallion = value;
    },
    setShowPlayWithFriendsModal(value: boolean) {
      self.showPlayWithFriendsModal = value;
    },
  }));

export interface IGameplaySettingsModel
  extends Instance<typeof GameplaySettingsModel> {}

const SettingsModel = types
  .model({
    generation: GenerationSettingsModel,
    gameplay: GameplaySettingsModel,
  })
  .views((self) => ({
    get shouldGenLevel() {
      return self.generation.shouldGen || self.gameplay.isDev;
    },
  }))
  .actions((self) => ({
    /**
     * This function is what takes the gameplay settings from the config
     * file and actually sets them into the gameplay settings model for
     * the rest of the game to consume them.
     * @param gameplaySettings
     */
    loadConfigGameplaySettings(gameplaySettings: ConfigGameplaySettings) {
      self.gameplay.setTreasureReveal(gameplaySettings.treasureReveal);
      self.gameplay.setBombPenalty(gameplaySettings.bombPenalty);
      self.gameplay.setScoring(gameplaySettings.scoring);
      self.gameplay.setHintsOptionDefault(gameplaySettings.hintsOptionDefault);
      self.gameplay.setShowHintsOptionInSettingsMenu(
        gameplaySettings.showHintsOptionInSettingsMenu
      );
      self.gameplay.setPars(gameplaySettings.pars);
      self.gameplay.setShowScoreMedallion(gameplaySettings.showScoreMedallion);
      self.gameplay.setShowPlayWithFriendsModal(
        gameplaySettings.showPlayWithFriendsModal
      );
    },
  }));

export interface ISettings extends Instance<typeof SettingsModel> {}
export interface ISettingsSnapshotIn extends SnapshotIn<typeof SettingsModel> {}
export interface ISettingsSnapshotOut
  extends SnapshotOut<typeof SettingsModel> {}

const defaultSettings = SettingsModel.create({
  generation: {
    seed: createRandomString(8),
    bombCount: 15,
    treasureCount: 5,
    width: 10,
    height: 15,
    startingArea: "zero",
    shouldGen: false,
    areaClueWeight: 25,
  },
  gameplay: {
    treasureReveal: true,
    bombPenalty: 5,
    scoring: "clicks",
    revealAll: false,
    isDev: false,
    fakeAmu: false,
    showPause: true,
    hintsOptionDefault: areHintsEnabledDefaultValue,
    showHintsOptionInSettingsMenu: false,
    pars: {
      mon: 9,
      tue: 9,
      wed: 12,
      thu: 25,
      fri: 25,
      sat: 32,
      sun: 32,
    },
    showScoreMedallion: true,
    showPlayWithFriendsModal: true,
  },
});

export default SettingsModel;
export { defaultSettings };
