import { TilesModel } from "../../models/Grid";
import { generateInitialTiles3 } from "../generateInitialTiles";
import { setSeed } from "../random/rng";
import { GenerationSettings } from "../settings/defaultSettings";
import chooseStartLocation from "./chooseStartLocation";

function generateLevelData({ settings }: { settings: GenerationSettings }) {
  console.log(`Generating level with seed: ${settings.seed}`);
  setSeed(settings.seed);

  let initialTiles = generateInitialTiles3({
    bombCount: settings.bombCount,
    treasureCount: settings.treasureCount,
    width: settings.width,
    height: settings.height,
    areaClueWeight: settings.areaClueWeight,
  });

  if (!initialTiles) {
    console.error(`Failed to generate initial tiles!`);
    initialTiles = [[]];
  }

  const startLocation = chooseStartLocation({
    startingArea: settings.startingArea,
    tiles: TilesModel.create(initialTiles),
  });

  return {
    tiles: initialTiles,
    startLocation,
  };
}

export default generateLevelData;
