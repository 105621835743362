import * as React from "react";
import styled from "styled-components";
import { useGameStore } from "../models/Root";
import { IGrid } from "../models/Grid";
import { ITile } from "../models/Tile";
import { observer } from "mobx-react-lite";

import getDimensions from "../utils/getDimensions";
import mapMatrix from "../utils/mapMatrix";
import { Location } from "../utils/types";

type GridContainerProps = {
  width: number;
};

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: ${(props) =>
    `${props.theme.tileSize}px `.repeat(props.width)};
  line-height: ${(props) => `${props.theme.tileSize}px`};
  text-align: center;
  cursor: pointer;
  user-select: none;

  font-family: ${(props) => props.theme.fonts.boldStack};

  // Disable the blue highlight color that
  // mobile devices do when they hold down
  // on an element. For some reason user-select: none
  // does not prevent this.
  // https://stackoverflow.com/questions/21003535/anyway-to-prevent-the-blue-highlighting-of-elements-in-chrome-when-clicking-quic
  -webkit-tap-highlight-color: transparent;
`;

type GridProps<T> = {
  renderTile: (tile: T, location: Location, grid: IGrid) => React.ReactNode;
};

const Grid = observer(({ renderTile = () => null }: GridProps<ITile>) => {
  const { grid } = useGameStore();
  const { width } = getDimensions(grid.tiles);

  return (
    <GridContainer
      width={width}
      onContextMenu={(event) => event.preventDefault()}
    >
      {mapMatrix<ITile, React.ReactNode>(
        (tile, location) => renderTile(tile, location, grid),
        grid.tiles
      )}
    </GridContainer>
  );
});

export default Grid;
