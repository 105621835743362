import { ITile } from "../models/Tile";
import areLocationsEqual from "./areLocationsEqual";
import { DIRECTIONS, DIRECTION_LOCATIONS } from "./directions";
import getAreaClueLocations from "./getAreaClueLocations";
import getLocation from "./getLocation";
import getLocationsInDirection from "./getLocationsInDirection";
import { Location, Matrix } from "./types";

function doesClueIdentifyTreasure(
  tiles: Matrix<ITile>,
  clueLocation: Location,
  treasureLocation: Location,
): boolean {
  const clue = getLocation(tiles, clueLocation);
  const treasure = getLocation(tiles, treasureLocation);

  if (treasure.type !== "treasure") {
    return false;
  }

  switch (clue.type) {
    case "clue-arrow":
      if (!clue.arrowDirection) {
        return false;
      }

      const locationsInDirection = getLocationsInDirection(
        clueLocation,
        DIRECTION_LOCATIONS[clue.arrowDirection],
        tiles,
      );

      return locationsInDirection.some((locationInDirection) =>
        areLocationsEqual(locationInDirection, treasureLocation),
      );
    case "clue-area":
      if (!clue.areaOffsetLocations) {
        return false;
      }

      const areaLocations = getAreaClueLocations(tiles, clueLocation);
      return areaLocations.some((areaLocation) =>
        areLocationsEqual(areaLocation, treasureLocation),
      );
    default:
      // This is not a clue
      return false;
  }
}

export default doesClueIdentifyTreasure;
