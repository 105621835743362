import getParentOrigin from "./getParentOrigin";

// TODO:
// Set up typed messages instead of any
//
// It looks like there are types i could maybe use
// from the wiki page?
function messageParent(message: any) {
  const parentOrigin = getParentOrigin();

  console.log(message?.amuGame?.event?.type, { message });

  try {
    window.parent.postMessage(message, parentOrigin);
  } catch (error) {
    console.error(error);
  }
}

export default messageParent;
