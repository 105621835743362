import { IGrid } from "../../models/Grid";
import { ITile } from "../../models/Tile";
import forEachMatrix from "../forEachMatrix";

function getPlacedFlags(grid: IGrid) {
  const tiles: ITile[] = [];

  forEachMatrix<ITile>((tile) => {
    if (tile.marking === "flagged") {
      tiles.push(tile);
    }
  }, grid.tiles);

  return tiles;
}

export default getPlacedFlags;
