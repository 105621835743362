import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import { useRootStore } from "../../models/Root";
import images from "../../utils/assets/images";
import Center from "../Center";
import ImageButton from "../ImageButton";
import Modal from "./Modal";
import ModalBackground from "./ModalBackground";
import useStats from "../../utils/game/useStats";

const ModalHeader = styled.div<{ isPerfectScore: boolean }>`
  position: relative;

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  background-color: ${(props) => {
    return props.isPerfectScore
      ? props.theme.colors.perfectScoreHeader
      : props.theme.colors.modal.text;
  }};
  color: ${(props) => {
    return props.isPerfectScore
      ? props.theme.colors.modal.text
      : props.theme.colors.modal.background;
  }};

  padding: 2rem;

  h1 {
    margin: 0;
    margin-top: 0.75rem;
    text-transform: uppercase;
  }

  p {
    font-size: 0.9em;
    opacity: 0.75;
    margin: 0;
  }
`;

const ModalBody = styled.div`
  position: relative;
  padding: 2rem 4rem;

  overflow: hidden;

  z-index: 1;
`;

const StatContainer = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  padding: 1rem;

  table {
    width: 100%;
  }

  tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1rem;
  }

  tr:last-child {
    margin: 0;
  }

  td:first-child {
    text-align: left;
  }

  td:last-child {
    text-align: right;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;

  padding: 1rem;

  a,
  button {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    border-radius: 999em;
    border: 2px solid ${(props) => props.theme.colors.modal.text};

    background: transparent;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;

    min-height: 45px;
    text-decoration: none;

    img {
      margin-left: 1rem;
    }
  }

  a:first-child,
  button:first-child {
    font-size: 0.85rem;
  }

  a:last-child,
  button:last-child {
    background-color: ${(props) => props.theme.colors.modal.text};
    color: ${(props) => props.theme.colors.modal.background};
  }
`;

const MovesContainer = styled.div`
  padding-bottom: 1rem;

  > *:first-child {
    font-size: 4rem;
    font-weight: bold;
  }

  > *:last-child {
    opacity: 0.75;
  }

  & > * {
    margin: 0;
  }
`;

const CloseButton = styled(ImageButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const BackgroundBadge = styled.img`
  position: absolute;
  left: -136px;
  bottom: -52px;

  pointer-events: none;
  user-select: none;

  z-index: -1;
`;

const CoinFront = styled.img`
  animation-name: flipToBack;
`;

const CoinBack = styled.div`
  transform: rotateY(180deg);

  backface-visibility: hidden;

  animation-name: flipToFront;
  animation-duration: 500ms;
  animation-delay: 1000ms;
  animation-fill-mode: forwards;

  & > div {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    color: ${(props) => props.theme.colors.coin.dark};

    p {
      font-size: 0.9rem;
      margin: 0;
    }

    p:last-child {
      font-size: 1.9rem;
      font-weight: bold;
    }
  }
`;

const CoinContainer = styled.div`
  position: absolute;
  top: 104px;
  right: 84px;

  z-index: 2;

  & > * {
    position: absolute;

    backface-visibility: hidden;
    animation-duration: 500ms;
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
  }
`;

const StatsModal = observer(() => {
  const { app } = useRootStore();

  const {
    score,
    revealedBombCount,
    flagAccuracy,
    time,
    revealedClueCount,
    allClueCount,
    isPerfectScore,
  } = useStats();

  return (
    <Modal>
      <ModalBackground className="slideInUp">
        <ModalHeader isPerfectScore={isPerfectScore}>
          <CloseButton
            src={images["ic_add_circle_outline_24px.svg"]}
            onClick={() => app.modal.hide()}
          />
          <h1>
            {isPerfectScore
              ? app.text.statsModalPerfectScoreTitle
              : app.text.statsModalNormalScoreTitle}
          </h1>
          <p>
            {isPerfectScore
              ? app.text.statsModalPerfectScoreBody
              : app.text.statsModalNormalScoreBody}
          </p>
        </ModalHeader>
        {app.settings.gameplay.showScoreMedallion ? (
          <CoinContainer>
            <CoinFront src={images["badge-gold.svg"]} />
            <CoinBack>
              <img src={images["WinScreenMedallion.svg"]} />
              <Center>
                <p
                  dangerouslySetInnerHTML={{
                    __html: app.html.coinParagraph ?? "",
                  }}
                />
                <p>
                  +
                  {isPerfectScore
                    ? app.text.societyPointsPerfectScore
                    : app.text.societyPointsNormalScore}
                </p>
              </Center>
            </CoinBack>
          </CoinContainer>
        ) : null}
        <ModalBody>
          <MovesContainer>
            <p>{score}</p>
            <p>{app.text.statsModalScoreLabel}</p>
          </MovesContainer>
          <StatContainer>
            <table>
              <tbody>
                <tr>
                  <td>{app.text.statsModalRevealedBombCountLabel}</td>
                  <td>{revealedBombCount}</td>
                </tr>
                {/* <tr>
                  <td>Flag Accuracy:</td>
                  <td>{flagAccuracy}%</td>
                </tr> */}
                <tr>
                  <td>{app.text.statsModalTimeLabel}</td>
                  <td>{time}</td>
                </tr>
                <tr>
                  <td>{app.text.statsModalCluesRevealedLabel}</td>
                  <td>
                    {revealedClueCount}/{allClueCount}
                  </td>
                </tr>
              </tbody>
            </table>
          </StatContainer>
          <ButtonContainer>
            <button
              onClick={() => {
                app.modal.show("share");
              }}
            >
              {app.text.statsModalShareWithAFriend}{" "}
              <img src={images["ic_share_24px.svg"]} />
            </button>
            <a href={app.text.moreGamesUrl} target="_blank" rel="noopener">
              {app.text.statsModalMoreGames}{" "}
              <img src={images["play-button.svg"]} />
            </a>
          </ButtonContainer>
          <BackgroundBadge src={images["badge-gray.svg"]} />
        </ModalBody>
      </ModalBackground>
    </Modal>
  );
});

export default StatsModal;
