import { RefObject, useLayoutEffect, useCallback, useState } from "react";

const getWindowWidth = () => window.visualViewport?.width ?? window.innerWidth;
const getWindowHeight = () =>
  window.visualViewport?.height ?? window.innerHeight;

const getScaleRatios = (
  node: HTMLElement,
  parent: { width: number; height: number }
) => {
  const nodeHeight = node.clientHeight;
  const nodeWidth = node.clientWidth;
  const parentHeight = parent.height;
  const parentWidth = parent.width;

  const widthRatio = parentWidth / nodeWidth;
  const heightRatio = parentHeight / nodeHeight;

  return {
    width: widthRatio,
    height: heightRatio,
  };
};

const scaleToFitWindow = (
  node: HTMLElement | null,
  parent: HTMLElement | null,
  scaleRegion?: HTMLElement | null
) => {
  // Scale region being undefined is okay, that
  // means no ref was set. If it is null a ref
  // was set and is wrong though!
  if (!node || !parent || scaleRegion === null) {
    // If no node or parent is provided, do nothing
    return;
  }

  // const { clientWidth, clientHeight } = node;
  // const { innerWidth, innerHeight, visualViewport } = window;

  // TODO:
  // I would like to respect the App Margin in "real terms"
  // I don't want to remove the margin from the scaled scene
  // I don't want to scale the margin on this element
  //
  // I think MAYBE what I actually want to do is somehow
  // have the REST of the document pretend I'm at my scaled
  // size and not my original size....?
  //
  // I think we need two things
  // - build in app padding to reduce our scaled size
  // - let the rest of app treat this element as scaled down
  // const app = document.querySelector<HTMLDivElement>("#app");

  // TODO: Make this a larger config setting
  const APP_PADDING = 16;

  const nodeHeight = node.clientHeight;
  const nodeWidth = node.clientWidth;

  const { width: widthRatio, height: heightRatio } = getScaleRatios(node, {
    width: scaleRegion ? scaleRegion.clientWidth : getWindowWidth(),
    height: scaleRegion ? scaleRegion.clientHeight : getWindowHeight(),
  });

  const widthScaleToFit = widthRatio;
  const heightScaleToFit = heightRatio;

  const smallerScale = Math.min(widthScaleToFit, heightScaleToFit);

  node.style.transform = `scale(${smallerScale})`;
  parent.style.width = smallerScale * nodeWidth + "px";
  parent.style.height = smallerScale * nodeHeight + "px";
};

const useScaleRef = <T extends HTMLElement>({
  onScale = () => {},
  scaleRegionRef,
}: {
  onScale?: (newWindowWidth: number) => void;
  scaleRegionRef?: RefObject<HTMLElement>;
}) => {
  const [scaleNode, setScaleNode] = useState<T | null>(null);
  const [parentNode, setParentNode] = useState<T | null>(null);
  const scaleRef = useCallback((node: T | null) => {
    if (node) {
      setScaleNode(node);
    }
  }, []);
  const parentRef = useCallback((node: T | null) => {
    if (node) {
      setParentNode(node);
    }
  }, []);

  useLayoutEffect(() => {
    if (!scaleNode || !parentNode) {
      return;
    }

    const scaleToFitWindowWithRef = () => {
      onScale(window.visualViewport?.width ?? window.innerWidth);
      scaleToFitWindow(scaleNode, parentNode, scaleRegionRef?.current);
    };

    window.addEventListener("resize", scaleToFitWindowWithRef);

    const resizeObserver = new ResizeObserver(() => {
      // If any element that the resize observer sees
      // changes size, we should re-size the whole game
      // to make sure everything fits now.
      scaleToFitWindowWithRef();
    });

    if (scaleRegionRef?.current) {
      resizeObserver.observe(scaleRegionRef.current);
      resizeObserver.observe(scaleNode);
    }

    return () => {
      window.removeEventListener("resize", scaleToFitWindowWithRef);
      resizeObserver.disconnect();
    };
  }, [scaleNode, parentNode]);

  return [scaleRef, parentRef];
};

export default useScaleRef;
