import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRootStore } from "../models/Root";
import images from "../utils/assets/images";

const Loading = observer(() => {
  const { app } = useRootStore();
  const isTransitioningOut = app.transitioningTo !== undefined;

  return (
    <img
      src={images["loading.png"]}
      className={isTransitioningOut ? "fadeOut" : "fadeIn"}
      onAnimationEnd={() => {
        app.finishTransition();
      }}
    />
  );
});

export default Loading;
