import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import { useRootStore } from "../../models/Root";
import Modal from "./Modal";
import ModalBackground from "./ModalBackground";
import times from "../../utils/times";
import images from "../../utils/assets/images";

const Container = styled.div`
  .onboarding {
    background-color: ${(props) =>
      props.theme.colors.onboardingModal.background};
    color: ${(props) => props.theme.colors.onboardingModal.text};

    height: 575px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const ModalInner = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;

  p {
    text-align: left;
    font-size: 1rem;
    line-height: 1.2rem;
  }

  p:last-child {
    margin: 0;
  }

  strong {
    color: ${(props) => props.theme.colors.onboardingModal.textStrong};
    font-weight: bold;
  }

  svg:not(.no-resize) {
    width: 175px;
    height: 175px;
  }

  svg.no-resize {
    width: 36px;
    height: 36px;
  }
`;

const TileBg = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyTile = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.tile.hidden};
`;

const TileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  *:nth-child(2) {
    margin-left: 1rem;
  }
`;

const SlideWelcome = () => {
  const { app } = useRootStore();

  return (
    <ModalInner>
      <h3 style={{ marginBottom: 0 }}>{app.text.onboardingModalWelcome}</h3>
      <img style={{ maxWidth: "300px" }} src={images["Caved -Title_SVG.svg"]} />
      <p
        dangerouslySetInnerHTML={{
          __html: app.html.onboardingModalSlide1Paragraph1 ?? "",
        }}
      />
      <TileRow>
        <TileBg>
          <EmptyTile />
        </TileBg>
      </TileRow>
      <p
        dangerouslySetInnerHTML={{
          __html: app.html.onboardingModalSlide1Paragraph2 ?? "",
        }}
      />
      <TileRow>
        <TileBg>
          <SVG
            className="no-resize"
            width={48}
            height={48}
            cacheRequests
            src={images["crystal.svg"]}
          />
        </TileBg>
        <TileBg>
          <SVG
            className="no-resize"
            width={48}
            height={48}
            cacheRequests
            src={images["skull.svg"]}
          />
        </TileBg>
      </TileRow>
    </ModalInner>
  );
};

const SlideItemClues = () => {
  const { app } = useRootStore();

  return (
    <ModalInner>
      <SVG cacheRequests src={images["FTUE_Slide_2.svg"]} />
      <div
        dangerouslySetInnerHTML={{
          __html: app.html.onboardingModalSlide2Body ?? "",
        }}
      />
    </ModalInner>
  );
};

const SlideFlag = () => {
  const { app } = useRootStore();

  return (
    <ModalInner>
      <SVG cacheRequests src={images["FTUE_Slide_3.svg"]} />
      <div
        dangerouslySetInnerHTML={{
          __html: app.html.onboardingModalSlide3Body ?? "",
        }}
      />
    </ModalInner>
  );
};

const SlideFlag2 = () => {
  const { app } = useRootStore();

  return (
    <ModalInner>
      <SVG cacheRequests src={images["FTUE_Slide_4.svg"]} />
      <div
        dangerouslySetInnerHTML={{
          __html: app.html.onboardingModalSlide4Body ?? "",
        }}
      />
    </ModalInner>
  );
};

const SlideTreasureClues = () => {
  const { app } = useRootStore();

  return (
    <ModalInner>
      <SVG cacheRequests src={images["FTUE_Slide_5.svg"]} />
      <div
        dangerouslySetInnerHTML={{
          __html: app.html.onboardingModalSlide5Body ?? "",
        }}
      />
    </ModalInner>
  );
};

const SlidePlay = () => {
  const { app } = useRootStore();

  return (
    <ModalInner>
      <SVG cacheRequests src={images["FTUE_Slide_6.svg"]} />
      <div
        dangerouslySetInnerHTML={{
          __html: app.html.onboardingModalSlide6Body ?? "",
        }}
      />
    </ModalInner>
  );
};

const slides = [
  SlideWelcome,
  SlideItemClues,
  SlideFlag,
  SlideFlag2,
  SlideTreasureClues,
  SlidePlay,
];

type ModalControlsProps = {
  next: () => void;
  prev: () => void;
  goTo: (newIndex: number) => void;
  skip: () => void;
  selectedIndex: number;
  totalCount: number;
};

const ModalControlsContainer = styled.div`
  padding: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button.primary {
    background-color: ${(props) =>
      props.theme.colors.onboardingModal.primaryButton};
    color: ${(props) => props.theme.colors.onboardingModal.radioNotActive};
    border-radius: 6px;
  }

  button {
    border: none;
    font-family: ${(props) => props.theme.fonts.monoStack};
    color: ${(props) => props.theme.colors.onboardingModal.text};
    background-color: transparent;
    font-weight: bold;
    padding: 0.75rem 2rem;
  }
`;

const RadioList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  justify-content: center;

  li {
    width: 1.35rem;
    height: 0.5rem;
    background-color: ${(props) =>
      props.theme.colors.onboardingModal.radioNotActive};
    border-radius: 6px;
    margin-right: 0.5rem;

    &.selected {
      background-color: ${(props) =>
        props.theme.colors.onboardingModal.radioActive};
      width: 3rem;
    }
  }
`;

const ModalControls = ({
  selectedIndex,
  totalCount,
  next,
  prev,
  goTo,
  skip,
}: ModalControlsProps) => {
  const SkipButton = (
    <button key="skip" onClick={skip}>
      Skip
    </button>
  );
  const BackButton = (
    <button key="back" onClick={prev}>
      {"< Back"}
    </button>
  );
  const NextButton = (
    <button key="next" onClick={next} className="primary">
      Next
    </button>
  );
  const PlayButton = (
    <button key="play" onClick={next} className="primary">
      Play
    </button>
  );

  let buttons = [BackButton, NextButton];

  if (selectedIndex === 0) {
    buttons = [SkipButton, NextButton];
  }

  if (selectedIndex === totalCount - 1) {
    buttons = [BackButton, PlayButton];
  }

  return (
    <div>
      <RadioList>
        {times(
          (index) => (
            <li
              className={selectedIndex === index ? "selected" : ""}
              key={index}
            ></li>
          ),
          totalCount
        )}
      </RadioList>
      <ModalControlsContainer>{buttons}</ModalControlsContainer>
    </div>
  );
};

const OnboardingModal = observer(() => {
  const { app } = useRootStore();
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

  const CurrentSlide = slides[currentSlideIndex];

  return (
    <Modal>
      <Container>
        <ModalBackground className="onboarding">
          <CurrentSlide />
          <ModalControls
            selectedIndex={currentSlideIndex}
            totalCount={slides.length}
            goTo={(newIndex: number) => setCurrentSlideIndex(newIndex)}
            next={() => {
              const isLastSlide = currentSlideIndex + 1 === slides.length;

              if (isLastSlide) {
                app.finishOnboarding();
                app.modal.hide();
                app.startGame();
              } else {
                setCurrentSlideIndex(currentSlideIndex + 1);
              }
            }}
            prev={() => {
              const isFirstSlide = currentSlideIndex === 0;

              if (!isFirstSlide) {
                setCurrentSlideIndex(currentSlideIndex - 1);
              }
            }}
            skip={() => {
              app.modal.hide();
              app.skipOnboarding();

              app.startGame();
            }}
          />
        </ModalBackground>
      </Container>
    </Modal>
  );
});

export default OnboardingModal;
