import { IGame } from "../../models/Game";
import { ISettings } from "../../models/Settings";
import clamp from "../clamp";
import getRevealedBombs from "./getRevealedBombs";

function calculateScore({
  game,
  settings,
  isInitialized,
}: {
  game: IGame;
  settings: ISettings;
  isInitialized: boolean;
}) {
  const { grid, timer, actions } = game;

  let score = 0;

  if (!isInitialized) {
    // Short circuit if not initialized yet.
    return score;
  }

  switch (settings.gameplay.scoring) {
    case "time":
      score = timer.getElapsedTimeSeconds();
      break;
    case "clicks":
      score = actions.count;
      break;
  }

  const revealedBombs = getRevealedBombs(grid);
  score += revealedBombs.length * settings.gameplay.bombPenalty;

  return score;
}

export default calculateScore;
