import merge from "lodash.merge";
import { DefaultTheme } from "styled-components";
import loadingTheme from "./loadingTheme";
import { colors } from "./defaultTheme";

const mainMenuTheme: DefaultTheme = merge({}, loadingTheme, {
  colors: {
    background: colors.black,
  },
});

export default mainMenuTheme;
