import * as React from "react";
import styled from "styled-components";

type ToggleContainerProps = {
  value: boolean;
};

const ToggleContainer = styled.div<ToggleContainerProps>`
  display: flex;

  // Hide the original checkbox
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  // Style the label like a toggle background
  label {
    cursor: pointer;
    width: 4rem;
    height: 2rem;
    background: ${(props) =>
      props.theme.colors.settings.toggleBackground}; // Untoggled color here
    display: block;
    border-radius: 1rem;
    position: relative;
  }

  // Create the toggle slider
  label:after {
    content: "${(props) => (props.value ? "ON" : "OFF")}";
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: 2.5rem;
    height: 1.5rem;
    background: ${(props) => props.theme.colors.settings.toggleElement};
    border-radius: 0.75rem;
    transition: 0.3s;

    color: ${(props) => props.theme.colors.settings.toggleText};
    font-size: 0.85rem;
    line-height: 1.5rem;
    text-align: center;
  }

  // Apply activated color to the toggle
  input:checked + label {
    /* background: ${(props) => props.theme.colors.positive}; */
  }

  // Use CSS transform to move toggle slider
  // almost to end of the toggle.
  input:checked + label:after {
    left: calc(100% - 0.25rem);
    transform: translateX(-100%);
  }

  // Stretch width of slider while it moves
  label:active:after {
    width: 3rem;
  }
`;

type ToggleProps = {
  id: string;
  onToggle: (newValue: boolean) => void;
  value: boolean;
};

const Toggle = ({ id, onToggle, value }: ToggleProps) => {
  return (
    <ToggleContainer value={value}>
      <input
        type="checkbox"
        id={id}
        checked={value}
        onChange={(event) => {
          onToggle(event.target.checked);
        }}
      />
      <label htmlFor={id}></label>
    </ToggleContainer>
  );
};

export default Toggle;
