import React from "react";
import { observer } from "mobx-react-lite";
import ReviewBar from "./ReviewBar";
import { useGameStore, useRootStore } from "../../models/Root";
import images from "../../utils/assets/images";
import Settings from "../Settings";
import useWindowOrientation from "../../utils/breakpoints/useWindowOrientation";

const TopBar = observer(() => {
  const { app } = useRootStore();
  const game = useGameStore();
  const windowOrientation = useWindowOrientation();

  return (
    <ReviewBar>
      {game.state === "review" && game.shouldShowReviewBar ? (
        <>
          <button
            onClick={() => {
              app.modal.show("reset");
            }}
          >
            {app.text.topBarResetButton}{" "}
            <img src={images["arrow-counterclockwise.svg"]} />
          </button>
          <button onClick={() => app.modal.show("stats")}>
            {app.text.topBarResultsButton} <img src={images["star-fill.svg"]} />
          </button>
        </>
      ) : null}
      {windowOrientation === "landscape" ? <Settings embedded /> : null}
    </ReviewBar>
  );
});

export default TopBar;
