import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  getParent,
  getParentOfType,
  getSnapshot,
  types,
} from "mobx-state-tree";
import floodableTypes from "../utils/floodableTypes";
import getLocation from "../utils/getLocation";
import getNeighborLocations from "../utils/getNeighborLocations";
import { Location, Matrix } from "../utils/types";
import wait from "../utils/wait";
import ActionsModel from "./Actions";
import GridModel, { IGrid, LocationModel } from "./Grid";
import { ITile, ITileSnapshotIn } from "./Tile";
import TimerModel from "./Timer";
import createFloodFillForGrid from "../utils/createFloodFillForGrid";
import getLocationsByType from "../utils/getLocationsByType";
import manhattanDistance from "../utils/manhattanDistance";
import { Settings } from "../utils/settings/defaultSettings";
import isLocationInBounds from "../utils/isLocationInBounds";
import { GameStateSnapshot, IGameStateSnapshot } from "../utils/amu/AmuMessage";
import rotateLocationRelativeToDimensions from "../utils/matrix/rotateLocation";
import getDimensions from "../utils/getDimensions";
import { ISettings } from "./Settings";
import calculateGameOrientation from "../utils/breakpoints/calculateGameOrientation";
import { RootModel } from "./Root";
import packageInfo from "../../package.json";
import { sounds } from "../utils/assets/audio";
import isNumberClue from "../utils/isNumberClue";
import doStartingRevealAnimation from "../utils/game/doStartingRevealAnimation";

const GameModel = types
  .model({
    state: types.optional(
      types.enumeration(["running", "stopped", "victory", "review"]),
      "stopped"
    ),
    grid: GridModel,
    timer: TimerModel,
    actions: ActionsModel,
    gameOverCondition: types.optional(
      types.enumeration(["allTreasuresFound", "allBombsFound"]),
      "allTreasuresFound"
    ),
    shouldShowReviewBar: types.optional(types.boolean, true),
    inputModel: types.optional(
      types.enumeration(["click", "tapToMark", "tapToReveal"]),
      "tapToReveal"
    ),
    longPressThreshold: types.optional(types.number, 250),
    orientation: types.optional(
      types.enumeration(["vertical", "horizontal"]),
      "vertical"
    ),
    startLocation: types.maybe(LocationModel),
    shouldHintOnPrimaryAction: types.optional(types.boolean, false),
    startingFromState: types.optional(types.boolean, false),
    hasDoneStartingRevealAnimation: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get areAllTreasuresFound() {
      const { grid } = self;

      const allTreasureLocations = getLocationsByType(grid.tiles, "treasure");

      return allTreasureLocations.every((location) => {
        const treasure = getLocation(grid.tiles, location);

        return treasure.isRevealed;
      });
    },
    get areAllBombsFound() {
      const { grid } = self;

      const allBombLocations = getLocationsByType(grid.tiles, "bomb");

      return allBombLocations.every((location) => {
        const bomb = getLocation<ITile>(grid.tiles, location);

        return bomb.isRevealed || bomb.isFlagged;
      });
    },
    getVerticalAlignedTiles(grid: IGrid) {
      let tiles = getSnapshot(grid.tiles);

      // We always need to generate levelFiles
      // from the vertical orientation.
      // If we're not already vertical, copy ourselves
      // and then generate from the vertical copy.
      if (self.orientation === "horizontal") {
        const gameSnapshot = getSnapshot(self);
        const tempGame = GameModel.create(gameSnapshot);
        tempGame.setOrientation("vertical");
        tiles = getSnapshot(tempGame.grid.tiles);
      }

      return tiles;
    },
  }))
  .views((self) => ({
    get isGameOver() {
      const { gameOverCondition } = self;

      switch (gameOverCondition) {
        case "allTreasuresFound":
          return self.areAllTreasuresFound;
        case "allBombsFound":
          return self.areAllBombsFound;
        default:
          return false;
      }
    },
    // effectiveStartLocation takes into account
    // that the original file generation is ALWAYS
    // done in vertical.
    // This way the game can adjust for the initial
    // render being in horizontal mode.
    get effectiveStartLocation() {
      if (!self.startLocation) {
        return;
      }

      let effectiveStartLocation = self.startLocation;

      if (self.orientation === "horizontal") {
        // The level data is generated in vertical.
        // We need to translate the startLocation to
        // the current orientation.
        const { width, height } = getDimensions(self.grid.tiles);
        effectiveStartLocation = rotateLocationRelativeToDimensions(
          self.startLocation,
          {
            width: height,
            height: width,
          }
        );
      }

      return effectiveStartLocation;
    },
    getLevelFileSnapshot() {
      const tiles = self.getVerticalAlignedTiles(self.grid);

      return {
        // startLocation is constant regardless of orientation
        // so we can always reference the main game's startLocation
        startLocation: self.startLocation
          ? getSnapshot(self.startLocation)
          : undefined,
        tiles,
      };
    },
  }))
  .actions((self) => ({
    hideVictory(onVictory?: () => void) {
      self.state = "review";
      onVictory?.();
    },
  }))
  .actions((self) => ({
    showVictory(onVictory?: () => void) {
      // setTimeout(() => self.hideVictory(onVictory), 5000);
    },
  }))
  .actions((self) => ({
    handleGameOver(onVictory?: () => void) {
      const { app } = getParentOfType(self, RootModel);
      const { timer } = self;

      if (self.isGameOver) {
        app.playSound("victoryPopUp");
        timer.stopTimer();
        self.state = "victory";
        self.showVictory(onVictory);
        app.events.sendEndEvent();
      }
    },
  }))
  .actions((self) => ({
    async animateTileReveal(
      origin: Location,
      revealedLocations: Location[],
      onVictory?: () => void
    ) {
      const { grid } = self;
      const locationsByDistance: { [key: number]: Location[] } = {};

      revealedLocations.forEach((location) => {
        const distanceFromOrigin = manhattanDistance(origin, location);
        if (!locationsByDistance[distanceFromOrigin]) {
          locationsByDistance[distanceFromOrigin] = [];
        }

        locationsByDistance[distanceFromOrigin].push(location);
      });

      const distanceCount = Object.keys(locationsByDistance).length;
      for (let i = 0; i < distanceCount; i += 1) {
        locationsByDistance[i].forEach((location) => grid.revealTile(location));
        await wait(100);
      }

      self.handleGameOver(onVictory);
    },
    setOrientation(newOrientation: "vertical" | "horizontal") {
      switch (true) {
        case self.orientation === "vertical" && newOrientation === "horizontal":
          self.grid.rotate("clockwise");
          break;
        case self.orientation === "horizontal" && newOrientation === "vertical":
          self.grid.rotate("counter-clockwise");
          break;
      }

      self.orientation = newOrientation;
    },
    ensureTimerRunning() {
      const { timer } = self;
      // TODO: Make timer re-usable
      if (!timer.isRunning) {
        timer.startTimer();
      }
    },
  }))
  .actions((self) => ({
    init(
      initialTiles: ITile[][] | ITileSnapshotIn[][],
      startLocation?: Location
    ) {
      if (self.state !== "stopped") {
        // We can only init the game if it is stopped
        return;
      }

      let inBoundsStartLocation = startLocation;
      if (startLocation && !isLocationInBounds(initialTiles, startLocation)) {
        inBoundsStartLocation = undefined;
        console.error(
          `Start location ${startLocation.row}, ${startLocation.col} is not in bounds!`
        );
      }

      // self.orientation = "vertical";
      self.startingFromState = false;
      self.startLocation = inBoundsStartLocation;
      self.grid.reset(initialTiles);

      const gameOrientation = calculateGameOrientation();
      self.setOrientation(gameOrientation);
    },
    start() {
      if (self.state !== "stopped") {
        // We can only start the game if we've stopped it previously
        return;
      }

      // I need this type cast because the MST type def for the
      // orientation enumeration thinks orientation should be a
      // generic string instead of a more specific union type.
      self.setOrientation(self.orientation as "vertical" | "horizontal");

      // Timer and Actions have been seeded from a state snapshot
      // We don't want to overwrite them.
      if (!self.startingFromState) {
        self.timer.reset();
        self.actions.reset();
      }

      self.ensureTimerRunning();

      /**
       * If the state loaded was already completed,
       * we don't start in the running state, we go
       * straight ot the victory state.
       */
      const { app } = getParentOfType(self, RootModel);
      if (app.loading.amuState?.isCompleted) {
        console.log("Game is completed on load, handle game over.");
        self.handleGameOver(() => app.modal.show("stats"));
      } else {
        self.state = "running";
      }
    },
    stop() {
      self.state = "stopped";
    },
    reset() {
      // Game needs to be in vertical orientation
      // in order to load grid correctly.
      // All level grids are saved in vertical
      // orientation.
      self.setOrientation("vertical");
      self.hasDoneStartingRevealAnimation = false;
      self.startingFromState = false;

      self.grid.reset();
      self.timer.reset();
      self.actions.reset();
    },
    takeMarkingAction(
      location: Location,
      settings: ISettings,
      onVictory?: () => void
    ) {
      const { grid } = self;

      const tile = getLocation<ITile>(grid.tiles, location);

      if (tile.isRevealed) {
        return;
      }

      grid.toggleMarking(location);
      self.handleGameOver(onVictory);
    },
    takeRevealingAction(
      location: Location,
      settings: ISettings,
      onVictory?: () => void
    ) {
      const { app } = getParentOfType(self, RootModel);
      const { timer, grid, actions } = self;

      const floodFill = createFloodFillForGrid(grid);

      grid.removeMarking(location);

      const tile = getLocation(grid.tiles, location);

      if (
        !tile.isRevealed &&
        (tile.type === "empty" ||
          tile.type === "clue-area" ||
          tile.type === "clue-arrow") &&
        !tile.isHinted &&
        self.shouldHintOnPrimaryAction
      ) {
        // don't click, hint instead
        grid.hintTile(location);
        app.playSound("hintReveal");
      }

      if (!tile.isRevealed) {
        actions.click();

        // Handle all audio here
        switch (tile.type) {
          case "bomb":
            app.playSound("skullReveal");
            break;
          case "treasure":
            // TODO:
            // There are 3 different SFX (if we get a third one)
            // That treasure can play, just start only playing
            // this one for now.
            // gemReveal
            // pentultimateGemReveal
            // finalGemReveal
            app.playSound("gemReveal");
            break;
          case "empty":
            // Empty tiles can be actually EMPTY
            // or a Number clue.
            // We don't want cave sound playing for number
            // clues.
            if (isNumberClue(self.grid.tiles, location)) {
              break;
            }

            // Any empty tile SHOULD do a flood of some amount
            // it might be a tiny flood, but should be a good
            // enough trigger point for this SFX.

            app.playSound("caveReveal");
            break;
          case "clue-area":
            app.playSound("areaClueReveal");
            break;
          case "clue-arrow":
            app.playSound("arrowClueReveal");
            break;
          default:
            // No SFX if somehow extra tile type gets in here
            break;
        }
      }

      if (tile.type === "bomb") {
        // TODO: Visualize the bomb penalty
        console.log(
          "This should trigger an animation showing how much time was added as a penalty."
        );
      }

      if (!floodableTypes.includes(tile.type) && tile.type !== "treasure") {
        grid.revealTile(location);
        self.handleGameOver(onVictory);
        return;
      }

      if (tile.type === "treasure") {
        if (!settings.gameplay.treasureReveal) {
          // Treasure reveal bonus rule is off,
          // just reveal the treasure
          grid.revealTile(location);
          self.handleGameOver(onVictory);
          return;
        }

        const neighborLocations = getNeighborLocations(grid.tiles, location);
        const nonBombLocations: Location[] = [];
        neighborLocations.forEach((neighborLocation) => {
          const neighbor = getLocation<ITile>(grid.tiles, neighborLocation);

          if (neighbor.type === "bomb") {
            if (!neighbor.isRevealed) {
              grid.flagTile(neighborLocation);
            }
          } else {
            nonBombLocations.push(neighborLocation);
            grid.revealTile(neighborLocation);
          }
        });

        // TODO:
        // Why is this here?
        // Isn't this doing nothing?
        (async () => {
          await wait(1000);
        })();

        // NOTE:
        // I just realized this should never actually trigger
        // a floodfill in the current ruleset.
        // ONLY _empty_ tiles can trigger a flooding.
        // However, there will never be an empty tile
        // next to a treasure since it would have to at least
        // have one number on it.
        const revealedTiles = floodFill(grid.tiles, nonBombLocations);
        // Add current location to revealedTiles
        // since treasure won't get caught in floodfill.
        self.animateTileReveal(location, [location, ...revealedTiles], () =>
          self.handleGameOver(onVictory)
        );
        return;
      }

      const revealedTiles = floodFill(grid.tiles, location);
      self.animateTileReveal(location, revealedTiles, () =>
        self.handleGameOver(onVictory)
      );
    },
  }))
  .actions((self) => ({
    takePrimaryAction(
      location: Location,
      settings: ISettings,
      onVictory?: () => void
    ) {
      switch (self.inputModel) {
        case "tapToReveal":
        case "click":
          self.takeRevealingAction(location, settings, onVictory);
          break;
        case "tapToMark":
          self.takeMarkingAction(location, settings, onVictory);
          break;
      }
    },
    takeSecondaryAction(
      location: Location,
      settings: ISettings,
      onVictory?: () => void
    ) {
      switch (self.inputModel) {
        case "tapToReveal":
        case "click":
          self.takeMarkingAction(location, settings, onVictory);
          break;
        case "tapToMark":
          self.takeRevealingAction(location, settings, onVictory);
          break;
      }
    },
    // TODO:
    // This should probably be an app setting and not a game setting.
    // The user probably wants all games to behave this way, not
    // just the current one. AKA, tutorial and main game should
    // use same control scheme.
    setInputModel(newModel: "tapToReveal" | "tapToMark" | "click") {
      self.inputModel = newModel;
    },
    // TODO:
    // These GameStateSnapshots are heavier than
    // they need to be to go over the wire.
    //
    // We could remove some data from them
    // and they'd still convey save state appropriately.
    createGameStateSnapshot(): IGameStateSnapshot {
      const { app } = getParentOfType(self, RootModel);

      return GameStateSnapshot.create({
        appVersion: packageInfo.version,
        levelIssueDateString: app.issueDateSerialized,
        grid: self.getLevelFileSnapshot(),
        actions: getSnapshot(self.actions),
        timer: getSnapshot(self.timer),
        hasDoneStartingRevealAnimation: self.hasDoneStartingRevealAnimation,
      });
    },
    hydrateFromGameStateSnapshot(snapshot: IGameStateSnapshot) {
      self.startingFromState = true;
      self.hasDoneStartingRevealAnimation =
        snapshot.hasDoneStartingRevealAnimation;

      self.actions.hydrate(snapshot.actions);
      self.timer.hydrate(snapshot.timer);

      let tiles = getSnapshot(snapshot.grid.tiles);

      if (self.orientation === "horizontal") {
        const gameSnapshot = getSnapshot(self);
        const tempGame = GameModel.create({
          ...gameSnapshot,
          grid: {
            tiles,
          },
          orientation: "vertical",
        });
        tempGame.setOrientation("horizontal");
        tiles = getSnapshot(tempGame.grid.tiles);
      }

      self.grid.hydrate({
        tiles,
      });
    },
    setShouldHintOnPrimaryAction(newValue: boolean) {
      self.shouldHintOnPrimaryAction = newValue;
    },
    toggleHintOnPrimaryAction() {
      const { app } = getParentOfType(self, RootModel);

      self.shouldHintOnPrimaryAction = !self.shouldHintOnPrimaryAction;
      app.events.sendChangeSettingsEvent(app.createAmuUserSettingsSnapshot());
    },
    tryStartingRevealAnimation({ onVictory = () => {} }) {
      if (self.effectiveStartLocation && !self.hasDoneStartingRevealAnimation) {
        doStartingRevealAnimation({
          // Need to cast here because self isn't matching IGame for some reason
          // Maybe because these actions aren't defined yet in the types?
          game: self as IGame,
          startLocation: self.effectiveStartLocation,
          onVictory,
        });

        self.hasDoneStartingRevealAnimation = true;
      }
    },
    pause() {
      self.timer.stopTimer();
    },
    resume() {
      self.timer.startTimer();
    },
  }));

export interface IGame extends Instance<typeof GameModel> {}
export interface IGameSnapshotIn extends SnapshotIn<typeof GameModel> {}
export interface IGameSnapshotOut extends SnapshotOut<typeof GameModel> {}
export default GameModel;
