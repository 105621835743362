import { HttpResult } from "./httpResult";

async function requestText(url: string): Promise<HttpResult<string>> {
  try {
    const response = await fetch(url);

    if (response.status < 200 || response.status >= 400) {
      return {
        ok: false,
        statusCode: response.status,
        error: new Error(
          `Received "${response.status} - ${response.statusText}" requesting "${url}"`,
        ),
      };
    }

    return {
      ok: true,
      statusCode: response.status,
      value: await response.text(),
    };
  } catch (error) {
    return {
      ok: false,
      error,
    };
  }
}

export default requestText;
