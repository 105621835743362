import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  getSnapshot,
  types,
} from "mobx-state-tree";
import areLocationsEqual from "../utils/areLocationsEqual";
import calcNeighboringPoiCount from "../utils/calcNeighboringPoiCount";
import { DIRECTION_LOCATIONS, rotateDirection } from "../utils/directions";
import getAreaClueLocations from "../utils/getAreaClueLocations";
import getLocation from "../utils/getLocation";
import getLocationsInDirection from "../utils/getLocationsInDirection";
import getNeighborLocations from "../utils/getNeighborLocations";
import { Location, Matrix } from "../utils/types";
import TileModel, { ITile, ITileSnapshotIn, TILE_MARKINGS } from "./Tile";
import rotateMatrix from "../utils/matrix/rotateMatrix";
import getLocationsByType from "../utils/getLocationsByType";
import setLocation from "../utils/setLocation";
import { GameStateSnapshot } from "../utils/amu/AmuMessage";

export const TilesModel = types.array(types.array(TileModel));
export const LocationModel = types.model({
  row: types.number,
  col: types.number,
});

const GridModel = types
  .model({
    tiles: TilesModel,
    hovered: types.maybeNull(LocationModel),
  })
  .views((self) => ({
    get hoveredClueAreaLocations() {
      if (!self.hovered) {
        return [];
      }

      const hoveredTile = getLocation(self.tiles, self.hovered);

      if (!hoveredTile.isRevealed) {
        // Unrevealed tiles cannot highlight
        // other tiles, but unrevealed tiles can be
        // highlighted.
        return [];
      }

      let clueAreaLocations: Location[] = [];
      switch (hoveredTile.type) {
        case "clue-area":
          clueAreaLocations = getAreaClueLocations(self.tiles, self.hovered);
          break;
        case "clue-arrow":
          clueAreaLocations = getLocationsInDirection(
            self.hovered,
            DIRECTION_LOCATIONS[hoveredTile.arrowDirection ?? "UP"],
            self.tiles,
          );
          break;
        case "empty":
          const neighboringPoiCount = calcNeighboringPoiCount(
            self.tiles,
            self.hovered,
          );

          if (neighboringPoiCount > 0) {
            clueAreaLocations = getNeighborLocations(self.tiles, self.hovered);
          }
          break;
        default:
          break;
      }

      return clueAreaLocations;
    },
  }))
  .views((self) => ({
    isLocationInHoveredClueArea(location: Location): boolean {
      const clueAreaLocations = self.hoveredClueAreaLocations;

      return clueAreaLocations.some((clueAreaLocation) =>
        areLocationsEqual(clueAreaLocation, location),
      );
    },
  }))
  .actions((self) => ({
    revealTile(location: Location) {
      /**
       * I need to type this tile as an ITile because it's currently
       * set up as a "TileModel" in the model definition.
       *
       * For some reason "TileModel"s typing doesn't include the
       * actions I have defined, even though they're present on
       * ITile.
       *
       * In my understanding the type of TileModel is _supposed_ to
       * just be ITile. Clearly there's something different there though.
       *
       * I think this might be "good enough" to move forward though.
       */
      const tile = getLocation<ITile>(self.tiles, location);

      // tile.revealedState = "revealing";
      tile.reveal();
    },
    toggleMarking(location: Location) {
      const tile = getLocation<ITile>(self.tiles, location);
      const currentMarking = tile.marking;
      if (!currentMarking) {
        // TODO: This should never be true... Something wrong
        // with our mst typedefs again. There should ALWAYS be
        // a marking.
        return;
      }

      const currentMarkingIndex = TILE_MARKINGS.indexOf(currentMarking);
      const nextMarkingIndex = (currentMarkingIndex + 1) % TILE_MARKINGS.length;

      tile.setMarking(TILE_MARKINGS[nextMarkingIndex]);
    },
    removeMarking(location: Location) {
      const tile = getLocation<ITile>(self.tiles, location);

      tile.setMarking("none");
    },
    flagTile(location: Location) {
      const tile = getLocation<ITile>(self.tiles, location);

      tile.setMarking("flagged");
    },
    hintTile(location: Location) {
      const tile = getLocation<ITile>(self.tiles, location);

      tile.setHinted();
    },
    // TODO:
    // I have NO idea if this is supposed to work this way.
    // I want the rest of the app to only deal in ITile's
    // where possible.
    // I'm not sure how else to convert from ITile[][] to
    // the appropriate TilesModel type.
    reset(tiles?: ITile[][] | ITileSnapshotIn[][]) {
      if (tiles) {
        self.tiles = TilesModel.create(tiles);
      }
      self.hovered = null;
    },
    setHovered(newHovered: Location | null) {
      self.hovered = newHovered;
    },
    rotate(direction: "clockwise" | "counter-clockwise") {
      const rotatedTiles = rotateMatrix(getSnapshot(self.tiles), direction);

      const arrows = getLocationsByType(
        rotatedTiles as Matrix<ITile>,
        "clue-arrow",
      );

      arrows.forEach((arrowLocation) => {
        const arrow = getLocation(rotatedTiles, arrowLocation);

        setLocation(rotatedTiles, arrowLocation, {
          ...arrow,
          arrowDirection: rotateDirection(
            arrow.arrowDirection ?? "UP",
            direction,
          ),
        });
      });

      self.tiles = TilesModel.create(rotatedTiles);
    },
  }))
  .actions((self) => ({
    hydrate(snapshot: IGridSnapshotIn) {
      self.reset(snapshot.tiles as ITile[][]);
    },
  }));

export interface IGrid extends Instance<typeof GridModel> {}
export interface IGridSnapshotIn extends SnapshotIn<typeof GridModel> {}
export interface IGridSnapshotOut extends SnapshotOut<typeof GridModel> {}
export default GridModel;
