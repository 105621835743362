function rkSum<T>(this: Array<T>) {
  let total = 0;
  const values = this.values();

  for (let value of values) {
    total += Number(value);
  }

  return total;
}

export default rkSum;
