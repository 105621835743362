import deepClone from "./deepClone";

function fillArray<T>(item: T, length: number, shouldDeepClone = true) {
  const array = [];

  for (let i = 0; i < length; i++) {
    let newItem = item;

    if (shouldDeepClone) {
      newItem = deepClone(item);
    }

    array.push(newItem);
  }

  return array;
}

export default fillArray;
