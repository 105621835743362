import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import images from "../../../utils/assets/images";
import BgContainer from "./BgContainer";
import ShrinkableFlexImage from "./ShrinkableFlexImage";
import BgRow from "./BgRow";

const TopRow = styled(BgRow)`
  align-items: start;

  margin-bottom: 1rem;
`;

const BottomRow = styled(BgRow)`
  align-items: end;

  margin-top: 1rem;
`;

const BgHorizontal = observer(() => {
  return (
    <BgContainer>
      <TopRow>
        <ShrinkableFlexImage
          draggable={false}
          src={images["Caved-Title-Element-Desktop-TopLeft-NoNumbers.svg"]}
        />
        <ShrinkableFlexImage
          draggable={false}
          src={images["Caved-Title-Element-Desktop-TopRight-NoNumbers.svg"]}
        />
      </TopRow>
      <BottomRow>
        <ShrinkableFlexImage
          draggable={false}
          src={images["Caved-Title-Element-Desktop-BottomLeft-NoNumbers.svg"]}
        />
        <ShrinkableFlexImage
          draggable={false}
          src={images["Caved-Title-Element-Desktop-BottomRight-NoNumbers.svg"]}
        />
      </BottomRow>
    </BgContainer>
  );
});

export default BgHorizontal;
