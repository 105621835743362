import { ITile } from "../models/Tile";
import doesClueIdentifyTreasure from "./doesClueIdentifyTreasure";
import getLocation from "./getLocation";
import getLocationsByType from "./getLocationsByType";
import { Location, Matrix } from "./types";

function countIdentifyingClues(
  tiles: Matrix<ITile>,
  location: Location,
): number {
  const tile = getLocation(tiles, location);

  if (tile.type !== "treasure") {
    return 0;
  }

  const clueLocations = [
    ...getLocationsByType(tiles, "clue-arrow"),
    ...getLocationsByType(tiles, "clue-area"),
  ];

  let identifyingClueCount = 0;

  clueLocations.forEach((clueLocation) => {
    if (doesClueIdentifyTreasure(tiles, clueLocation, location)) {
      identifyingClueCount += 1;
    }
  });

  return identifyingClueCount;
}

export default countIdentifyingClues;
