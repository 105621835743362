import addLocations from "./addLocations";
import isLocationInBounds from "./isLocationInBounds";
import { Location, Matrix } from "./types";

function getNeighborLocations<T>(matrix: Matrix<T>, location: Location) {
  const neighbors = [];

  const addOtherLocation = (otherLocation: Location) =>
    addLocations(location, otherLocation);

  neighbors.push(addOtherLocation({ row: -1, col: -1 }));
  neighbors.push(addOtherLocation({ row: -1, col: 0 }));
  neighbors.push(addOtherLocation({ row: -1, col: 1 }));

  neighbors.push(addOtherLocation({ row: 0, col: -1 }));
  neighbors.push(addOtherLocation({ row: 0, col: 1 }));

  neighbors.push(addOtherLocation({ row: 1, col: -1 }));
  neighbors.push(addOtherLocation({ row: 1, col: 0 }));
  neighbors.push(addOtherLocation({ row: 1, col: 1 }));

  return neighbors.filter((neighborLocation) =>
    isLocationInBounds(matrix, neighborLocation),
  );
}

export default getNeighborLocations;
