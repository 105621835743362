import useSettings from "../../hooks/useSettings";
import { useGameStore, useRootStore } from "../../models/Root";
import formatSeconds from "../formatSeconds";
import calculateScore from "./calculateScore";
import getAllClues from "./getAllClues";
import getPlacedFlags from "./getPlacedFlags";
import getRevealedBombs from "./getRevealedBombs";
import getRevealedClues from "./getRevealedClues";
import calcIsPerfectScore from "./isPerfectScore";

const useStats = () => {
  const { app } = useRootStore();
  const game = useGameStore();
  const [settings, isInitialized] = useSettings();

  const placedFlags = getPlacedFlags(game.grid);
  const correctlyPlacedFlags = placedFlags.filter(
    (tile) => tile.type === "bomb"
  );

  const allClues = getAllClues(game.grid);
  const revealedClues = getRevealedClues(game.grid);

  const revealedBombs = getRevealedBombs(game.grid);
  const score = calculateScore({
    game,
    settings,
    isInitialized,
  });

  const flagAccuracy =
    placedFlags.length > 0
      ? Math.round((correctlyPlacedFlags.length / placedFlags.length) * 100)
      : 100;

  const time = formatSeconds(game.timer.getElapsedTimeSeconds());

  const isPerfectScore = calcIsPerfectScore({
    app,
    game,
    settings,
    isInitialized,
  });

  return {
    flagAccuracy,
    revealedClueCount: revealedClues.length,
    allClueCount: allClues.length,
    time,
    revealedBombCount: revealedBombs.length,
    score,
    isPerfectScore,
  };
};

export default useStats;
