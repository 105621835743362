import { getParent, getParentOfType, types } from "mobx-state-tree";
import { EventGameMessageType, IAmuState } from "../utils/amu/AmuMessage";
import AppModel, { IApp } from "./App";
import {
  sendAmuChangeSettingsMessage,
  sendAmuEndMessage,
  sendAmuHelpMessage,
  sendAmuOnboardingCompleteMessage,
  sendAmuOnboardingSkipMessage,
  sendAmuPauseMessage,
  sendAmuReplayMessage,
  sendAmuResumeMessage,
  sendAmuStartMessage,
} from "../utils/amu/sendAmuMessages";
import { UserSettingsSnapshotType } from "../utils/amu/parseAmuMessage";

const AmuEvents = types
  .model({
    onEvents: types.array(EventGameMessageType),
  })
  .views((self) => ({
    get parentState(): IAmuState {
      const app: IApp = getParentOfType(self, AppModel);

      return app.createAmuState();
    },
  }))
  .actions((self) => ({
    sendStartEvent() {
      if (self.onEvents.includes("start") || self.onEvents.includes("all")) {
        sendAmuStartMessage(self.parentState);
      }
    },
    sendPauseEvent() {
      if (self.onEvents.includes("pause") || self.onEvents.includes("all")) {
        sendAmuPauseMessage(self.parentState);
      }
    },
    sendResumeEvent() {
      if (self.onEvents.includes("resume") || self.onEvents.includes("all")) {
        sendAmuResumeMessage(self.parentState);
      }
    },
    sendEndEvent() {
      if (self.onEvents.includes("end") || self.onEvents.includes("all")) {
        sendAmuEndMessage(self.parentState);
      }
    },
    sendReplayEvent() {
      if (self.onEvents.includes("replay") || self.onEvents.includes("all")) {
        sendAmuReplayMessage(self.parentState);
      }
    },
    sendHelpEvent() {
      if (self.onEvents.includes("help") || self.onEvents.includes("all")) {
        sendAmuHelpMessage(self.parentState);
      }
    },
    sendChangeSettingsEvent(newSettings: UserSettingsSnapshotType) {
      if (
        self.onEvents.includes("change settings") ||
        self.onEvents.includes("all")
      ) {
        sendAmuChangeSettingsMessage(self.parentState, newSettings);
      }
    },
    sendOnboardingCompleteEvent(newSettings: UserSettingsSnapshotType) {
      if (
        self.onEvents.includes("onboarding complete") ||
        self.onEvents.includes("all")
      ) {
        sendAmuOnboardingCompleteMessage(self.parentState, newSettings);
      }
    },
    sendOnboardingSkipEvent(newSettings: UserSettingsSnapshotType) {
      if (
        self.onEvents.includes("onboarding skip") ||
        self.onEvents.includes("all")
      ) {
        sendAmuOnboardingSkipMessage(self.parentState, newSettings);
      }
    },
  }));

export default AmuEvents;
