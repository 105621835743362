import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

const ActionsModel = types
  .model({
    count: types.optional(types.number, 0),
  })
  .actions((self) => ({
    hydrate(snapshot: IActionsSnapshotIn) {
      self.count = snapshot.count ?? 0;
    },
    click() {
      self.count += 1;
    },
    reset() {
      self.count = 0;
    },
  }));

export interface IActions extends Instance<typeof ActionsModel> {}
export interface IActionsSnapshotIn extends SnapshotIn<typeof ActionsModel> {}
export interface IActionsSnapshotOut extends SnapshotOut<typeof ActionsModel> {}
export default ActionsModel;
