import { ITile } from "../models/Tile";
import { DIRECTION_LOCATIONS } from "./directions";
import getAreaClueLocations from "./getAreaClueLocations";
import getLocation from "./getLocation";
import getLocationsInDirection from "./getLocationsInDirection";
import { Location, Matrix } from "./types";

function isClueSatisfied(tiles: Matrix<ITile>, location: Location): boolean {
  const tile = getLocation(tiles, location);

  let allTreasures: ITile[] = [];
  switch (tile.type) {
    case "clue-arrow":
      if (!tile.arrowDirection) {
        console.error(
          `Tried to check if clue at ${JSON.stringify(
            location,
          )} was satisfied and failed because no arrowDirection exists.`,
        );
        return false;
      }

      allTreasures = getLocationsInDirection(
        location,
        DIRECTION_LOCATIONS[tile.arrowDirection],
        tiles,
      )
        .map((location) => getLocation(tiles, location))
        .filter((tile) => tile.type === "treasure");
      break;
    case "clue-area":
      if (!tile.areaOffsetLocations) {
        console.error(
          `Tried to check if clue at ${JSON.stringify(
            location,
          )} was satisfied and failed because no areaOffsetLocations exists.`,
        );
        return false;
      }

      allTreasures = getAreaClueLocations(tiles, location)
        .map((location) => getLocation(tiles, location))
        .filter((tile) => tile.type === "treasure");
      break;
    default:
      return false;
  }

  return allTreasures.every((treasure) => treasure.isRevealed);
}

export default isClueSatisfied;
