import React from "react";
import styled from "styled-components";
import packageInfo from "../../../package.json";

const VersionContainer = styled.p`
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 1rem;
  margin: 1rem;

  @media (max-width: 500px) {
    margin: 0.35rem;
  }
`;

const Version = () => {
  return <VersionContainer>v{packageInfo.version}</VersionContainer>;
};

export default Version;
