import mapMatrix from "./mapMatrix";
import { Location, Matrix } from "./types";

function getLocationsByLens<T>(lens: (tile: T, location: Location) => boolean) {
  return (tiles: Matrix<T>) =>
    mapMatrix<T, [T, Location]>((tile, location) => [tile, location], tiles)
      .flat()
      .filter(([tile, location]) => lens(tile, location))
      .map(([, location]) => location);
}

export default getLocationsByLens;
