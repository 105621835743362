import { IApp } from "../../models/App";
import { IGame } from "../../models/Game";
import { ISettings } from "../../models/Settings";
import calculateScore from "./calculateScore";

function isPerfectScore({
  app,
  game,
  settings,
  isInitialized,
}: {
  app: IApp;
  game: IGame;
  settings: ISettings;
  isInitialized: boolean;
}): boolean {
  if (game.state === "running" || game.state === "stopped") {
    return false;
  }

  const score = calculateScore({
    game,
    settings,
    isInitialized,
  });

  return score <= app.issueDatePar;
}

export default isPerfectScore;
