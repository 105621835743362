import { useEffect } from "react";
import { useGameStore, useRootStore } from "../../models/Root";
import useSettings from "../../hooks/useSettings";
import forEachMatrix from "../../utils/forEachMatrix";

const useStartGame = ({ onVictory }: { onVictory?: () => void }) => {
  const [settings] = useSettings();
  const game = useGameStore();
  const { app } = useRootStore();

  useEffect(() => {
    if (game.state !== "stopped") {
      return;
    }

    game.start();

    if (game.hasDoneStartingRevealAnimation) {
      return;
    }

    app.playSound("pressPlay");
    game.tryStartingRevealAnimation({ onVictory });

    if (settings.gameplay.revealAll) {
      const { grid } = game;

      forEachMatrix((_, location) => {
        grid.revealTile(location);
      }, grid.tiles);
    }
  }, [game.hasDoneStartingRevealAnimation]);
};

export default useStartGame;
