import fillArray from "./fillArray";
import { Dimensions } from "./types";

function fillMatrix<T>(item: T, dimensions: Dimensions) {
  const row = fillArray(item, dimensions.width);

  return fillArray(row, dimensions.height);
}

export default fillMatrix;
