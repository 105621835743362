import { ITile } from "../../models/Tile";
import calcNeighboringPoiCount from "../calcNeighboringPoiCount";
import getLocation from "../getLocation";
import { Location, Matrix } from "../types";

function isValidCluePlacementLocation(
  tiles: Matrix<ITile>,
  location: Location,
) {
  const tile = getLocation(tiles, location);
  const neighboringPoiCount = calcNeighboringPoiCount(tiles, location);

  return tile.type === "empty" && neighboringPoiCount === 0;
}

export default isValidCluePlacementLocation;
