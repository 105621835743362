function calculateGameOrientation(currentWidth?: number) {
  // if (currentWidth === undefined) {
  //   currentWidth = window.innerWidth;
  // }

  // TODO: This 992 should get pulled out of a global
  // media query config somewhere.
  // In the theme maybe?
  // This 992 comes from the resolution that the puzzle
  // society switches to mobile.
  // However, since we're iframed, the orientation doesn't
  // quite work for us. Trying 800px instead.
  // if (currentWidth < 800) {
  if (window.matchMedia("(orientation: portrait").matches) {
    return "vertical";
  } else {
    return "horizontal";
  }
}

export default calculateGameOrientation;
