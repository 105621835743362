import areLocationsEqual from "./areLocationsEqual";
import getLocation from "./getLocation";
import getNeighborLocations from "./getNeighborLocations";
import { Location, Matrix } from "./types";

function createFloodFill<T>(
  isLocationValid: (tile: T, location: Location, matrix: Matrix<T>) => boolean,
  shouldOpenNeighbors: (
    tile: T,
    location: Location,
    matrix: Matrix<T>,
  ) => boolean,
) {
  function _floodFill(
    open: Location[],
    closed: Location[],
    found: Location[],
    matrix: Matrix<T>,
  ): Location[] {
    // get next open location
    const location = open.pop();

    if (!location) {
      // exit case, we've found all connected locations
      return found;
    }

    const tile = getLocation(matrix, location);

    if (isLocationValid(tile, location, matrix)) {
      // mark location as found
      found.push(location);

      if (shouldOpenNeighbors(tile, location, matrix)) {
        const neighbors = getNeighborLocations(matrix, location);

        neighbors.forEach((neighborLocation) => {
          if (
            !open.find((openLocation) =>
              areLocationsEqual(neighborLocation, openLocation),
            ) &&
            !closed.find((closedLocation) =>
              areLocationsEqual(neighborLocation, closedLocation),
            )
          ) {
            // if location not already closed or open, push it into open
            open.push(neighborLocation);
          }
        });
      }
    }

    // close current location
    closed.push(location);

    return _floodFill(open, closed, found, matrix);
  }

  function floodFill(matrix: Matrix<T>, origin: Location | Location[]) {
    let openLocations = [];

    if (Array.isArray(origin)) {
      openLocations.push(...origin);
    } else {
      openLocations.push(origin);
    }

    return _floodFill(openLocations, [], [], matrix);
  }

  return floodFill;
}

export default createFloodFill;
