import { observer } from "mobx-react-lite";
import * as React from "react";
import styled, {
  DefaultTheme,
  ThemedStyledProps,
  useTheme,
} from "styled-components";
import { useGameStore } from "../models/Root";
import { ITile } from "../models/Tile";
import areLocationsEqual from "../utils/areLocationsEqual";
import calcAreaValue from "../utils/calcAreaValue";
import calcArrowValue from "../utils/calcArrowValue";
import calcNeighboringPoiCount from "../utils/calcNeighboringPoiCount";
import { DIRECTIONS } from "../utils/directions";
import getAllClueBorders from "../utils/getAllClueBorders";
import getAreaClueLocations from "../utils/getAreaClueLocations";
import getLocation from "../utils/getLocation";
import getLocationsByType from "../utils/getLocationsByType";
import isClueSatisfied from "../utils/isClueSatisfied";
import isPoi from "../utils/isPoi";
import { Location, Matrix } from "../utils/types";
import images from "../utils/assets/images";
import { ReactElement } from "react";
import ClueIcon from "./tile/ClueIcon";
import isClue from "../utils/isClue";
import { StyledProps } from "styled-components";
import NonSaveableImage from "./tile/NonSaveableImage";

type TileContainerProps = {
  color?: string;
  isClueSatisfied: boolean;
  isRevealed: boolean | undefined;
  isAnyAreaClueNearby: boolean;
  borders: string[];
  isPoi: boolean;
  isClue: boolean;
  isFlagged: boolean;
  isInHoveredClueArea: boolean;
  isJustRevealed: boolean;
  isLongPressed: boolean;
  isPlayerVictorious: boolean;
  victoryAnimDelay: number;
};

const getNumberColor = (tiles: Matrix<ITile>, location: Location) => {
  const theme = useTheme();
  const tile = getLocation(tiles, location);

  if (tile.isHinted) {
    return "lightgray";
  }

  if (tile.type !== "empty") {
    return;
  }

  const neighboringPoiCount = calcNeighboringPoiCount(tiles, location);

  switch (neighboringPoiCount) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      return theme.colors.numbers[neighboringPoiCount];
    default:
    case 0:
      return;
  }
};

const getBgColor =
  ({ isHovered }: { isHovered: boolean }) =>
  (props: StyledProps<TileContainerProps>) => {
    const isHoveredOrInHoveredAreaClue = props.isInHoveredClueArea || isHovered;

    switch (true) {
      case props.isRevealed && (props.isClue || props.isPoi):
        return "";
      case props.isRevealed && isHoveredOrInHoveredAreaClue:
        return props.theme.colors.tile.revealedHovered;
      case props.isRevealed:
        return props.theme.colors.tile.revealed;
      case isHoveredOrInHoveredAreaClue:
        return props.theme.colors.tile.hiddenHovered;
      default:
        return props.theme.colors.tile.hidden;
    }
  };

const getAnimationName = (props: StyledProps<TileContainerProps>) => {
  const animations = [];

  if (props.isJustRevealed || props.isPlayerVictorious) {
    animations.push(props.theme.animations.tileReveal);
  }

  if (props.isLongPressed) {
    animations.push("pulse");
  }

  return animations.join(", ");
};

const TileContainer = styled.div<TileContainerProps>`
  width: ${(props) => props.theme.tileSize}px;
  height: ${(props) => props.theme.tileSize}px;
  background: ${getBgColor({ isHovered: false })};

  font-weight: bold;
  color: ${(props) => (props.color ? props.color : "black")};

  border-width: ${(props) =>
    (props.isRevealed && props.isPoi) || (!props.isRevealed && props.isFlagged)
      ? "2px"
      : "1px"};
  border-style: solid;
  border-color: transparent;
  border-top-color: ${(props) =>
    props.borders.includes(DIRECTIONS.UP)
      ? props.theme.colors.clue
      : "transparent"};
  border-bottom-color: ${(props) =>
    props.borders.includes(DIRECTIONS.DOWN)
      ? props.theme.colors.clue
      : "transparent"};
  border-left-color: ${(props) =>
    props.borders.includes(DIRECTIONS.LEFT)
      ? props.theme.colors.clue
      : "transparent"};
  border-right-color: ${(props) =>
    props.borders.includes(DIRECTIONS.RIGHT)
      ? props.theme.colors.clue
      : "transparent"};

  border-radius: 4px;

  animation-name: ${getAnimationName};
  animation-timing-function: ease-in-out;
  animation-duration: ${(props) =>
    props.isPlayerVictorious ? "350ms" : "200ms"};
  animation-delay: ${(props) =>
    props.isPlayerVictorious ? `${props.victoryAnimDelay}ms` : ""};

  /* filter: ${(props) =>
    props.isInHoveredClueArea ? "brightness(110%)" : ""}; */

  &:hover {
    transition-property: filter;
    transition-duration: 200ms;
    transition-timing-function: easeInOutQuad;

    background: ${getBgColor({ isHovered: true })};

    /* transform: scale(1.1); */
    /* filter: brightness(130%); */
  }

  & > div {
    ${(props) =>
      props.isRevealed && props.isClueSatisfied
        ? `
          filter: grayscale(0.75);
          opacity: 0.6;
        `
        : ""}
    font-size: 1.5rem;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

/**
 * Pull event list from w3c spec:
 * https://www.w3.org/TR/uievents/#events-mouse-types
 */
const isMouseEvent = (event: React.UIEvent): event is React.MouseEvent => {
  switch (event.type) {
    case "auxclick":
    case "click":
    case "contextmenu":
    case "dblclick":
    case "mousedown":
    case "mouseenter":
    case "mouseleave":
    case "mousemove":
    case "mouseout":
    case "mouseover":
    case "mouseup":
      return true;
    default:
      return false;
  }
};

const ACTION_DEBOUNCE_BUFFER = 500;

type TileProps = {
  location: Location;
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
  onActionTaken: (isPrimaryAction: boolean) => void;
  victoryAnimDelay: number;
  onVictoryAnimFinished: () => void;
};

const Tile = observer(
  ({
    location,
    onMouseEnter,
    onMouseLeave,
    onActionTaken,
    victoryAnimDelay,
    onVictoryAnimFinished,
  }: TileProps) => {
    const {
      grid,
      longPressThreshold,
      inputModel,
      state: gameState,
    } = useGameStore();
    const tile = getLocation<ITile>(grid.tiles, location);
    const [actionStartTimestamp, setActionStartTimestamp] = React.useState<
      number | null
    >(null);
    const [longPressThresholdReached, setLongPressThresholdReached] =
      React.useState(false);
    const longPressTimeoutRef = React.useRef<number>();
    const [isJustRevealed, setIsJustRevealed] = React.useState(false);
    const [lastActionTimestamp, setLastActionTimestamp] = React.useState(0);
    const theme = useTheme();

    React.useEffect(() => {
      if (tile.isRevealed) {
        setIsJustRevealed(true);
      }
    }, [tile.isRevealed]);

    let icon: ReactElement | string = "🚫";

    const areaClues = getLocationsByType(grid.tiles, "clue-area");
    const isAnyAreaClueNearby = areaClues.some((clueLocation) => {
      const targetedLocations = getAreaClueLocations(grid.tiles, clueLocation);

      return targetedLocations.some((targeted) =>
        areLocationsEqual(targeted, location)
      );
    });

    switch (tile.type) {
      case "empty":
        const neighboringPoiCount = calcNeighboringPoiCount(
          grid.tiles,
          location
        );

        if (neighboringPoiCount === 0) {
          icon = "";
        } else {
          icon = "" + neighboringPoiCount;
        }
        break;
      case "bomb":
        icon = <NonSaveableImage src={images["skull.svg"]} />;
        break;
      case "treasure":
        icon = <NonSaveableImage src={images["crystal.svg"]} />;
        break;
      case "clue-arrow":
        const arrowValue = calcArrowValue(grid.tiles, location);

        switch (tile.arrowDirection) {
          case DIRECTIONS.UP:
            icon = <ClueIcon value={arrowValue} type="up" />;
            break;
          case DIRECTIONS.DOWN:
            icon = <ClueIcon value={arrowValue} type="down" />;
            break;
          case DIRECTIONS.LEFT:
            icon = <ClueIcon value={arrowValue} type="left" />;
            break;
          case DIRECTIONS.RIGHT:
            icon = <ClueIcon value={arrowValue} type="right" />;
            break;
        }

        break;
      case "clue-area":
        const areaValue = calcAreaValue(grid.tiles, location);

        icon = <ClueIcon value={areaValue} type="area" />;
        break;
      default:
        break;
    }

    if (!tile.isRevealed) {
      icon = "";
    }

    if (tile.marking === "flagged") {
      icon = <NonSaveableImage src={images["flag-fill.svg"]} />;
    }

    // if (tile.marking === "safe") {
    //   icon = "✅";
    // }

    const neighborPoiCount = calcNeighboringPoiCount(grid.tiles, location);

    // This is last so that the hint can
    // override other types of player marking.
    if (tile.isHinted) {
      if (tile.type === "empty" && neighborPoiCount > 0) {
        icon = <NonSaveableImage src={images["Caved-Icon-Hash_SVG.svg"]} />;
      } else {
        icon = (
          <NonSaveableImage src={images["Caved-Icon-Exclamation_SVG.svg"]} />
        );
      }
    }

    const onDownEvent = () => {
      if (Date.now() - lastActionTimestamp < ACTION_DEBOUNCE_BUFFER) {
        // If it has not been long enough since the last action
        // ignore this down event and do not start a new
        // action.
        return;
      }

      setActionStartTimestamp(Date.now());

      longPressTimeoutRef.current = setTimeout(() => {
        setLongPressThresholdReached(true);
      }, longPressThreshold);
    };

    const onUpEvent = (
      event:
        | React.MouseEvent<HTMLDivElement, MouseEvent>
        | React.TouchEvent<HTMLDivElement>
    ) => {
      // There should always be a timestamp
      // created in the corresponding down event before
      // this. If not, we're in a bad state or the
      // down event was debounced.
      if (actionStartTimestamp === null) {
        return;
      }

      // More than one up event can be triggered per
      // down event. For example, on mobile an up
      // event will trigger for touchEnd and mouseUp
      // since mobile devices sometimes translate touch
      // events into click events.
      event.preventDefault();

      const cleanup = () => {
        setActionStartTimestamp(null);
        setLongPressThresholdReached(false);
        clearTimeout(longPressTimeoutRef.current);
        longPressTimeoutRef.current = undefined;
      };

      let isPrimaryAction = true;

      switch (inputModel) {
        case "tapToReveal":
        case "tapToMark":
          const actionTimeLength = Date.now() - actionStartTimestamp;

          if (actionTimeLength >= longPressThreshold) {
            isPrimaryAction = false;
          }

          // As of 1/5/24, tapToReveal is the only
          // valid input model. There's no more switching input models.
          // Therefore, if we're a MouseEvent, we should prefer
          // these conditions pulled from the old
          // click input model.
          if (isMouseEvent(event)) {
            switch (true) {
              case event.button === 0 && !event.ctrlKey:
                isPrimaryAction = true;

                // Allow long press on desktop/mouse input
                // to override primary action.
                if (actionTimeLength >= longPressThreshold) {
                  isPrimaryAction = false;
                }
                break;
              // This case is to support the MacOS
              // ctrl+click right click behavior.
              // This will trigger on any operating
              // system though.
              case event.button === 0 && event.ctrlKey:
                isPrimaryAction = false;
                break;
              case event.button === 2:
                isPrimaryAction = false;
                break;
              default:
                // If any other kind of mouse button is
                // clicked we don't want to treat that as a click.
                // Escape from this function.
                cleanup();
                return;
            }
          }
          break;
        case "click":
        default:
          if (!isMouseEvent(event)) {
            // Ignore touch events if we're in "click"
            // inputModel. We could try and handle both
            // simultaneously, but it would take a bit
            // of a rewrite where we split up touch and
            // click handlers. I think this is probably
            // not needed for our game.
            cleanup();
            return;
          }

          switch (event.button) {
            case 0:
              isPrimaryAction = true;
              break;
            case 2:
              isPrimaryAction = false;
              break;
            default:
              // If any other kind of mouse button is
              // clicked we don't want to treat that as a click.
              // Escape from this function.
              cleanup();
              return;
          }
          break;
      }

      setLastActionTimestamp(Date.now());
      onActionTaken(isPrimaryAction);
      cleanup();
    };

    return (
      <TileContainer
        borders={getAllClueBorders(grid.tiles, location)}
        onAnimationEnd={(event) => {
          if (event.animationName === theme.animations.tileReveal) {
            setIsJustRevealed(false);

            if (gameState === "victory") {
              onVictoryAnimFinished();
            }
          }
        }}
        onMouseDown={onDownEvent}
        onMouseUp={onUpEvent}
        onTouchStart={onDownEvent}
        onTouchEnd={onUpEvent}
        onPointerEnter={onMouseEnter}
        onPointerLeave={onMouseLeave}
        isClueSatisfied={isClueSatisfied(grid.tiles, location)}
        isRevealed={tile.isRevealed}
        isAnyAreaClueNearby={isAnyAreaClueNearby}
        color={getNumberColor(grid.tiles, location)}
        isPoi={isPoi(tile)}
        isClue={isClue(tile)}
        isFlagged={tile.marking === "flagged"}
        isInHoveredClueArea={grid.isLocationInHoveredClueArea(location)}
        isJustRevealed={isJustRevealed}
        isLongPressed={longPressThresholdReached}
        isPlayerVictorious={gameState === "victory"}
        victoryAnimDelay={victoryAnimDelay}
      >
        <div>{icon}</div>
      </TileContainer>
    );
  }
);

export default Tile;
