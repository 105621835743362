import * as React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import Settings from "../Settings";
import useWindowOrientation from "../../utils/breakpoints/useWindowOrientation";
import MainMenuContent from "./MainMenuContent";
import BgVertical from "./Background/BgVertical";
import BgHorizontal from "./Background/BgHorizontal";
import Version from "./Version";

const MainMenuContainer = styled.div`
  color: ${(props) => props.theme.colors.text};
  text-align: center;

  // This is a hack to delay the fadeIn animation
  // on the main menu.
  animation-delay: 150ms;
`;

const SettingsSizer = styled.div`
  & > * {
    /* transform: scale(1.5);
    width: 3rem;
    height: 3rem; */
  }

  padding: 1.8rem 1.25rem;

  @media (max-width: 920px) or (max-height: 530px) {
    padding: 1rem;
  }

  @media (max-width: 670px) or (max-height: 445px) {
    padding: 0.35rem;
  }
`;

type AbsolutePositionProps = {
  backLayer?: boolean;
  position: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
};

const AbsolutePosition = styled.div<AbsolutePositionProps>`
  position: absolute;
  top: ${(props) =>
    props.position.toLowerCase().includes("top") ? 0 : "auto"};
  bottom: ${(props) =>
    props.position.toLowerCase().includes("bottom") ? 0 : "auto"};
  left: ${(props) =>
    props.position.toLowerCase().includes("left") ? 0 : "auto"};
  right: ${(props) =>
    props.position.toLowerCase().includes("right") ? 0 : "auto"};

  z-index: ${(props) => (props.backLayer ? -1 : "auto")};
`;

const MainMenu = observer(() => {
  const windowOrientation = useWindowOrientation();

  return (
    <MainMenuContainer>
      <AbsolutePosition backLayer position="topLeft">
        {windowOrientation === "landscape" ? <BgHorizontal /> : <BgVertical />}
      </AbsolutePosition>
      <MainMenuContent />
      <AbsolutePosition position="topRight">
        <SettingsSizer>
          <Settings embedded />
        </SettingsSizer>
      </AbsolutePosition>
      <AbsolutePosition position="bottomLeft">
        <Version />
      </AbsolutePosition>
    </MainMenuContainer>
  );
});

export default MainMenu;
