import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  clone,
  flow,
  getParent,
  getParentOfType,
  getRoot,
  getSnapshot,
  types,
} from "mobx-state-tree";
import { compareVersions } from "compare-versions";
import ModalModel from "./Modal";
import attachMessageListener from "../utils/amu/attachMessageListener";
import {
  sendAmuPauseMessage,
  sendAmuReadyMessage,
  sendAmuStartMessage,
  sendAmuStateMessage,
} from "../utils/amu/sendAmuMessages";
import isIFramedByOtherSite from "../utils/amu/isIFramedByOtherSite";
import {
  AmuState,
  IAmuMessage,
  IAmuState,
  IUserSettingsSnapshotDetails,
  UserSettingsSnapshotDetails,
} from "../utils/amu/AmuMessage";
import { IRoot, RootModel, isRoot } from "./Root";
import parseQueryParams from "../utils/settings/parseQueryParams";
import generateLevelData from "../utils/generation/generateLevelData";
import LoadingModel from "./Loading";
import { ITileSnapshotIn } from "./Tile";
import getRevealedBombs from "../utils/game/getRevealedBombs";
import calculateScore from "../utils/game/calculateScore";
import AmuEvents from "./AmuEvents";
import appendOrdinalSuffix from "../utils/appendOrdinalSuffix";
import testLevelFile from "../../tests/data/level (24).json";
import SettingsModel from "./Settings";
import parseAmuMessage, {
  GameStateSnapshot,
  UserSettingsSnapshot,
  UserSettingsSnapshotType,
} from "../utils/amu/parseAmuMessage";
import messageParent from "../utils/amu/messageParent";
import initGameMessage from "../../tests/data/events/init_game.json";
import initGameInProgressMessage from "../../tests/data/events/init_game_in_progress.json";
import initGameWithConfigMessage from "../../tests/data/events/init_game_in_progress_config_changes.json";
import { ConfigSchema } from "../utils/amu/parseConfigFile";
import themes from "../styles/themes";
import merge from "lodash.merge";
import transformObject from "../utils/transformObject";
import packageInfo from "../../package.json";
import GameModel from "./Game";
import { sounds } from "../utils/assets/audio";
import isPerfectScore from "../utils/game/isPerfectScore";

const AppState = types.enumeration([
  "loading",
  "waitingForInitGame",
  "splash",
  "mainMenu",
  "game",
  "resetting",
  "failed",
]);

const AppModel = types
  .model({
    state: types.optional(AppState, "loading"),
    loading: LoadingModel,
    onboarding: types.model({
      isCompleted: types.optional(types.boolean, false),
    }),
    transitioningTo: types.maybe(AppState),
    modal: ModalModel,
    events: AmuEvents,
    settings: SettingsModel,
    initGameTimeout: types.optional(types.number, 5000),
    themeKey: types.enumeration([
      "defaultTheme",
      "mainMenuTheme",
      "loadingTheme",
    ]),
    text: types.model({
      gameUrl: types.optional(
        types.string,
        "https://www.puzzlesociety.com/member/logic-puzzles/caved"
      ),
      letsGoUrl: types.optional(
        types.string,
        "https://www.puzzlesociety.com/member/friends"
      ),
      moreGamesUrl: types.optional(
        types.string,
        "https://www.puzzlesociety.com/member/all-games"
      ),
      gameName: types.optional(types.string, "Caved"),
      playButton: types.optional(types.string, "Play"),
      topBarResetButton: types.optional(types.string, "Reset"),
      topBarResultsButton: types.optional(types.string, "Results"),
      by: types.optional(types.string, "By"),
      failedToLoadLevel: types.optional(types.string, "Failed to load level"),
      pauseModalBody: types.optional(types.string, "Game Paused"),
      pauseModalButton: types.optional(types.string, "Resume"),
      resetModalTitle: types.maybe(types.string),
      resetModalBody: types.maybe(types.string),
      resetModalReplayButton: types.maybe(types.string),
      resetModalBackButton: types.maybe(types.string),
      shareClipboardTotalMoves: types.maybe(types.string),
      shareClipboardSkullsHit: types.maybe(types.string),
      shareModalBackButton: types.maybe(types.string),
      shareModalTitle: types.maybe(types.string),
      shareModalBody: types.maybe(types.string),
      shareModalPlayWithFriendsTitle: types.maybe(types.string),
      shareModalPlayWithFriendsBody: types.maybe(types.string),
      shareModalPlayWithFriendsButton: types.maybe(types.string),
      statsModalPerfectScoreTitle: types.maybe(types.string),
      statsModalPerfectScoreBody: types.maybe(types.string),
      statsModalNormalScoreTitle: types.maybe(types.string),
      statsModalNormalScoreBody: types.maybe(types.string),
      societyPointsPerfectScore: types.maybe(types.string),
      societyPointsNormalScore: types.maybe(types.string),
      statsModalScoreLabel: types.maybe(types.string),
      statsModalRevealedBombCountLabel: types.maybe(types.string),
      statsModalTimeLabel: types.maybe(types.string),
      statsModalCluesRevealedLabel: types.maybe(types.string),
      statsModalShareWithAFriend: types.maybe(types.string),
      statsModalMoreGames: types.maybe(types.string),
      onboardingModalWelcome: types.maybe(types.string),
      settingsMenuMuteLabel: types.maybe(types.string),
      settingsMenuUseHintsLabel: types.maybe(types.string),
      settingsMenuShowTutorialLabel: types.maybe(types.string),
    }),
    html: types.model({
      coinParagraph: types.maybe(types.string),
      onboardingModalSlide1Paragraph1: types.maybe(types.string),
      onboardingModalSlide1Paragraph2: types.maybe(types.string),
      onboardingModalSlide2Body: types.maybe(types.string),
      onboardingModalSlide3Body: types.maybe(types.string),
      onboardingModalSlide4Body: types.maybe(types.string),
      onboardingModalSlide5Body: types.maybe(types.string),
      onboardingModalSlide6Body: types.maybe(types.string),
    }),
    audio: types.model({
      isMuted: types.optional(types.boolean, false),
    }),
    isPaused: types.optional(types.boolean, false),
    isSettingsMenuOpen: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get config(): ConfigSchema | undefined {
      if (
        self.loading.configFile.status === "done" &&
        self.loading.configFile.file
      ) {
        return self.loading.configFile.file.config;
      }

      return undefined;
    },
    get issueDateWeekdayThreeChar() {
      const now = new Date();
      const issueDate = self.loading.levelIssueDate
        ? self.loading.levelIssueDate
        : now;

      const day = new Intl.DateTimeFormat("en-US", {
        weekday: "short",
      }).format(issueDate);

      return day.toLowerCase() as
        | "mon"
        | "tue"
        | "wed"
        | "thu"
        | "fri"
        | "sat"
        | "sun";
    },
  }))
  .views((self) => ({
    get issueDateDisplayText() {
      const now = new Date();
      const issueDate = self.loading.levelIssueDate
        ? self.loading.levelIssueDate
        : now;

      const day = new Intl.DateTimeFormat("default", {
        weekday: "long",
      }).format(issueDate);

      const month = new Intl.DateTimeFormat("default", {
        month: "long",
      }).format(issueDate);

      const date = new Intl.DateTimeFormat("default", {
        day: "numeric",
      }).format(issueDate);

      const year = new Intl.DateTimeFormat("default", {
        year: "numeric",
      }).format(issueDate);

      return `${day}, ${month} ${appendOrdinalSuffix(
        parseInt(date, 10)
      )}, ${year}`;
    },
    get issueDateDisplayTextShort() {
      const now = new Date();
      const issueDate = self.loading.levelIssueDate
        ? self.loading.levelIssueDate
        : now;

      const month = new Intl.DateTimeFormat("default", {
        month: "2-digit",
      }).format(issueDate);

      const date = new Intl.DateTimeFormat("default", {
        day: "2-digit",
      }).format(issueDate);

      const year = new Intl.DateTimeFormat("default", {
        year: "numeric",
      }).format(issueDate);

      return `${month}/${date}/${year}`;
    },
    get issueDateSerialized() {
      if (!self.loading.levelIssueDate) {
        return;
      }

      const issueDate = self.loading.levelIssueDate;

      const month = new Intl.DateTimeFormat("default", {
        month: "2-digit",
      }).format(issueDate);

      const date = new Intl.DateTimeFormat("default", {
        day: "2-digit",
      }).format(issueDate);

      const year = new Intl.DateTimeFormat("default", {
        year: "numeric",
      }).format(issueDate);

      return `${year}-${month}-${date}`;
    },
    get configuredTheme() {
      const startingTheme = themes[self.themeKey];
      let configuredTheme = startingTheme;

      // These are the only keys actually exposed
      // in the config file, but there are more
      // theme keys on the themes object.
      if (
        self.themeKey === "defaultTheme" ||
        self.themeKey === "mainMenuTheme"
      ) {
        configuredTheme = merge(
          {},
          startingTheme,
          self.config?.themes?.defaultTheme ?? {},
          self.config?.themes?.[self.themeKey] ?? {}
        );
      }

      if (self.config?.colors) {
        configuredTheme.colors = transformObject(
          configuredTheme.colors,
          (colorValue) => {
            return self.config?.colors?.[colorValue] ?? colorValue;
          }
        );
      }

      return configuredTheme;
    },
    get issueDatePar() {
      const { pars } = self.settings.gameplay;
      const par = pars[self.issueDateWeekdayThreeChar];

      if (!par) {
        console.warn(
          `Par not found for "${self.issueDateWeekdayThreeChar}". Defaulting to 15.`
        );
        return 15;
      }

      return par;
    },
  }))
  .actions((self) => ({
    // TODO: I'm not sure how to access the underlying union type
    // of AppState to accept it here. Ideally, I'd do
    // typeof AppState here. Instead, I'm redefining the manual
    // union type.
    startTransition(newState: "loading" | "splash" | "mainMenu" | "game") {
      self.transitioningTo = newState;
    },
    finishTransition() {
      if (!self.transitioningTo) {
        // No active transition
        return;
      }

      self.state = self.transitioningTo;
      self.transitioningTo = undefined;
    },
    createAmuState(): IAmuState {
      const root = getRoot(self);

      if (!isRoot(root)) {
        return AmuState.create();
      }

      const { game } = root;

      const score = calculateScore({
        game,
        settings: self.settings,
        isInitialized: true,
      });
      return AmuState.create({
        snapshot: game.createGameStateSnapshot(),
        isCompleted: game.state === "victory" || game.state === "review",
        madeMistakes: getRevealedBombs(game.grid).length > 0,
        score: {
          value: score,
          formattedScore: score + "",
        },
        totalPlayTime: game.timer.getElapsedTimeMilliSeconds(),
        earnedPerfectScore: isPerfectScore({
          // @ts-ignore
          app: self,
          game,
          settings: self.settings,
          isInitialized: self.state !== "loading",
        }),
      });
    },
    createAmuUserSettingsSnapshot(): UserSettingsSnapshotType {
      const { game } = getParentOfType(self, RootModel);

      return UserSettingsSnapshot.parse({
        isMuted: self.audio.isMuted,
        isOnboardingComplete: self.onboarding.isCompleted,
        areHintsEnabled: game.shouldHintOnPrimaryAction,
      });
    },
    parseQueryParams() {
      const settingsFromQueryParams = parseQueryParams();

      self.settings = SettingsModel.create(settingsFromQueryParams);
    },
    processConfig(config: ConfigSchema) {
      // We may need to check appVersion on
      // this config in the future.
      self.settings.loadConfigGameplaySettings(config.gameplaySettings);
      // Not actually created settings in game yet:
      // - hintOptionsDefault
      // - showHintsOptionInSettingsMenu

      if (config.text) {
        const textEntries = Object.entries(config.text);
        textEntries.forEach(([textKey, textValue]) => {
          // This is a legit ts typing issue. The config
          // Text type is not the same as the text mst
          // model that App has. Therefore, those two
          // types can get out of sync.
          //
          // TS has no way to know they're the same at
          // type of authoring I guess?
          //
          // Either way, it _should_ throw type
          // errors in the process of creating new text
          // strings so we'll probably catch it.
          // @ts-ignore
          self.text[textKey] = textValue;
        });
      }

      if (config.html) {
        const htmlEntries = Object.entries(config.html);
        htmlEntries.forEach(([htmlKey, htmlValue]) => {
          // This is a legit ts typing issue. The config
          // Text type is not the same as the text mst
          // model that App has. Therefore, those two
          // types can get out of sync.
          //
          // TS has no way to know they're the same at
          // type of authoring I guess?
          //
          // Either way, it _should_ throw type
          // errors in the process of creating new text
          // strings so we'll probably catch it.
          // @ts-ignore
          self.html[htmlKey] = htmlValue;
        });
      }
    },
    setThemeKey(
      newThemeKey: "loadingTheme" | "mainMenuTheme" | "defaultTheme"
    ) {
      self.themeKey = newThemeKey;
    },
    toggleSettingsMenuOpen() {
      self.isSettingsMenuOpen = !self.isSettingsMenuOpen;
    },
  }))
  .actions((self) => ({
    showMainMenu() {
      self.state = "mainMenu";
    },
    startGame() {
      self.events.sendStartEvent();
      self.state = "game";
    },
    finishOnboarding() {
      self.onboarding.isCompleted = true;
      self.events.sendOnboardingCompleteEvent(
        self.createAmuUserSettingsSnapshot()
      );
    },
    skipOnboarding() {
      self.onboarding.isCompleted = true;
      self.events.sendOnboardingSkipEvent(self.createAmuUserSettingsSnapshot());
    },
    setMute(newValue: boolean) {
      self.audio.isMuted = newValue;
      self.events.sendChangeSettingsEvent(self.createAmuUserSettingsSnapshot());
    },
  }))
  .actions((self) => ({
    finishLoading() {
      if (self.state !== "loading" && self.state !== "resetting") {
        return;
      }

      const { game } = getParentOfType(self, RootModel);

      /**
       * We only want to load settings from the loading object in
       * the loading state.
       *
       * Otherwise, we should not override whatever the user currently
       * has set.
       */
      if (self.state === "loading" && self.loading.settings?.snapshot) {
        self.audio.isMuted = self.loading.settings.snapshot.isMuted;
        game.setShouldHintOnPrimaryAction(
          self.loading.settings.snapshot.areHintsEnabled
        );
        self.onboarding.isCompleted =
          self.loading.settings.snapshot.isOnboardingComplete;
      }

      // Verify all information necessary is loaded, otherwise
      // serve default or fallback data.
      //
      // Then set up the app and game.

      let tiles: ITileSnapshotIn[][] | undefined = self.loading.getLevelTiles();
      let startLocation = self.loading.getLevelStartLocation();

      if (!tiles) {
        // Did not load a level
        if (!self.settings.shouldGenLevel) {
          self.state = "failed";
          return;
        }

        // TODO:
        // Hardcode in a level data file manually
        // here since Codex doesn't let us test
        // files uploaded/passed in via message
        // correctly.
        // const levelData = testLevelFile;
        const levelData = generateLevelData({
          settings: self.settings.generation,
        });

        tiles = levelData.tiles;
        startLocation = levelData.startLocation;
      }

      if (self.loading.onEvent) {
        self.events = AmuEvents.create({
          onEvents: getSnapshot(self.loading.onEvent),
        });
      }

      if (
        self.loading.configFile.status === "done" &&
        self.loading.configFile.file
      ) {
        self.processConfig(self.loading.configFile.file.config);
      }

      // const { game } = getParent(self) as IRoot;

      if (game.state !== "stopped") {
        // If game is already running, or in victory
        // state we need to stop and reset it so
        // it can be init'd again
        game.stop();
      }
      game.reset();
      game.init(tiles, startLocation);

      if (self.loading.amuState?.snapshot) {
        const { snapshot } = self.loading.amuState;

        const isSnapshotSameIssueDate =
          snapshot.levelIssueDateString !== undefined &&
          snapshot.levelIssueDateString === self.issueDateSerialized;
        const isCurrentVersionGreaterOrEqual =
          snapshot.appVersion !== undefined &&
          compareVersions(packageInfo.version, snapshot.appVersion) <= 0;

        if (isSnapshotSameIssueDate && isCurrentVersionGreaterOrEqual) {
          console.log("Hydrating from save state", getSnapshot(snapshot));
          game.hydrateFromGameStateSnapshot(clone(snapshot));
        } else {
          console.warn("Failed to parse user save snapshot", {
            isSnapshotSameIssueDate,
            isCurrentVersionGreaterOrEqual,
            snapshot: {
              appVersion: snapshot.appVersion,
              levelIssueDateString: snapshot.levelIssueDateString,
            },
            current: {
              appVersion: packageInfo.version,
              issueDateSerialized: self.issueDateSerialized,
            },
          });
        }
      }

      if (self.state === "resetting") {
        self.state = "game";
        return;
      }

      self.startTransition("splash");
    },
    receivedInitGame() {
      if (self.state === "waitingForInitGame") {
        self.state = "loading";
      }
    },
  }))
  .actions((self) => ({
    handleAmuLoadMessage: flow(function* initGame(message: IAmuMessage) {
      self.loading.handleAmuMessage(message, self.finishLoading);
    }),
    pause() {
      if (self.isPaused) {
        return;
      }

      if (self.state !== "game") {
        // Only the game can be paused
        return;
      }

      const { game } = getParentOfType(self, RootModel);
      if (game.state !== "running") {
        // Can only pause while game is running
        return;
      }

      if (self.isSettingsMenuOpen) {
        // Cannot pause while settings menu is open
        // Probably the timer is supposed to be
        // paused in this state which makes this
        // irrelevant anyway.
        return;
      }

      self.isPaused = true;

      game.pause();

      self.events.sendPauseEvent();

      if (self.settings.gameplay.showPause) {
        self.modal.show("pause");
      }
      console.log("Pausing game.");
    },
    resume() {
      if (!self.isPaused) {
        return;
      }

      self.isPaused = false;

      const { game } = getParentOfType(self, RootModel);
      game.resume();

      self.events.sendResumeEvent();
      console.log("Resuming game.");
    },
  }))
  .actions((self) => ({
    onAmuMessage(messageSnapshot: any) {
      try {
        if (messageSnapshot?.loadState?.snapshot) {
          // parseAmuMessage will move forward with an empty
          // snapshot if it fails, we still want to see
          // these errors so we'll parse here as well.
          const result = GameStateSnapshot.safeParse(
            messageSnapshot?.loadState?.snapshot
          );

          if (!result.success) {
            console.warn("Game save snapshot errors: ", result.error);
          }
        }

        const message = parseAmuMessage(messageSnapshot);
        if (message.initGame) {
          self.receivedInitGame();
        }

        if (!message.initGame && message.loadLevel) {
          // Load level command will take game out of current
          // state and put it back into loading state to
          // overwrite current game instance.
          self.state = "loading";

          self.modal.hide();

          // We need to reset old loading data so that the
          // new loading data can overwrite it.
          self.loading.resetForLoadLevel();

          // Is there other app data that needs to be reset?
        }

        if (message.initGame || message.loadLevel) {
          self.handleAmuLoadMessage(message);
        }

        if (message.getState) {
          sendAmuStateMessage(self.createAmuState());
        }

        if (message.pauseGame) {
          self.pause();
        }
      } catch (err) {
        console.error("Failed to parse AmuMessage", err);
      }
    },
    replayCurrentLevel() {
      self.state = "resetting";
      self.loading.clearState();
      self.finishLoading();
    },
    attachPauseResumeEvents() {
      // window focus events
      window.addEventListener("blur", () => {
        self.pause();
      });
      // window.addEventListener("focus", () => {
      //   self.resume();
      // });

      // AMU can trigger pause on us
      // tell AMU we resumed if user
      // interacts with us again.
      ["mousedown", "touchstart", "pointerdown"].map((eventName) => {
        window.addEventListener(eventName, () => {
          self.resume();
        });
      });
    },
  }))
  .actions((self) => ({
    startLoading() {
      self.state = "loading";

      self.parseQueryParams();

      self.attachPauseResumeEvents();

      if (isIFramedByOtherSite(self.settings.gameplay)) {
        self.state = "waitingForInitGame";

        attachMessageListener(self.onAmuMessage);
        sendAmuReadyMessage(self.createAmuState());

        // TODO:
        // We're sending a fake init game message
        // if we're faking AMU
        if (self.settings.gameplay.fakeAmu) {
          messageParent(initGameWithConfigMessage);
        }

        // If we have not received initGame message in this
        // timeout duration, we'll force finishLoading instead.
        setTimeout(() => {
          if (self.state === "waitingForInitGame") {
            self.receivedInitGame();
            self.finishLoading();
          }
        }, self.initGameTimeout);
      } else {
        // We're not iframed, just go ahead with normal boot
        self.finishLoading();
      }
    },
    playSound(key: keyof typeof sounds) {
      if (self.audio.isMuted) {
        return;
      }

      sounds[key]?.play();
    },
    replay() {
      self.events.sendReplayEvent();
      self.replayCurrentLevel();
    },
  }));

export interface IApp extends Instance<typeof AppModel> {}
export interface IAppSnapshotIn extends SnapshotIn<typeof AppModel> {}
export interface IAppSnapshotOut extends SnapshotOut<typeof AppModel> {}
export default AppModel;
