{
  "initGame": true,
  "loadLevel": {
    "issueDate": "2023-12-01",
    "files": [
      {
        "name": "",
        "url": "http://127.0.0.1:8080/level_12_9_23a.json",
        "mimeType": "text/plain",
        "originalFileName": "level_12_9_23a.json"
      }
    ]
  },
  "loadConfig": {
    "name": "puzzlesociety",
    "files": [
      {
        "name": "",
        "url": "http://127.0.0.1:8080/config_12_9_23a.json",
        "mimeType": "application/json",
        "originalFileName": "config_12_9_23a.json"
      }
    ]
  },
  "onEvent": ["all"],
  "loadState": {
    "snapshot": {
      "grid": {
        "tiles": [
          [
            {
              "type": "clue-arrow",
              "arrowDirection": "DOWN",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "treasure",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "clue-arrow",
              "arrowDirection": "LEFT",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "clue-arrow",
              "arrowDirection": "DOWN",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "clue-area",
              "areaOffsetLocations": [
                { "row": -2, "col": -2 },
                { "row": -2, "col": -1 },
                { "row": -2, "col": 0 },
                { "row": -2, "col": 1 },
                { "row": -2, "col": 2 },
                { "row": -1, "col": -2 },
                { "row": -1, "col": -1 },
                { "row": -1, "col": 0 },
                { "row": -1, "col": 1 },
                { "row": -1, "col": 2 },
                { "row": 0, "col": -2 },
                { "row": 0, "col": -1 },
                { "row": 0, "col": 1 },
                { "row": 0, "col": 2 },
                { "row": 1, "col": -2 },
                { "row": 1, "col": -1 },
                { "row": 1, "col": 0 },
                { "row": 1, "col": 1 },
                { "row": 1, "col": 2 },
                { "row": 2, "col": -2 },
                { "row": 2, "col": -1 },
                { "row": 2, "col": 0 },
                { "row": 2, "col": 1 },
                { "row": 2, "col": 2 }
              ],
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "treasure",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "clue-arrow",
              "arrowDirection": "LEFT",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "treasure",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "clue-arrow",
              "arrowDirection": "LEFT",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "treasure",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "treasure",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "clue-area",
              "areaOffsetLocations": [
                { "row": -2, "col": -2 },
                { "row": -2, "col": -1 },
                { "row": -2, "col": 0 },
                { "row": -2, "col": 1 },
                { "row": -2, "col": 2 },
                { "row": -1, "col": -2 },
                { "row": -1, "col": -1 },
                { "row": -1, "col": 0 },
                { "row": -1, "col": 1 },
                { "row": -1, "col": 2 },
                { "row": 0, "col": -2 },
                { "row": 0, "col": -1 },
                { "row": 0, "col": 1 },
                { "row": 0, "col": 2 },
                { "row": 1, "col": -2 },
                { "row": 1, "col": -1 },
                { "row": 1, "col": 0 },
                { "row": 1, "col": 1 },
                { "row": 1, "col": 2 },
                { "row": 2, "col": -2 },
                { "row": 2, "col": -1 },
                { "row": 2, "col": 0 },
                { "row": 2, "col": 1 },
                { "row": 2, "col": 2 }
              ],
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "bomb",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ],
          [
            {
              "type": "clue-arrow",
              "arrowDirection": "UP",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": false,
              "marking": "flagged",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "bomb",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            },
            {
              "type": "empty",
              "isRevealed": true,
              "marking": "none",
              "isHinted": false
            }
          ]
        ],
        "hovered": null
      },
      "actions": { "count": 11 },
      "timer": {
        "elapsedTimeMs": 32002,
        "lastTrackedTimeStamp": 1702158946491,
        "isRunning": true
      }
    },
    "isCompleted": false,
    "madeMistakes": true,
    "score": { "value": 41 },
    "totalPlayTime": 32002
  },
  "loadSettings": {
    "expertMode": true,
    "showConflicts": false,
    "showErrors": false,
    "showRelated": false,
    "darkMode": true,
    "sound": true
  }
}
