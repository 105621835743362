import * as SeedRandom from "seedrandom";

/**
 * Default to current timestamp for seed.
 */
let seed = Date.now().toString();
// TODO: remove temp hardcode
// seed = "test";
let rng = SeedRandom(seed, { state: true });

function random() {
  return rng();
}

function setSeed(newSeed: string) {
  seed = newSeed;
  rng = SeedRandom(newSeed, { state: true });
}

function getSeed() {
  return seed;
}

function resetCurrentSeed() {
  setSeed(getSeed());
}

function getState() {
  return rng.state();
}

function createFromState(newSeed: string, state: object) {
  seed = newSeed;
  rng = SeedRandom("", { state });
}

export {
  random,
  setSeed,
  getSeed,
  resetCurrentSeed,
  getState,
  createFromState,
};
