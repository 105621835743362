import { Location } from "./types";

function addLocations(a: Location, b: Location) {
  return {
    row: a.row + b.row,
    col: a.col + b.col,
  };
}

export default addLocations;
