import { observer } from "mobx-react-lite";
import * as React from "react";
import styled, { DefaultTheme, ThemeProvider } from "styled-components";
import "normalize.css";
import CacheProvider from "react-inlinesvg/provider";
import { RootContextProvider, rootStore, useRootStore } from "../models/Root";
import defaultTheme from "../styles/defaultTheme";
import GlobalStyle from "../styles/GlobalStyle";
import AppStateRouter from "./AppStateRouter";
import Center from "./Center";
import ModalStateRouter from "./modal/ModalStateRouter";
import themes from "../styles/themes";

const AppContainer = styled.div`
  padding: 1rem;
`;

const App = observer(() => {
  const { app } = useRootStore();

  return (
    <ThemeProvider theme={app.configuredTheme}>
      <Center>
        <AppContainer id="app">
          <GlobalStyle />
          <AppStateRouter setTheme={app.setThemeKey} />
          <ModalStateRouter />
        </AppContainer>
      </Center>
    </ThemeProvider>
  );
});

const withProviders = (WrappedComponent: typeof App) => {
  return () => {
    return (
      <RootContextProvider value={rootStore}>
        <CacheProvider>
          <WrappedComponent />
        </CacheProvider>
      </RootContextProvider>
    );
  };
};

export default withProviders(App);
