import * as React from "react";
import styled from "styled-components";

const ModalBackgroundContainer = styled.div`
  position: relative;

  background-color: ${(props) => props.theme.colors.modal.background};
  border-radius: 1rem;

  text-align: center;
`;

const ModalBackground = ({
  children,
  className,
}: React.PropsWithChildren & { className?: string }) => {
  return (
    <ModalBackgroundContainer
      onClick={(e) => e.stopPropagation()}
      className={className}
    >
      {children}
    </ModalBackgroundContainer>
  );
};

export default ModalBackground;
