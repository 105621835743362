import React from "react";
import styled from "styled-components";
import images from "../../utils/assets/images";
import { useRootStore } from "../../models/Root";
import useSettings from "../../hooks/useSettings";
import { observer } from "mobx-react-lite";

const MainMenuContentContainer = styled.div`
  max-width: 80%;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
`;

const ByLine = styled.p`
  font-family: ${(props) => props.theme.fonts.serifStack};
  font-size: smaller;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.textSecondary};

  margin: 0;
`;

const Button = styled.button`
  font-family: ${(props) => props.theme.fonts.serifStack};
  font-weight: bold;
  text-transform: uppercase;

  border: 0;
  border-radius: 6px;

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) =>
      props.theme.colors.mainMenu.playButtonDisabled};
  }
`;

const PlayButton = styled(Button)`
  color: ${(props) => props.theme.colors.mainMenu.playButtonText};
  background-color: ${(props) => props.theme.colors.mainMenu.playButtonBg};

  padding: 0.5rem 3rem;

  margin-top: 3rem;
`;

const TutorialButton = styled(Button)`
  color: ${(props) => props.theme.colors.mainMenu.helpButtonText};
  background-color: ${(props) => props.theme.colors.mainMenu.helpButtonBg};

  padding: 0.5rem 0.75rem;

  margin-left: 0.5rem;
`;

const IssueDate = styled.p`
  font-size: smaller;
  margin: 0;
  margin-top: 0.5rem;
`;

const MainMenuContent = observer(() => {
  const { app } = useRootStore();
  const [settings] = useSettings();

  return (
    <MainMenuContentContainer>
      <img src={images["Caved -Title_SVG.svg"]} />
      <ByLine>{app.text.by} Ryan Kubik</ByLine>
      <PlayButton
        disabled={app.state === "failed"}
        onClick={() => {
          // Skip the tutorial in dev mode
          if (settings.gameplay.isDev) {
            app.startGame();
            return;
          }

          if (app.onboarding.isCompleted) {
            app.startGame();
          } else {
            app.modal.show("onboarding");
          }
        }}
      >
        {app.text.playButton}
      </PlayButton>
      <TutorialButton
        onClick={() => {
          app.events.sendHelpEvent();
          app.modal.show("onboarding");
        }}
      >
        ?
      </TutorialButton>
      <IssueDate>
        {app.state === "failed"
          ? app.text.failedToLoadLevel
          : app.issueDateDisplayText}
      </IssueDate>
    </MainMenuContentContainer>
  );
});

export default MainMenuContent;
