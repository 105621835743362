import { ITile } from "../models/Tile";
import { DIRECTION_LOCATIONS } from "./directions";
import getLocation from "./getLocation";
import getLocationsInDirection from "./getLocationsInDirection";
import { Location, Matrix } from "./types";

function calcArrowValue(tiles: Matrix<ITile>, location: Location) {
  const arrow = getLocation(tiles, location);

  // TODO: I think this could be removed with appropriate typing
  //       somehow, but this seemed much easier for now.
  if (!arrow.arrowDirection) {
    console.error(
      `Could not calc arrow value for arrow at: ${JSON.stringify(location)}`,
    );
    return 0;
  }

  const locationsInDirection = getLocationsInDirection(
    location,
    DIRECTION_LOCATIONS[arrow.arrowDirection],
    tiles,
  );
  const tilesInDirection = locationsInDirection.map((location) =>
    getLocation(tiles, location),
  );
  const treasures = tilesInDirection.filter((tile) => tile.type === "treasure");
  const treasureCount = treasures.length ?? 0;

  return treasureCount;
}

export default calcArrowValue;
