import { IGame } from "../../models/Game";
import createFloodFillForGrid from "../createFloodFillForGrid";
import { Location } from "../types";

function doStartingRevealAnimation({
  game,
  startLocation,
  onVictory,
}: {
  game: IGame;
  startLocation: Location;
  onVictory?: () => void;
}) {
  const { grid } = game;
  const floodFill = createFloodFillForGrid(grid);
  const revealedTiles = floodFill(grid.tiles, startLocation);

  game.animateTileReveal(
    startLocation,
    [startLocation, ...revealedTiles],
    onVictory,
  );
}

export default doStartingRevealAnimation;
