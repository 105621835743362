import { Matrix, Dimensions } from "./types";

function getDimensions<T>(matrix: Matrix<T>) {
  return {
    width: matrix[0].length,
    height: matrix.length,
  };
}

export default getDimensions;
