import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

const ModalModel = types
  .model({
    state: types.optional(
      types.enumeration([
        "none",
        "stats",
        "share",
        "onboarding",
        "reset",
        "pause",
      ]),
      "none",
    ),
  })
  .actions((self) => ({
    show: (type: "stats" | "share" | "onboarding" | "reset" | "pause") => {
      self.state = type;
    },
    hide: () => {
      self.state = "none";
    },
  }));

export interface IModal extends Instance<typeof ModalModel> {}
export interface IModalSnapshotIn extends SnapshotIn<typeof ModalModel> {}
export interface IModalSnapshotOut extends SnapshotOut<typeof ModalModel> {}
export default ModalModel;
