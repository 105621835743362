import React from "react";

import styled from "styled-components";
import images from "../../utils/assets/images";
import NonSaveableImage from "./NonSaveableImage";

const ClueIconContainer = styled.div`
  color: ${(props) => props.theme.colors.clue};
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 1rem;
  }
`;

const getOffsetForType = (type: ClueType) => {
  switch (type) {
    // case "up":
    //   return { top: -1, left: 1 };
    // case "down":
    //   return { top: -1, left: 0 };
    // case "left":
    //   return { top: -1, left: 0 };
    // case "area":
    //   return { top: -1, left: 1 };
    default:
      return { top: 0, left: 0 };
  }
};

const ClueNumber = styled.span<{ type: ClueType }>`
  top: ${(props) => getOffsetForType(props.type).top}px;
  left: ${(props) => getOffsetForType(props.type).left}px;
`;

type ClueType = "up" | "down" | "left" | "right" | "area";

const imageSources = {
  up: images["arrow-up.svg"],
  down: images["arrow-down.svg"],
  left: images["arrow-left.svg"],
  right: images["arrow-right.svg"],
  area: images["area-clue.svg"],
};

type ClueIconProps = {
  value: number;
  type: ClueType;
};

const ClueIcon = ({ value, type }: ClueIconProps) => {
  return (
    <ClueIconContainer>
      <NonSaveableImage src={imageSources[type]} />
      <ClueNumber type={type}>{value}</ClueNumber>
    </ClueIconContainer>
  );
};

export default ClueIcon;
