import { observer } from "mobx-react-lite";
import * as React from "react";
import styled, { useTheme } from "styled-components";
import { useGameStore, useRootStore } from "../models/Root";
import images from "../utils/assets/images";
import ImageButton from "./ImageButton";
import Toggle from "./Toggle";

type SettingsContainerProps = {
  embedded?: boolean;
};

const SettingsContainer = styled.div<SettingsContainerProps>`
  position: ${(props) => (props.embedded ? "relative" : "absolute")};
  top: ${(props) => (props.embedded ? "" : "1.5rem")};
  right: ${(props) => (props.embedded ? "" : "1.5rem")};
  display: ${(props) => (props.embedded ? "flex" : "")};
  flex-direction: ${(props) => (props.embedded ? "row" : "")};
  align-items: ${(props) => (props.embedded ? "center" : "")};
  justify-content: ${(props) => (props.embedded ? "center" : "")};

  font-size: ${(props) => props.theme.fonts.settings.baseSize}rem;

  & > button {
    line-height: 1rem;
  }

  object {
    /* width: ${(props) => (props.embedded ? "1.5rem" : "")};
    height: ${(props) => (props.embedded ? "1.5rem" : "")}; */
  }
`;

// TODO:
// Font-size of settings menu should
// be the same as it is on the main
// menu instead of shrinking down
// once switching into the game menu

const SettingsMenu = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  text-align: left;
  column-gap: 2rem;
  row-gap: 2rem;

  position: absolute;
  // 16px is width of our ::before arrow
  right: calc(-16px / 2);
  // 100% to position at bottom of cog
  // 16px is the height of our ::before arrow
  // 2px buffer so arrow not overlapping cog
  top: calc(100% + 16px + 2px);
  white-space: nowrap;

  z-index: 1;

  background: ${(props) => props.theme.colors.settings.background};
  padding: 2rem;
  color: ${(props) => props.theme.colors.settings.text};

  box-shadow: -4px 6px 4px rgba(0, 0, 0, 0.75);

  border-radius: 12px;

  &::before {
    content: "";
    border-bottom: 16px solid
      ${(props) => props.theme.colors.settings.background};
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    top: -16px;
    right: 8px;
  }

  button {
    border-radius: 1rem;
    border: none;
    padding: 0.5rem;
  }
`;

const GameCover = styled.div`
  /* opacity: 0.2; */
  /* background-color: red; */
  width: 200vw;
  height: 200vh;
  position: fixed;
  top: -100vh;
  left: -100vw;

  z-index: 1;
`;

type SettingsProps = {
  embedded?: boolean;
};

const Settings = observer(({ embedded }: SettingsProps) => {
  const { app } = useRootStore();
  const game = useGameStore();

  // Why does setting initial value to null cause this to be typed as
  // a RefObject instead of a MutableRefObject? Or rather why would I
  // want to work with either type of RefObject?
  const settingsObjRef = React.useRef<HTMLObjectElement>(null);
  const theme = useTheme();

  React.useEffect(() => {
    if (settingsObjRef.current) {
      let svgDocument;

      try {
        svgDocument = settingsObjRef.current.contentDocument;
      } catch (error) {
        console.error(error);
        return;
      }

      if (!svgDocument) {
        console.warn(
          "Failed to get SVG document when trying to recolor Settings cog."
        );
        return;
      }

      const paths = svgDocument.querySelectorAll("path");
      paths.forEach((path) => {
        path.style.fill = theme.colors.text;
      });
    }
  }, [theme, settingsObjRef.current]);

  return (
    <SettingsContainer embedded={embedded}>
      <ImageButton
        src={images["ic_settings_24px.svg"]}
        onClickCapture={(event) => {
          if (!app.isSettingsMenuOpen) {
            event.stopPropagation(); // Prevent interference with window listener
            app.toggleSettingsMenuOpen();
          }
        }}
        objRef={settingsObjRef}
        shouldUseObject={true}
      />
      {app.isSettingsMenuOpen ? (
        <>
          <GameCover
            onClick={(event) => {
              event.stopPropagation();
              app.toggleSettingsMenuOpen();
            }}
          />
          <SettingsMenu>
            <div>{app.text.settingsMenuMuteLabel}</div>
            <Toggle
              id="mute-toggle"
              onToggle={app.setMute}
              value={app.audio.isMuted}
            />
            {/* <div>Toggle Input</div>
          <Toggle
            id="input-toggle"
            onToggle={() => {
              switch (game.inputModel) {
                case "tapToMark":
                  game.setInputModel("tapToReveal");
                  break;
                default:
                case "tapToReveal":
                  game.setInputModel("tapToMark");
                  break;
              }
            }}

            // This is technically misleading for "click" inputMode, but that
            // should only be used in dev when hacked in anyway.
            value={game.inputModel === "tapToMark" ? false : true}
          /> */}
            {app.settings.gameplay.showHintsOptionInSettingsMenu ? (
              <>
                <div>{app.text.settingsMenuUseHintsLabel}</div>
                <Toggle
                  id="hint-toggle"
                  onToggle={game.toggleHintOnPrimaryAction}
                  value={game.shouldHintOnPrimaryAction}
                />
              </>
            ) : null}

            <button
              style={{ gridColumn: "span 2" }}
              onClick={() => app.modal.show("onboarding")}
            >
              {app.text.settingsMenuShowTutorialLabel}
            </button>
          </SettingsMenu>
        </>
      ) : null}
    </SettingsContainer>
  );
});

export default Settings;
