const _temp0 = require("./086354_8-bit-arcade-video-game-start-sound-effect-gun-reload-and-jump-81124.mp3");
const _temp1 = require("./beep3-98810.mp3");
const _temp2 = require("./doorbell-82187.mp3");
const _temp3 = require("./evil-demonic-laugh-6925.mp3");
const _temp4 = require("./item-39146.mp3");
const _temp5 = require("./power-up-sparkle-3-177985.mp3");
const _temp6 = require("./sound-effect-in-the-cave-b-175810.mp3");
const _temp7 = require("./tap-notification-180637.mp3");
const _temp8 = require("./water-dropping-102072.mp3");
const _temp9 = require("./winsquare-6993.mp3");
module.exports = {
  "086354_8-bit-arcade-video-game-start-sound-effect-gun-reload-and-jump-81124.mp3": _temp0,
  "beep3-98810.mp3": _temp1,
  "doorbell-82187.mp3": _temp2,
  "evil-demonic-laugh-6925.mp3": _temp3,
  "item-39146.mp3": _temp4,
  "power-up-sparkle-3-177985.mp3": _temp5,
  "sound-effect-in-the-cave-b-175810.mp3": _temp6,
  "tap-notification-180637.mp3": _temp7,
  "water-dropping-102072.mp3": _temp8,
  "winsquare-6993.mp3": _temp9
}