import { ITile } from "../models/Tile";

function isPoi(tile: ITile): boolean {
  switch (tile.type) {
    case "bomb":
    case "treasure":
      return true;
    default:
      return false;
  }
}

export default isPoi;
