import { ITile } from "../models/Tile";
import countIdentifyingClues from "./countIdentifyingClues";
import getLocation from "./getLocation";
import { Location, Matrix } from "./types";

function isTreasureSatisfied(
  tiles: Matrix<ITile>,
  location: Location,
): boolean {
  const tile = getLocation(tiles, location);

  if (tile.type !== "treasure") {
    return false;
  }

  const identifyingClueCount = countIdentifyingClues(tiles, location);

  switch (tile.satisfactionGoal) {
    case "2-clue":
      return identifyingClueCount >= 2;
    case "1-clue":
      return identifyingClueCount >= 1;
    default:
      return false;
  }
}

export default isTreasureSatisfied;
