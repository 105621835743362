import { ITile } from "../../models/Tile";
import calcNeighboringPoiCount from "../calcNeighboringPoiCount";
import getLocationsByType from "../getLocationsByType";
import pickRandomlyFromArray from "../random/pickRandomlyFromArray";
import { Location } from "../types";

function chooseStartLocation({
  startingArea,
  tiles,
}: {
  startingArea?: "clue" | "empty" | "zero" | "off";
  tiles: ITile[][];
}) {
  let startLocations: Location[] = [];

  switch (startingArea) {
    case "clue":
      startLocations = [
        ...getLocationsByType(tiles, "clue-arrow"),
        ...getLocationsByType(tiles, "clue-area"),
      ];
      break;
    case "empty":
      startLocations = getLocationsByType(tiles, "empty");
      break;
    case "zero":
      const emptyLocations = getLocationsByType(tiles, "empty");
      startLocations = emptyLocations.filter((location) => {
        return calcNeighboringPoiCount(tiles, location) === 0;
      });
      break;
    default:
      break;
  }

  console.log({ startingArea, startLocations });

  return pickRandomlyFromArray(startLocations);
}

export default chooseStartLocation;
