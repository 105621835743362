import { Howl } from "howler";

// TODO: This seems to function correctly as Parcel
// suggests it should.
//
// However, TypeScript has no concept that this glob
// import is being handled fine by Parcel.
// I guess Parcel is fixing this up for TypeScript
// in an earlier phase, and the actual build works
// fine.
// However, the version of TSC that's running in
// vscode isn't being updated by Parcel and is
// complaining.
//
// It would be nice to remove this error in a
// recommended way.
// @ts-ignore
import * as audio from "../../../assets/audio/*";

// Files originally sourced from Pixabay via AMU specifications in
// game confluence document:
// https://amuniversal.atlassian.net/wiki/spaces/PUZSOC/pages/3256942997/Caved+Minesweeper+Remix
const sounds = {
  pressPlay: new Howl({
    src: [
      audio[
        "086354_8-bit-arcade-video-game-start-sound-effect-gun-reload-and-jump-81124.mp3"
      ],
    ],
  }),
  gridReveal: undefined,
  gemReveal: new Howl({
    src: [audio["beep3-98810.mp3"]],
  }),
  pentultimateGemReveal: new Howl({
    src: [audio["power-up-sparkle-3-177985.mp3"]],
  }),
  arrowClueReveal: new Howl({
    src: [audio["doorbell-82187.mp3"]],
  }),
  skullReveal: new Howl({
    src: [audio["evil-demonic-laugh-6925.mp3"]],
  }),
  finalGemReveal: undefined,
  // This is duplicated with skullReveal, I think it's
  // a duplicate entry in the table with this information.
  gemReveal2: new Howl({
    src: [audio["evil-demonic-laugh-6925.mp3"]],
  }),
  areaClueReveal: new Howl({
    src: [audio["sound-effect-in-the-cave-b-175810.mp3"]],
  }),
  tileClick: new Howl({
    src: [audio["water-dropping-102072.mp3"]],
  }),
  victoryPopUp: new Howl({
    src: [audio["winsquare-6993.mp3"]],
  }),
  hintReveal: new Howl({
    src: [audio["tap-notification-180637.mp3"]],
  }),
  caveReveal: new Howl({
    src: [audio["item-39146.mp3"]],
  }),
};

export { sounds };
