import { ITile } from "../models/Tile";

function isClue(tile: ITile): boolean {
  switch (tile.type) {
    case "clue-area":
    case "clue-arrow":
      return true;
    default:
      return false;
  }
}

export default isClue;
