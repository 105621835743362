import React, { DetailedHTMLProps, ImgHTMLAttributes } from "react";

const NonSaveableImage = (
  props: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  return (
    <img
      {...props}
      style={{ ...props.style, pointerEvents: "none" }}
      onTouchStart={(event) => event.preventDefault()}
    />
  );
};

export default NonSaveableImage;
