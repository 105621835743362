import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import clamp from "../utils/clamp";

function createTimer(interval: number, callback: (time: number) => void) {
  let time = Date.now();

  const intervalId = setInterval(() => {
    time = Date.now();
    callback(time);
  }, interval);

  const stop = () => {
    clearInterval(intervalId);
  };

  return stop;
}

let stopTimer: () => void;

const TimerModel = types
  .model({
    elapsedTimeMs: types.optional(types.number, 0),
    lastTrackedTimeStamp: types.optional(types.number, 0),
    isRunning: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    trackTime(trackedTimeStamp: number) {
      self.elapsedTimeMs += trackedTimeStamp - self.lastTrackedTimeStamp;
      self.lastTrackedTimeStamp = trackedTimeStamp;
    },
    stopTimer() {
      if (!self.isRunning) {
        return;
      }

      self.isRunning = false;
      stopTimer?.();

      // Track any time remaining since
      // last tracked timestamp.
      self.elapsedTimeMs += Date.now() - self.lastTrackedTimeStamp;

      console.log("Stopping timer.", {
        elapsedTimeMs: self.elapsedTimeMs,
        lastTrackedTimeStamp: self.lastTrackedTimeStamp,
      });
    },
    getElapsedTimeSeconds() {
      return clamp(
        Math.floor(self.elapsedTimeMs / 1000),
        0,
        Number.MAX_SAFE_INTEGER,
      );
    },
    getElapsedTimeMilliSeconds() {
      return clamp(self.elapsedTimeMs, 0, Number.MAX_SAFE_INTEGER);
    },
    hydrate(snapshot: ITimerSnapshotIn) {
      self.elapsedTimeMs = snapshot.elapsedTimeMs ?? 0;
    },
  }))
  .actions((self) => ({
    startTimer() {
      if (self.isRunning) {
        return;
      }

      self.lastTrackedTimeStamp = Date.now();
      self.isRunning = true;

      console.log("Starting timer.", {
        elapsedTimeMs: self.elapsedTimeMs,
        lastTrackedTimeStamp: self.lastTrackedTimeStamp,
      });

      stopTimer = createTimer(500, (time) => {
        self.trackTime(time);
      });
    },
    reset() {
      self.stopTimer();
      self.elapsedTimeMs = 0;
    },
  }));

export interface ITimer extends Instance<typeof TimerModel> {}
export interface ITimerSnapshotIn extends SnapshotIn<typeof TimerModel> {}
export interface ITimerSnapshotOut extends SnapshotOut<typeof TimerModel> {}

export default TimerModel;
