import addLocations from "./addLocations";
import isLocationInBounds from "./isLocationInBounds";
import { Location, Matrix } from "./types";

function getLocationsInDirection<T>(
  origin: Location,
  direction: Location,
  matrix: Matrix<T>,
) {
  let foundLocations = [];
  let target = origin;

  while (isLocationInBounds(matrix, target)) {
    foundLocations.push(target);
    // Pick next target in direction
    target = addLocations(target, direction);
  }

  return foundLocations;
}

export default getLocationsInDirection;
