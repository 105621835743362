import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRootStore } from "../models/Root";
import images from "../utils/assets/images";

const Splash = observer(() => {
  const { app } = useRootStore();
  const isTransitioningOut = app.transitioningTo !== undefined;

  React.useEffect(() => {
    setTimeout(() => app.startTransition("mainMenu"), 1500);
  }, []);

  return (
    <img
      className={isTransitioningOut ? "fadeOut" : "fadeIn"}
      src={images["brand.png"]}
      onAnimationEnd={() => {
        app.finishTransition();
      }}
    />
  );
});

export default Splash;
