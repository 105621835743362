import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import createDataUriString from "../utils/files/createDataUriString";
import { MstConfigSchema } from "../utils/amu/parseConfigFile";

const ConfigFileModel = types
  .model({
    config: MstConfigSchema,
  })
  .views((self) => ({
    toJsonString() {
      return JSON.stringify({}, undefined, 2);
    },
  }))
  .views((self) => ({
    toDataUriString() {
      return createDataUriString(self.toJsonString());
    },
  }));

export interface IConfigFile extends Instance<typeof ConfigFileModel> {}
export interface IConfigFileSnapshotIn
  extends SnapshotIn<typeof ConfigFileModel> {}
export interface IConfigFileSnapshotOut
  extends SnapshotOut<typeof ConfigFileModel> {}
export default ConfigFileModel;
