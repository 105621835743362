import rkSum from "./rkSum";
import rkMedian from "./rkMedian";
import rkSort from "./rkSort";

declare global {
  interface Array<T> {
    rkSum: typeof rkSum;
    rkMedian: typeof rkMedian;
    rkSort: typeof rkSort;
  }
}

function attachRkPrototypes() {
  Array.prototype.rkSum = rkSum;
  Array.prototype.rkMedian = rkMedian;
  Array.prototype.rkSort = rkSort;
}

export default attachRkPrototypes;
