type OrdinalSuffixes = {
  one: string;
  two: string;
  few: string;
  many: string;
  zero: string;
  other: string;
};

function appendOrdinalSuffix(number: number): string {
  const [language] = navigator.language.split("-");

  const ordinalSuffixMap: { [key: string]: OrdinalSuffixes } = {
    en: {
      one: "st",
      two: "nd",
      few: "rd",
      many: "th",
      zero: "th",
      other: "th",
    },
  };

  if (!ordinalSuffixMap[language]) {
    // We do not have a supporting ordinal map for
    // this language.
    return String(number);
  }

  const pluralRules = new Intl.PluralRules("default", { type: "ordinal" });
  const ordinalRule = pluralRules.select(number);

  return `${number}${ordinalSuffixMap[language][ordinalRule]}`;
}

export default appendOrdinalSuffix;
