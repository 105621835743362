import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: ${(props) => props.theme.fonts.baseStack};

    margin: 0;
    background-color: ${(props) => props.theme.colors.background};
    font-size: ${(props) => props.theme.fonts.baseSizePercentage}%;

    user-select: none;

    overflow: hidden;
  }

  button {
    cursor: pointer;
  }
 
  .slideInUp {
    animation-name: slideInUp;
    animation-duration: 350ms;
  }

  @keyframes slideInUp {
    from {  
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }



  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeOut {
    animation-name: fadeOut;
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes tada {
    from {
      transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes pulse {
    from {
      transform: scale3d(1, 1, 1);
    }

    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes flipToBack {
    from {
      transform: rotateY(0deg);
    }

    to {
      transform: rotateY(180deg);
    }
  }

  @keyframes flipToFront {
    from {
      transform: rotateY(180deg);
    }

    to {
      transform: rotateY(0deg);
    }
  }

  @keyframes flash {
    from,
    50%,
    to {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
  }
`;

export default GlobalStyle;
