import styled from "styled-components";

const ShrinkableFlexImage = styled.img`
  flex-shrink: 1;
  min-width: 0;
  padding: 0.35rem;

  max-height: 100%;
`;

export default ShrinkableFlexImage;
