import {
  IStateTreeNode,
  Instance,
  SnapshotIn,
  SnapshotOut,
  getSnapshot,
  types,
} from "mobx-state-tree";
import messageParent from "./messageParent";
import {
  AmuState,
  EventGameMessageType,
  UserSettingsSnapshotDetails,
  IAmuState,
} from "./AmuMessage";
import { formatISO, isValid, parseISO } from "date-fns";

export const WindowLoadedGameMessage = types.model("WindowLoadedGameMessage", {
  windowLoaded: types.literal(true),
});

export const GameStateMessage = types.model("GameStateMessage", {
  state: AmuState,
});

const EventGameMessageDetails = types.union(
  types.string,
  UserSettingsSnapshotDetails,
);

const Iso8601DateTime = types.custom<string, Date>({
  name: "Iso8601DateTime",
  // Given a serialized value and environment
  // how to turn it into the target type
  fromSnapshot(value: string): Date {
    return new Date(value);
  },
  // Return the serialization of the current value
  toSnapshot(value: Date): string {
    return new Date().toISOString();
  },
  // If true, this is a converted value, if false, it's a snapshot
  isTargetType(value: string | Date): value is Date {
    return value instanceof Date;
  },
  // A non empty string is assumed to be a validation error
  getValidationMessage(snapshot: string): string {
    // Is this a valid date snapshot?
    const parsed = parseISO(snapshot);
    if (!isValid(parsed)) {
      return `Cannot parse invalid ISO8601 date: "${snapshot}"`;
    }

    return "";
  },
});

export const EventGameMessage = types.model("EventGameMessage", {
  type: EventGameMessageType,
  timestamp: Iso8601DateTime,
  details: types.maybe(EventGameMessageDetails),
});

export interface IEventGameMessage extends Instance<typeof EventGameMessage> {}

// It looks like there's some incompatibility with
// interfaces and mobx-state-tree's union type?
// const GameMessage = types.union(EventGameMessage, WindowLoadedGameMessage);

// export interface IGameMessage extends Instance<typeof GameMessage> {}
// export interface IGameMessageSnapshotIn
//   extends SnapshotIn<typeof GameMessage> {}
// export interface IGameMessageSnapshotOut
//   extends SnapshotOut<typeof GameMessage> {}

// I'm just going to use any/IStateTreeNode instead.

function messageAmu(message: IStateTreeNode) {
  const amuMessage = {
    amuGame: getSnapshot(message),
  };

  messageParent(amuMessage);
}

function messageAmuAsEvent(message: IEventGameMessage, state: IAmuState) {
  const amuMessage = {
    amuGame: {
      event: getSnapshot(message),
      state: {
        ...getSnapshot(state),
        // Required to be emitted by AMU, we don't use the value
        // adding it in here so it's on every state emission.
        clearedBoard: false,
      },
    },
  };

  messageParent(amuMessage);
}

export default messageAmu;
export { messageAmuAsEvent };
