import * as React from "react";
import styled from "styled-components";

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  &:hover,
  &:focus {
    /* background: #0053ba; */
  }

  &:focus {
    /* outline: 1px solid #fff;
    outline-offset: -4px; */
  }

  &:active {
    /* transform: scale(0.99); */
  }

  /**
   * Object element will swallow click events and not
   * allow them to pass through to the button by
   * default.
   */
  object {
    pointer-events: none;
  }
`;

// TODO:
// I cannot figure out why Button will not
// allow me to spread props over it to support
// a full passthrough of button props.
// Instead I am just going to manually pass through
// each prop I would want.
const ImageButton = ({
  src,
  onClick,
  onClickCapture,
  className,
  shouldUseObject = false,
  imgRef,
  objRef,
}: {
  src: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClickCapture?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  // TODO: This seems like a naive way to make this switch
  // Maybe just separate ImageButton and ObjectButton?
  shouldUseObject?: boolean;
  // Needing to make two optional refs, one for each inner element
  // type feels like a typing failure...
  // I suspect there is some way to accept a ref of EITHER type
  // and then, if it matches, reduce the scope of the type
  // and apply it to the appropriate HTML element.
  imgRef?: React.RefObject<HTMLImageElement>;
  objRef?: React.RefObject<HTMLObjectElement>;
}) => {
  return (
    <Button
      onClick={onClick}
      onClickCapture={onClickCapture}
      className={className}
    >
      {shouldUseObject ? (
        <object data={src} ref={objRef} />
      ) : (
        <img src={src} ref={imgRef} />
      )}
    </Button>
  );
};

export default ImageButton;
