import styled from "styled-components";
// TODO:
// This bar should probably
// affect the amount of space the game
// can be scaled into.
// The bar can overlap the gamespace on some

// resolutions right now.
const ReviewBar = styled.div`
  width: 100%;
  padding-bottom: 1rem;

  display: flex;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  font-family: ${(props) => props.theme.fonts.baseStack};

  & > button {
    border: none;
    background: none;

    color: ${(props) => props.theme.colors.reviewBar.background};
    text-transform: uppercase;
    font-size: 1.25rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-left: 1rem;
    }

    margin-right: 1rem;
  }

  /* button:last-child {
    flex: 1;
    justify-content: right;
  } */
`;

export default ReviewBar;
