import { getSnapshot } from "mobx-state-tree";
import {
  ISettings,
  ISettingsSnapshotIn,
  defaultSettings,
} from "../../models/Settings";
import getQueryParams from "./getQueryParams";
import deepClone from "../deepClone";

function parseQueryParams() {
  const queryParams = getQueryParams();
  // Need to deepClone the snapshot to modify it.
  // Mobx-state-tree snapshots are immutable
  const settings: ISettingsSnapshotIn = deepClone(getSnapshot(defaultSettings));

  const treasureReveal = queryParams.get("treasureReveal");
  switch (treasureReveal) {
    case "on":
      settings.gameplay.treasureReveal = true;
      break;
    case "off":
      settings.gameplay.treasureReveal = false;
      break;
    default:
      break;
  }

  const bombPenalty = queryParams.get("bombPenalty");
  if (bombPenalty) {
    try {
      settings.gameplay.bombPenalty = parseInt(bombPenalty);
    } catch (err) {
      // This was not a valid int, use the default value.
    }
  }

  const scoring = queryParams.get("scoring");
  switch (scoring) {
    case "time":
    case "clicks":
      settings.gameplay.scoring = scoring;
      break;
    default:
      break;
  }

  const seed = queryParams.get("seed");
  if (seed) {
    settings.generation.seed = seed;
  }

  const bombCount = queryParams.get("bombCount");
  if (bombCount) {
    try {
      settings.generation.bombCount = parseInt(bombCount);
    } catch (err) {
      // This was not a valid int, use the default value.
    }
  }

  const treasureCount = queryParams.get("treasureCount");
  if (treasureCount) {
    try {
      settings.generation.treasureCount = parseInt(treasureCount);
    } catch (err) {
      // This was not a valid int, use the default value.
    }
  }

  const width = queryParams.get("width");
  if (width) {
    try {
      settings.generation.width = parseInt(width);
    } catch (err) {
      // This was not a valid int, use the default value.
    }
  }

  const height = queryParams.get("height");
  if (height) {
    try {
      settings.generation.height = parseInt(height);
    } catch (err) {
      // This was not a valid int, use the default value.
    }
  }

  const startingArea = queryParams.get("startingArea");
  switch (startingArea) {
    case "clue":
    case "empty":
    case "off":
    case "zero":
      settings.generation.startingArea = startingArea;
      break;
    default:
      break;
  }

  const revealAll = queryParams.get("revealAll");
  switch (revealAll) {
    case "on":
      settings.gameplay.revealAll = true;
      break;
    case "off":
      settings.gameplay.revealAll = false;
      break;
    default:
      break;
  }

  const shouldGen = queryParams.get("shouldGen");
  switch (shouldGen) {
    case "on":
      settings.generation.shouldGen = true;
      break;
    case "off":
      settings.generation.shouldGen = false;
      break;
    default:
      break;
  }

  const dev = queryParams.get("dev");
  switch (dev) {
    case "on":
      settings.gameplay.isDev = true;
      break;
    case "off":
      settings.gameplay.isDev = false;
      break;
    default:
      break;
  }

  const fakeAmu = queryParams.get("fakeAmu");
  switch (fakeAmu) {
    case "on":
      settings.gameplay.fakeAmu = true;
      break;
    case "off":
      settings.gameplay.fakeAmu = false;
      break;
    default:
      break;
  }

  const areaClueWeight = queryParams.get("areaClueWeight");
  if (areaClueWeight) {
    try {
      settings.generation.areaClueWeight = parseInt(areaClueWeight);
    } catch (err) {
      // This was not a valid int, use the default value.
    }
  }

  const showPause = queryParams.get("showPause");
  switch (showPause) {
    case "on":
      settings.gameplay.showPause = true;
      break;
    case "off":
      settings.gameplay.showPause = false;
      break;
    default:
      break;
  }

  return settings;
}

export default parseQueryParams;
