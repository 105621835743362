import styled from "styled-components";

const BgRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
  width: 100%;
`;

export default BgRow;
