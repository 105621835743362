import * as React from "react";
import { observer } from "mobx-react-lite";
import packageInfo from "../../package.json";
import { useGameStore } from "../models/Root";
import { IGrid } from "../models/Grid";
import getLocationsByType from "../utils/getLocationsByType";
import styled from "styled-components";
import getLocation from "../utils/getLocation";
import useSettings from "../hooks/useSettings";
import { getSeed } from "../utils/random/rng";
import calculateScore from "../utils/game/calculateScore";
import getRevealedBombs from "../utils/game/getRevealedBombs";
import getPlacedFlags from "../utils/game/getPlacedFlags";
import { getSnapshot } from "mobx-state-tree";
import images from "../utils/assets/images";
import Settings from "./Settings";
import LevelFileModel from "../models/LevelFile";

type TreasureProps = {
  isRevealed: boolean | undefined;
};

const Treasure = styled.div<TreasureProps>`
  font-size: 2rem;

  ${(props) =>
    props.isRevealed
      ? ""
      : `
          filter: grayscale(0.75);
          opacity: 0.6;
        `}

  animation-name: ${(props) => (props.isRevealed ? "tada" : "")};
  animation-timing-function: ease-in-out;
  animation-duration: 200ms;
  animation-delay: 500ms;
`;

type RowColProps = {
  orientation: string;
};

const RowCol = styled.div<RowColProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  flex-direction: ${(props) =>
    props.orientation === "vertical" ? "row" : "column"};

  & > * {
    width: ${(props) => (props.orientation === "vertical" ? "" : "100%")};
  }

  & > *:not(:last-child) {
    margin-bottom: ${(props) =>
      props.orientation === "vertical" ? "" : "0.5rem"};
    margin-right: ${(props) =>
      props.orientation === "vertical" ? "0.5rem" : ""};
  }
`;

type StatusBarRowProps = {
  orientation: string;
};

const StatusBarRow = styled.div<StatusBarRowProps>`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: ${(props) =>
    props.orientation === "vertical" ? "2fr 1fr 1fr 1fr 0.5fr" : "1fr 1fr"};
  grid-template-rows: ${(props) =>
    props.orientation === "vertical" ? "1fr" : "1.5fr 1fr 1fr"};

  margin-bottom: ${(props) => (props.orientation === "vertical" ? "1rem" : "")};
  margin-right: ${(props) => (props.orientation === "vertical" ? "" : "1rem")};
`;

type RowSectionProps = {
  primary?: boolean;
  wide?: boolean;
  orientation?: string;
};

const RowSection = styled.div<RowSectionProps>`
  background-color: ${(props) => props.theme.colors.tile.revealed};
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  grid-column: ${(props) =>
    props.wide && props.orientation === "horizontal" ? "span 2" : ""};

  height: 100%;

  p {
    font-size: ${(props) => (props.primary ? "2.5rem" : "inherit")};
    font-weight: ${(props) => (props.primary ? "bold" : "inherit")};
    color: ${(props) =>
      props.primary
        ? props.theme.colors.text
        : props.theme.colors.textSecondary};
    font-family: ${(props) =>
      props.primary ? props.theme.fonts.boldStack : "inherit"};
    margin: 0;
  }
`;

const DevContainer = styled.div`
  margin-right: 1rem;

  .seed {
    user-select: text;
    color: ${(props) => props.theme.colors.text};
  }
`;

const Dev = observer(() => {
  const game = useGameStore();

  return (
    <DevContainer>
      <RowCol orientation={game.orientation}>
        <RowSection>
          <p>Seed:</p>
          <p className="seed">{getSeed()}</p>
        </RowSection>
        <RowSection>
          <button
            onClick={() => {
              const gameSnapshot = game.getLevelFileSnapshot();

              const levelFile = LevelFileModel.create({
                appVersion: packageInfo.version,
                startLocation: gameSnapshot.startLocation,
                tiles: gameSnapshot.tiles,
              });

              const dataUri = levelFile.toDataUriString();
              var downloadAnchorNode = document.createElement("a");
              downloadAnchorNode.setAttribute("href", dataUri);
              downloadAnchorNode.setAttribute("download", "level.json");
              document.body.appendChild(downloadAnchorNode);
              downloadAnchorNode.click();
              downloadAnchorNode.remove();
            }}
          >
            Export Level JSON
          </button>
        </RowSection>
      </RowCol>
    </DevContainer>
  );
});

function calcPlacedFlagCount(grid: IGrid) {
  return getPlacedFlags(grid).length;
}

const Score = observer(() => {
  const game = useGameStore();
  const [settings, isInitialized] = useSettings();

  const score = calculateScore({
    game,
    settings,
    isInitialized,
  });

  return (
    <RowSection primary wide orientation={game.orientation}>
      <p>{score}</p>
    </RowSection>
  );
});

const Treasures = observer(() => {
  const game = useGameStore();
  const treasureLocations = getLocationsByType(game.grid.tiles, "treasure");
  const treasures = treasureLocations.map((location) =>
    getLocation(game.grid.tiles, location)
  );
  const revealedCount = treasures.filter(
    (treasure) => treasure.isRevealed
  ).length;

  return (
    <RowSection wide orientation={game.orientation}>
      <img src={images["crystal.svg"]} />
      <p>
        {revealedCount}/{treasureLocations.length}
      </p>
    </RowSection>
  );
});

const Skulls = observer(() => {
  const game = useGameStore();
  const totalBombCount = getLocationsByType(game.grid.tiles, "bomb").length;

  return (
    <RowSection orientation={game.orientation}>
      <img src={images["skull.svg"]} />
      <p>{totalBombCount}</p>
    </RowSection>
  );
});

const Flags = observer(() => {
  const game = useGameStore();
  const placedFlagCount = calcPlacedFlagCount(game.grid);

  return (
    <RowSection orientation={game.orientation}>
      <img src={images["flag-fill.svg"]} />
      <p>{placedFlagCount}</p>
    </RowSection>
  );
});

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusBar = observer(() => {
  const game = useGameStore();
  const { orientation } = game;
  const [settings, isInitialized] = useSettings();

  return (
    <>
      {settings.gameplay.isDev ? <Dev /> : null}
      <StatusBarRow orientation={orientation}>
        <Score />
        <Treasures />
        <Skulls />
        <Flags />
        {orientation === "vertical" ? (
          <SettingsContainer>
            <Settings embedded />
          </SettingsContainer>
        ) : null}
      </StatusBarRow>
    </>
  );
});

export default StatusBar;
