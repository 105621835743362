import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { LocationModel, TilesModel } from "./Grid";
import mapMatrix from "../utils/mapMatrix";
import { filterTileForExport } from "./Tile";
import createDataUriString from "../utils/files/createDataUriString";

const LevelFileModel = types
  .model({
    appVersion: types.string,
    startLocation: types.maybe(LocationModel),
    tiles: TilesModel,
  })
  .views((self) => ({
    toJsonString() {
      const tilesFiltered = mapMatrix(filterTileForExport, self.tiles);

      return JSON.stringify(
        {
          appVersion: self.appVersion,
          startLocation: self.startLocation,
          tiles: tilesFiltered,
        },
        undefined,
        2,
      );
    },
  }))
  .views((self) => ({
    toDataUriString() {
      return createDataUriString(self.toJsonString());
    },
  }));

export interface ILevelFile extends Instance<typeof LevelFileModel> {}
export interface ILevelFileSnapshotIn
  extends SnapshotIn<typeof LevelFileModel> {}
export interface ILevelFileSnapshotOut
  extends SnapshotOut<typeof LevelFileModel> {}
export default LevelFileModel;
