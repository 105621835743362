import fillMatrix from "./fillMatrix";
import mapMatrix from "./mapMatrix";
import { Dimensions, Location } from "./types";

function constructMatrix<T>(
  constructor: (location: Location) => T,
  dimensions: Dimensions,
) {
  const matrix = fillMatrix("", dimensions);

  return mapMatrix((value, location) => constructor(location), matrix);
}

export default constructMatrix;
