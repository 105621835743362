import { createRoot } from "react-dom/client";
import * as React from "react";
import App from "./components/App";
import attachRkPrototypes from "./utils/rkPrototypes/attachRkPrototypes";
import { preloadImages } from "./utils/assets/images";
import attachCavedLogger from "./utils/logger/attachCavedLogger";
import loadFonts from "./utils/assets/fonts/fonts";

attachRkPrototypes();
attachCavedLogger();

// TODO:
// We should really wait on this
// in the App loading state, but
// hopefully this preloads the
// images well enough!
preloadImages();

loadFonts();

// @ts-ignore (process is not defined)
if (process.env.NODE_ENV !== "production") {
  // Only runs in development and will be stripped in production builds.
  //
  // Conditionally append a meta tag that disables viewport
  // scaling in development to aid with using chrome dev tool
  // responsive styling more easily.
  const metaTag = document.createElement("meta");
  metaTag.name = "viewport";
  metaTag.content =
    "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
  document.head.appendChild(metaTag);
}

const container = document.getElementById("root");
const root = createRoot(container!); // ! is to assert the container will not be null
root.render(<App />);
