import * as React from "react";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../models/Root";
import StatsModal from "./StatsModal";
import ShareModal from "./ShareModal";
import OnboardingModal from "./OnboardingModal";
import ResetModal from "./ResetModal";
import PauseModal from "./PauseModal";

const ModalStateRouter = observer(() => {
  const root = useRootStore();

  switch (root.app.modal.state) {
    case "onboarding":
      return <OnboardingModal />;
    case "stats":
      return <StatsModal />;
    case "share":
      return <ShareModal />;
    case "reset":
      return <ResetModal />;
    case "pause":
      return <PauseModal />;
    case "none":
    default:
      return null;
  }
});

export default ModalStateRouter;
