import pickRandomlyFromArray from "./pickRandomlyFromArray";

/**
 * Removed "O" and "I" to help prevent visual confusion.
 */
const characters = "ABCDEFGHJKLMNPQRSTUVWXYZ0123456789".split("");

function createRandomString(length: number) {
  let randomString = "";

  for (let i = 0; i < length; i += 1) {
    randomString += pickRandomlyFromArray(characters);
  }

  return randomString;
}

export default createRandomString;
