import { ITile } from "../models/Tile";
import addLocations from "./addLocations";
import getLocation from "./getLocation";
import isLocationInBounds from "./isLocationInBounds";
import mapMatrix from "./mapMatrix";
import { Location, Matrix } from "./types";

function getAreaClueLocations(
  tiles: Matrix<ITile>,
  clueLocation: Location,
): Location[] {
  const clue = getLocation(tiles, clueLocation);

  if (clue.type !== "clue-area") {
    return [];
  }

  if (!clue.areaOffsetLocations) {
    return [];
  }

  const allLocationsInClue = clue.areaOffsetLocations.map((offset) =>
    addLocations(clueLocation, offset),
  );
  const inBoundsLocations = allLocationsInClue.filter((location) =>
    isLocationInBounds(tiles, location),
  );

  return [...inBoundsLocations, clueLocation];
}

export default getAreaClueLocations;
