import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import { useRootStore } from "../../models/Root";
import Modal from "./Modal";
import ModalBackground from "./ModalBackground";

const Container = styled.div`
  width: fit-content;
`;

const ButtonContainer = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;

  padding: 1rem;

  button {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    border-radius: 999em;
    border: 2px solid ${(props) => props.theme.colors.modal.text};

    background: transparent;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;

    img {
      margin-left: 1rem;
    }
  }

  button.primary {
    background-color: ${(props) => props.theme.colors.modal.text};
    color: ${(props) => props.theme.colors.modal.background};
  }
`;

const ModalBody = styled.div`
  position: relative;
  padding: 2rem 2rem;

  overflow: hidden;

  z-index: 1;
`;

const ResetModal = observer(() => {
  const { app } = useRootStore();

  return (
    <Modal>
      <Container>
        <ModalBackground className="slideInUp">
          <ModalBody>
            <h2>{app.text.resetModalTitle}</h2>
            <p>{app.text.resetModalBody}</p>
            <ButtonContainer>
              <button
                className="primary"
                onClick={() => {
                  app.modal.hide();
                  app.replay();
                }}
              >
                {app.text.resetModalReplayButton}
              </button>
              <button onClick={() => app.modal.hide()}>
                {app.text.resetModalBackButton}
              </button>
            </ButtonContainer>
          </ModalBody>
        </ModalBackground>
      </Container>
    </Modal>
  );
});

export default ResetModal;
