import { IGrid } from "../../models/Grid";
import getLocation from "../getLocation";
import getLocationsByType from "../getLocationsByType";

function getRevealedBombs(grid: IGrid) {
  const bombLocations = getLocationsByType(grid.tiles, "bomb");
  const revealedBombs = bombLocations
    .map((location) => getLocation(grid.tiles, location))
    .filter((bomb) => bomb.isRevealed);

  return revealedBombs;
}

export default getRevealedBombs;
