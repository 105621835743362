import { useRootStore } from "../models/Root";
import { ISettings } from "../models/Settings";

type SettingsTuple = [ISettings, boolean];

const useSettings = (): SettingsTuple => {
  const { app } = useRootStore();

  return [app.settings, app.state !== "loading"];
};

export default useSettings;
