import { Location } from "../types";

function rotateLocationRelativeToDimensions<T>(
  location: Location,
  dimensions: { width: number; height: number },
  // direction?: "clockwise" | "counter-clockwise" = "clockwise"
): Location {
  const { width, height } = dimensions;

  // TODO:
  // Support counter-clockwise rotation as well
  // We ignore it right now.
  // if (direction === "clockwise") {
  const finalCol = width - 1;
  const finalRow = height - 1;

  return {
    row: location.col,
    col: finalRow - location.row,
  };
}

export default rotateLocationRelativeToDimensions;

/**
. . . .
. . . .
. x . .
(2, 1) (w4, h3)

. . .
x . .
. . .
. . .
(1, 0) (w3, h4)

. . x .
. . . .
. . . .
(0, 2) (w4, h3)

. . .
. . .
. . x
. . .
(2, 2) (w3, h4)


---

. . . .
. . x .
. . . .
(1, 2) (w4, h3)

. . .
. . .
. x .
. . .
(2, 1) (w3, h4)

. . . .
. x . .
. . . .
(1, 1) (w3, h4)

 */

/*

(7,8) (w10, h15) -> (8, 7)
(8,7) -> (7, 6)
*/
