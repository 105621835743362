import {
  addMiddleware,
  getPath,
  getType,
  Instance,
  isStateTreeNode,
  onSnapshot,
  SnapshotIn,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import { createContext, useContext } from "react";
import { generateInitialTiles2 } from "../utils/generateInitialTiles";
import AppModel from "./App";
import GameModel, { IGame } from "./Game";
import { defaultSettings } from "./Settings";

export const RootModel = types.model({
  game: GameModel,
  app: AppModel,
});

export function isRoot(node: object): node is IRoot {
  if (!isStateTreeNode(node)) {
    return false;
  }

  const type = getType(node);

  return type === RootModel;
}

export interface IRoot extends Instance<typeof RootModel> {}
export interface IRootSnapshotIn extends SnapshotIn<typeof RootModel> {}
export interface IRootSnapshotOut extends SnapshotOut<typeof RootModel> {}

/**
 * TODO:
 * This is basically all _game_ state
 * We want the ability to instantiate new
 * game state's whenever we want.
 * This could be to change a seed for testing,
 * or load a previous day's puzzle.
 *
 * I think we'll want to do something like
 * moving this into an exported function.
 *
 * And create a Game component that creates a new RootModel
 * on mount.
 *
 * Then current RootModel becomes GameModel.
 *
 * Then a parent App component handles initial loading
 * and menus and such.
 *
 * Update: 2/28/23
 * I'm not sure how relevant this is or not any more...
 * Even this initial tile layout is not being used in game.
 * We're overwriting it when we load the App in a useEffect.
 */
const rootStore = RootModel.create({
  app: {
    modal: {},
    onboarding: {},
    loading: { levelFile: {}, configFile: {} },
    events: {},
    settings: defaultSettings,
    themeKey: "defaultTheme",
    text: {},
    html: {},
    audio: {},
  },
  game: {
    grid: {
      tiles: generateInitialTiles2(),
    },
    timer: {},
    actions: {},
  },
});

// Debug log info on all actions called
// const disposer = addMiddleware(rootStore, (call, next, abort) => {
//   console.log(`action ${getPath(call.context)} ${call.name} was invoked`, {
//     args: call.args,
//   });
//   // runs the next middleware
//   // or the implementation of the targeted action
//   // if there is no middleware left to run

//   // the value returned from the next can be manipulated
//   next(call, (value) => value + 1);
// });

// Debug on state snapshots
// onSnapshot(rootStore, (snapshot: IRootSnapshotIn) => {
//   console.log("Snapshot: ", snapshot);

//   // localStorage.setItem("rootState", JSON.stringify(snapshot));
// });

const RootStoreContext = createContext<IRoot>(rootStore);
const RootContextProvider = RootStoreContext.Provider;

function useRootStore(): IRoot {
  const store = useContext(RootStoreContext);

  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }

  return store;
}

const CurrentGameContext = createContext<IGame | null>(null);
const CurrentGameProvider = CurrentGameContext.Provider;

function useGameStore(): IGame {
  const root = useRootStore();
  const store = useContext(CurrentGameContext);

  /**
   * Default to the root game if we're used in a
   * context without our own game store.
   *
   * This happens in other non-game parts of the
   * app like the Stats modal.
   */
  if (store === null) {
    return root.game;
  }

  return store;
}

export {
  rootStore,
  RootContextProvider,
  useRootStore,
  useGameStore,
  CurrentGameContext,
  CurrentGameProvider,
};
