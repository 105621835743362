import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
  cast,
} from "mobx-state-tree";
import { DIRECTIONS } from "../utils/directions";
import { Location } from "../utils/types";

/**
 * We have removed the "safe" marking from this list
 * as it no longer seems useful in the design.
 */
type IMarking = "none" | "flagged";
export const TILE_MARKINGS: Array<IMarking> = ["none", "flagged"];

// This Tile Type is not used, but is an example of how to use
// unions and intersections to make types more usefully than
// I did in the TileModel below.
type Tile = (
  | { type: "arrow"; arrowDirection: string }
  | { type: "area"; radius: number }
) & { isRevealed: boolean };

export function filterTileForExport({
  type,
  arrowDirection,
  areaOffsetLocations,
}: ITileSnapshotOut) {
  return {
    type,
    arrowDirection,
    areaOffsetLocations,
  };
}

const TileModel = types
  .model({
    type: types.enumeration([
      "empty",
      "bomb",
      "treasure",
      "clue-arrow",
      "clue-area",
    ]),
    arrowDirection: types.maybe(types.enumeration(Object.values(DIRECTIONS))),
    areaOffsetLocations: types.maybe(
      types.array(types.model({ row: types.number, col: types.number })),
    ),
    // TODO:
    // A tile is not "revealed"
    // A tile is "notRevealed, revealing, revealed"
    // revealedState: types.optional(
    //   types.enumeration(["notRevealed", "revealing", "revealed"]),
    //   "notRevealed"
    // ),
    isRevealed: types.optional(types.boolean, false),
    marking: types.optional(types.enumeration(TILE_MARKINGS), "none"),
    satisfactionGoal: types.maybe(types.enumeration(["2-clue", "1-clue"])),
    isHinted: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isFlagged() {
      return self.marking === "flagged";
    },
  }))
  .actions((self) => ({
    reveal() {
      self.marking = "none";
      self.isRevealed = true;
      self.isHinted = false;
    },
    setMarking(marking: IMarking) {
      self.marking = marking;
    },
    setHinted() {
      self.isHinted = true;
    },
    /**
     * We need actions that can modify all tile properties to use
     * this model during level generation.
     */
    update(newValues: ITileSnapshotIn) {
      if (newValues.type !== undefined) {
        self.type = newValues.type;
      }

      if (newValues.arrowDirection !== undefined) {
        self.arrowDirection = newValues.arrowDirection;
      }

      if (newValues.satisfactionGoal !== undefined) {
        self.satisfactionGoal = newValues.satisfactionGoal;
      }

      if (newValues.areaOffsetLocations !== undefined) {
        self.areaOffsetLocations = cast(newValues.areaOffsetLocations);
      }
    },
  }));

export interface ITile extends Instance<typeof TileModel> {}
export interface ITileSnapshotIn extends SnapshotIn<typeof TileModel> {}
export interface ITileSnapshotOut extends SnapshotOut<typeof TileModel> {}
export default TileModel;
