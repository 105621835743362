import getParentOrigin from "./getParentOrigin";

const filters = [
  (event: any) => event?.data?.source?.includes("react-devtools"),
  // Not sure what is triggering this message event in my
  // development environment
  (event: any) => event?.data?.message === "reload-tab-complete",
  // In local dev, we'll post messages that should go to a
  // parent to ourselves. We want to filter them out.
  (event: any) => Boolean(event?.data?.amuGame),
];

function attachMessageListener(onMessage: (data: any) => void) {
  window.addEventListener("message", (event) => {
    if (filters.some((filter) => filter(event))) {
      // Ignoring messages we've explicitly filtered out
      return;
    }

    const parentOrigin = getParentOrigin();

    if (event.origin !== parentOrigin) {
      console.warn(
        `Ignoring event from: "${event.origin}". Doesn't match parent origin: "${parentOrigin}".`,
      );
      return;
    }

    onMessage(event.data);
  });
}

export default attachMessageListener;
