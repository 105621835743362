import { Location } from "./types";

export const DIRECTION_LOCATIONS: { [key: string]: Location } = {
  UP: { row: -1, col: 0 },
  DOWN: { row: 1, col: 0 },
  LEFT: { row: 0, col: -1 },
  RIGHT: { row: 0, col: 1 },
};

export const DIRECTIONS = {
  UP: "UP",
  DOWN: "DOWN",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};

// TODO:
// This hides errors where any non-direction string
// will default to UP.
// We should probably not be using "string" for typing
// here and be using some sort of enum or union type
// in the rest of the code base?
export const getDirectionLocation = (direction: string) => {
  switch (direction) {
    default:
    case DIRECTIONS.UP:
      return DIRECTION_LOCATIONS.UP;
    case DIRECTIONS.DOWN:
      return DIRECTION_LOCATIONS.DOWN;
    case DIRECTIONS.LEFT:
      return DIRECTION_LOCATIONS.LEFT;
    case DIRECTIONS.RIGHT:
      return DIRECTION_LOCATIONS.RIGHT;
  }
};

export const rotateDirection = (
  direction: string,
  rotation: "clockwise" | "counter-clockwise",
) => {
  switch (rotation) {
    case "clockwise":
      switch (direction) {
        default:
        case DIRECTIONS.UP:
          return DIRECTIONS.RIGHT;
        case DIRECTIONS.DOWN:
          return DIRECTIONS.LEFT;
        case DIRECTIONS.LEFT:
          return DIRECTIONS.UP;
        case DIRECTIONS.RIGHT:
          return DIRECTIONS.DOWN;
      }
    case "counter-clockwise":
      switch (direction) {
        default:
        case DIRECTIONS.UP:
          return DIRECTIONS.LEFT;
        case DIRECTIONS.DOWN:
          return DIRECTIONS.RIGHT;
        case DIRECTIONS.LEFT:
          return DIRECTIONS.DOWN;
        case DIRECTIONS.RIGHT:
          return DIRECTIONS.UP;
      }
  }
};
