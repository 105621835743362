import React, { RefObject } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { useGameStore } from "../../models/Root";
import useScaleRef from "../../hooks/useScaleRef";
import calculateGameOrientation from "../../utils/breakpoints/calculateGameOrientation";

const ScaleParent = styled.div`
  position: relative;
`;

type GridContainerProps = {
  orientation: string;
};

const GridContainer = styled.div<GridContainerProps>`
  width: fit-content;
  transform-origin: top left;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: ${(props) =>
    props.orientation === "vertical" ? "column" : "row"};
`;

type GameScaleContainerProps = {
  shouldScale: boolean;
  scaleRegionRef?: RefObject<HTMLElement>;
} & React.PropsWithChildren;

const GameScaleContainer = observer(
  ({ children, shouldScale, scaleRegionRef }: GameScaleContainerProps) => {
    const { orientation, setOrientation } = useGameStore();

    const [scaleRef, parentRef] = useScaleRef<HTMLDivElement>({
      onScale: (newWidth) => {
        const newOrientation = calculateGameOrientation(newWidth);

        setOrientation(newOrientation);
      },
      scaleRegionRef,
    });

    if (!shouldScale) {
      return <>{children}</>;
    }

    return (
      <ScaleParent ref={parentRef}>
        <GridContainer orientation={orientation} ref={scaleRef}>
          {children}
        </GridContainer>
      </ScaleParent>
    );
  }
);

export default GameScaleContainer;
