function getQueryParams() {
  try {
    const queryString = window.location.search;

    return new URLSearchParams(queryString);
  } catch (err) {
    console.error(err);
    return new URLSearchParams();
  }
}

export default getQueryParams;
