import { ITile } from "../models/Tile";
import getAreaClueNeighborDirections from "./getAreaClueNeighborDirections";
import getAreaClueLocations from "./getAreaClueLocations";
import getLocationsByType from "./getLocationsByType";
import { Location, Matrix } from "./types";
import { DIRECTIONS } from "./directions";
import getLocation from "./getLocation";
import areLocationsEqual from "./areLocationsEqual";

function getAllClueBorders(tiles: Matrix<ITile>, location: Location): string[] {
  const tile = getLocation(tiles, location);

  if (!tile.isRevealed) {
    return [];
  }

  const allClueLocations = getLocationsByType(tiles, "clue-area");

  const directions = new Set<string>();

  // More than one area clue can contribute borders to a cell
  // if the cell is contained within multiple areas.
  allClueLocations.forEach((clueLocation) => {
    const areaLocations = getAreaClueLocations(tiles, clueLocation);
    const isLocationInArea = areaLocations.some((locationInArea) =>
      areLocationsEqual(location, locationInArea),
    );

    if (!isLocationInArea) {
      // Border directions can only be added for
      // an area this location is contained within.
      return;
    }

    const currentClueDirections = new Set<string>([
      DIRECTIONS.UP,
      DIRECTIONS.DOWN,
      DIRECTIONS.LEFT,
      DIRECTIONS.RIGHT,
    ]);

    const neighborDirections = getAreaClueNeighborDirections(
      location,
      areaLocations,
    );
    neighborDirections.forEach((direction) =>
      currentClueDirections.delete(direction),
    );

    currentClueDirections.forEach((direction) => directions.add(direction));
  });

  return [...directions];
}

export default getAllClueBorders;
