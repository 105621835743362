const _temp0 = require("./Caved -Title_SVG.svg");
const _temp1 = require("./Caved-Icon-Exclamation_SVG.svg");
const _temp2 = require("./Caved-Icon-Hash_SVG.svg");
const _temp3 = require("./Caved-Title-Element-Desktop-BottomLeft-NoNumbers.svg");
const _temp4 = require("./Caved-Title-Element-Desktop-BottomLeft.png");
const _temp5 = require("./Caved-Title-Element-Desktop-BottomRight-NoNumbers.svg");
const _temp6 = require("./Caved-Title-Element-Desktop-BottomRight_SVG.svg");
const _temp7 = require("./Caved-Title-Element-Desktop-TopLeft-NoNumbers.svg");
const _temp8 = require("./Caved-Title-Element-Desktop-TopLeft_SVG.svg");
const _temp9 = require("./Caved-Title-Element-Desktop-TopRight-NoNumbers.svg");
const _temp10 = require("./Caved-Title-Element-Desktop-TopRight_SVG.svg");
const _temp11 = require("./Caved-Title-Element-Mobile-BottomRight-NoNumbers.svg");
const _temp12 = require("./Caved-Title-Element-Mobile-Bottom_SVG.svg");
const _temp13 = require("./Caved-Title-Element-Mobile-TopLeft-NoNumbers.svg");
const _temp14 = require("./Caved-Title-Element-Mobile-Top_SVG.svg");
const _temp15 = require("./Caved-icon-close-x_SVG.svg");
const _temp16 = require("./FTUE_Slide_2.svg");
const _temp17 = require("./FTUE_Slide_3.svg");
const _temp18 = require("./FTUE_Slide_4.svg");
const _temp19 = require("./FTUE_Slide_5.svg");
const _temp20 = require("./FTUE_Slide_6.svg");
const _temp21 = require("./Icon - Check Circle - default.svg");
const _temp22 = require("./WinScreenMedallion.svg");
const _temp23 = require("./area-clue.svg");
const _temp24 = require("./arrow-counterclockwise.svg");
const _temp25 = require("./arrow-down.svg");
const _temp26 = require("./arrow-left.svg");
const _temp27 = require("./arrow-right.svg");
const _temp28 = require("./arrow-up.svg");
const _temp29 = require("./badge-gold-empty.svg");
const _temp30 = require("./badge-gold.svg");
const _temp31 = require("./badge-gray.svg");
const _temp32 = require("./brand.png");
const _temp33 = require("./brand@2x.png");
const _temp34 = require("./brand@3x.png");
const _temp35 = require("./crack.svg");
const _temp36 = require("./crystal.svg");
const _temp37 = require("./exclamation.svg");
const _temp38 = require("./flag-fill.svg");
const _temp39 = require("./ic_add_circle_outline_24px.svg");
const _temp40 = require("./ic_keyboard_arrow_left_24px.svg");
const _temp41 = require("./ic_settings_24px.svg");
const _temp42 = require("./ic_share_24px.svg");
const _temp43 = require("./loading.png");
const _temp44 = require("./loading@2x.png");
const _temp45 = require("./loading@3x.png");
const _temp46 = require("./play-button.svg");
const _temp47 = require("./skull.svg");
const _temp48 = require("./star-fill.svg");
module.exports = {
  "Caved -Title_SVG.svg": _temp0,
  "Caved-Icon-Exclamation_SVG.svg": _temp1,
  "Caved-Icon-Hash_SVG.svg": _temp2,
  "Caved-Title-Element-Desktop-BottomLeft-NoNumbers.svg": _temp3,
  "Caved-Title-Element-Desktop-BottomLeft.png": _temp4,
  "Caved-Title-Element-Desktop-BottomRight-NoNumbers.svg": _temp5,
  "Caved-Title-Element-Desktop-BottomRight_SVG.svg": _temp6,
  "Caved-Title-Element-Desktop-TopLeft-NoNumbers.svg": _temp7,
  "Caved-Title-Element-Desktop-TopLeft_SVG.svg": _temp8,
  "Caved-Title-Element-Desktop-TopRight-NoNumbers.svg": _temp9,
  "Caved-Title-Element-Desktop-TopRight_SVG.svg": _temp10,
  "Caved-Title-Element-Mobile-BottomRight-NoNumbers.svg": _temp11,
  "Caved-Title-Element-Mobile-Bottom_SVG.svg": _temp12,
  "Caved-Title-Element-Mobile-TopLeft-NoNumbers.svg": _temp13,
  "Caved-Title-Element-Mobile-Top_SVG.svg": _temp14,
  "Caved-icon-close-x_SVG.svg": _temp15,
  "FTUE_Slide_2.svg": _temp16,
  "FTUE_Slide_3.svg": _temp17,
  "FTUE_Slide_4.svg": _temp18,
  "FTUE_Slide_5.svg": _temp19,
  "FTUE_Slide_6.svg": _temp20,
  "Icon - Check Circle - default.svg": _temp21,
  "WinScreenMedallion.svg": _temp22,
  "area-clue.svg": _temp23,
  "arrow-counterclockwise.svg": _temp24,
  "arrow-down.svg": _temp25,
  "arrow-left.svg": _temp26,
  "arrow-right.svg": _temp27,
  "arrow-up.svg": _temp28,
  "badge-gold-empty.svg": _temp29,
  "badge-gold.svg": _temp30,
  "badge-gray.svg": _temp31,
  "brand.png": _temp32,
  "brand@2x.png": _temp33,
  "brand@3x.png": _temp34,
  "crack.svg": _temp35,
  "crystal.svg": _temp36,
  "exclamation.svg": _temp37,
  "flag-fill.svg": _temp38,
  "ic_add_circle_outline_24px.svg": _temp39,
  "ic_keyboard_arrow_left_24px.svg": _temp40,
  "ic_settings_24px.svg": _temp41,
  "ic_share_24px.svg": _temp42,
  "loading.png": _temp43,
  "loading@2x.png": _temp44,
  "loading@3x.png": _temp45,
  "play-button.svg": _temp46,
  "skull.svg": _temp47,
  "star-fill.svg": _temp48
}