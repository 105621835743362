import NeuzeitGroteskRegular from "../../../../assets/fonts/NeuzeitGroteskRegular.ttf";
import NeuzeitGroteskBold from "../../../../assets/fonts/NeuzeitGroteskBold.ttf";
import Montserrat from "../../../../assets/fonts/Montserrat-VariableFont_wght.ttf";
import MontserratItalic from "../../../../assets/fonts/Montserrat-Italic-VariableFont_wght.ttf";
import loadFont from "./loadFont";

async function loadFonts() {
  const fonts = [
    ["Neuzeit Grotesk", NeuzeitGroteskRegular],
    ["NeuzeitGro-Bol", NeuzeitGroteskBold],
    ["Montserrat", Montserrat],
    ["Montserrat-Italic", MontserratItalic],
  ];
  const loadPromises = fonts.map(([fontKey, fontSrc]) =>
    loadFont(fontKey, fontSrc)
  );

  return Promise.all(loadPromises);
}

export default loadFonts;
