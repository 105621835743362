import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import useSettings from "../../hooks/useSettings";
import { useGameStore, useRootStore } from "../../models/Root";
import Grid from "../Grid";
import StatusBar from "../StatusBar";
import Tile from "../Tile";
import areLocationsEqual from "../../utils/areLocationsEqual";
import useStartGame from "./useStartGame";
import useIsVictoryFinished from "./useIsVictoryFinished";
import GameScaleContainer from "./GameScaleContainer";
import withCurrentGame from "./withCurrentGame";
import TopBar from "./TopBar";
import styled from "styled-components";

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100vh;
  width: 100vw;

  padding: 1rem;

  & > *:nth-child(2) {
    flex-shrink: 1;
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export type GameProps = {
  onVictory?: () => void;
  shouldScale?: boolean;
};

const Game = observer(({ onVictory, shouldScale = true }: GameProps) => {
  const { app } = useRootStore();
  const game = useGameStore();
  const [settings] = useSettings();
  const scaleRegionRef = useRef<HTMLDivElement>(null);

  const finishAnimation = useIsVictoryFinished({ onVictory });
  useStartGame({ onVictory });

  return (
    <GameContainer>
      <TopBar />
      <div
        style={{ position: "relative", overflow: "hidden" }}
        ref={scaleRegionRef}
      >
        <GameScaleContainer
          shouldScale={shouldScale}
          scaleRegionRef={scaleRegionRef}
        >
          <StatusBar />
          <Grid
            renderTile={(tile, location, grid) => {
              return (
                <Tile
                  key={JSON.stringify(location)}
                  location={location}
                  onActionTaken={(isPrimaryAction) => {
                    if (game.state !== "running") {
                      return;
                    }

                    // If the timer isn't running on first click,
                    // start it up.
                    game.ensureTimerRunning();

                    app.playSound("tileClick");

                    if (isPrimaryAction) {
                      game.takePrimaryAction(location, settings, onVictory);
                    } else {
                      game.takeSecondaryAction(location, settings, onVictory);
                    }
                  }}
                  onMouseEnter={() => {
                    grid.setHovered(location);
                  }}
                  onMouseLeave={() => {
                    if (
                      grid.hovered &&
                      areLocationsEqual(grid.hovered, location)
                    ) {
                      grid.setHovered(null);
                    }
                  }}
                  victoryAnimDelay={(location.row + location.col) * 100}
                  onVictoryAnimFinished={() => {
                    finishAnimation();
                  }}
                />
              );
            }}
          />
        </GameScaleContainer>
      </div>
    </GameContainer>
  );
});

export type GameType = typeof Game;

export default withCurrentGame(Game);
