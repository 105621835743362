import { flow, getSnapshot, types, clone } from "mobx-state-tree";
import LevelFileModel from "./LevelFile";
import ConfigFileModel from "./ConfigFile";
import {
  AmuState,
  EventGameMessageType,
  IAmuMessage,
  IAmuFile,
  UserSettingsSnapshotDetails,
} from "../utils/amu/AmuMessage";
import { parseISO } from "date-fns";
import parseConfigFile from "../utils/amu/parseConfigFile";

const LoadingStatus = types.optional(
  types.enumeration(["initial", "loading", "done", "error"]),
  "initial",
);
const OnEventModel = types.maybe(types.array(EventGameMessageType));

const LoadingModel = types
  .model({
    // TODO:
    // Check for asset preloading status here too (fonts & images)
    levelFile: types.model({
      status: LoadingStatus,
      file: types.maybe(LevelFileModel),
    }),
    configFile: types.model({
      status: LoadingStatus,
      file: types.maybe(ConfigFileModel),
    }),
    settings: types.maybe(UserSettingsSnapshotDetails),
    amuState: types.maybe(AmuState),
    onEvent: OnEventModel,
    levelIssueDate: types.maybe(types.Date),
  })
  .views((self) => ({
    getLevelTiles() {
      if (self.levelFile.status !== "done") {
        return;
      }

      if (!self.levelFile.file) {
        // This should not happen while
        // state is "done", but we need
        // to guard against it for TypeScript
        // anyway.
        return;
      }

      return getSnapshot(self.levelFile.file.tiles);
    },
    getLevelStartLocation() {
      if (self.levelFile.status !== "done") {
        return;
      }

      if (!self.levelFile.file) {
        // This should not happen while
        // state is "done", but we need
        // to guard against it for TypeScript
        // anyway.
        return;
      }

      return clone(self.levelFile.file.startLocation);
    },
  }))
  .actions((self) => ({
    loadFiles: flow(function* loadFiles(files: IAmuFile[]) {
      const loadPromises = [];
      const fetchPromises = files.map((file) => file.fetch());

      loadPromises.push(...fetchPromises);

      yield Promise.all(fetchPromises);
    }),
    parseJsonFile(fileKey: "levelFile" | "configFile", files: IAmuFile[]) {
      const didAnyFileFailToLoad = files.some((file) => file.state === "error");

      if (didAnyFileFailToLoad) {
        self[fileKey].status = "error";
      } else {
        // We only use the first file received.
        const fileJson = files[0].toJson();

        if (fileJson === null) {
          self[fileKey].status = "error";
        } else {
          if (fileKey === "configFile") {
            const config = parseConfigFile(fileJson);
            self[fileKey].file = ConfigFileModel.create({ config });
          } else if (fileKey === "levelFile") {
            self[fileKey].file = fileJson;
          }

          self[fileKey].status = "done";
        }
      }
    },
    clearState() {
      self.amuState = undefined;
    },
  }))
  .actions((self) => ({
    handleAmuMessage: flow(function* initGame(
      message: IAmuMessage,
      onComplete: any,
    ) {
      if (message.loadLevel) {
        self.levelFile.status = "loading";
        self.levelIssueDate = parseISO(message.loadLevel.issueDate);
        yield self.loadFiles(message.loadLevel.files);
        self.parseJsonFile("levelFile", message.loadLevel.files);
      }

      if (message.loadConfig) {
        self.configFile.status = "loading";
        yield self.loadFiles(message.loadConfig.files);
        self.parseJsonFile("configFile", message.loadConfig.files);
      }

      // This is JSON blob, no need for async loading
      if (message.loadSettings) {
        try {
          self.settings = clone(message.loadSettings);
        } catch (err) {
          console.error("loadSettings format was invalid", err);
        }
      }

      // This is JSON blob, no need for async loading
      if (message.loadState) {
        try {
          self.amuState = clone(message.loadState);
        } catch (err) {
          console.error("loadState format was invalid", err);
        }
      }

      if (message.onEvent) {
        try {
          // TODO:
          // I don't understand why I need to create a model from a snapshot
          // here instead of just assigning to the property.
          // I thought it would be a "re-using" the mst node error, but that
          // should've been remedied by using clone(). That didn't resolve
          // the issue though!
          self.onEvent = OnEventModel.create(getSnapshot(message.onEvent));
        } catch (err) {
          console.error("onEvent format was invalid", err);
        }
      }

      onComplete();
    }),
    resetForLoadLevel() {
      self.levelFile.status = "initial";
      self.levelFile.file = undefined;
      self.levelIssueDate = undefined;

      // If we're told to loadLevel, we should also
      // be told to loadState. Current state is no
      // longer valid.
      self.clearState();
    },
  }));

export default LoadingModel;
