import { useEffect, useMemo, useState } from "react";

const useWindowOrientation = (): "portrait" | "landscape" => {
  const isPortraitQuery = useMemo(
    () => window.matchMedia("(orientation: portrait)"),
    [window],
  );
  const [orientation, setOrientation] = useState<"portrait" | "landscape">(
    isPortraitQuery.matches ? "portrait" : "landscape",
  );

  useEffect(() => {
    if (!isPortraitQuery) {
      return;
    }

    isPortraitQuery.onchange = (event) => {
      setOrientation(event.matches ? "portrait" : "landscape");
    };
  }, [isPortraitQuery]);

  return orientation;
};

export default useWindowOrientation;
