import { getSnapshot } from "mobx-state-tree";
import GridModel from "../models/Grid";
import { ITile, ITileSnapshotIn } from "../models/Tile";
import addLocations from "./addLocations";
import areLocationsEqual from "./areLocationsEqual";
import constructMatrix from "./constructMatrix";
import convertTemplateToMatrix from "./convertTemplateToMatrix";
import {
  DIRECTIONS,
  DIRECTION_LOCATIONS,
  getDirectionLocation,
} from "./directions";
import fillMatrix from "./fillMatrix";
import isValidCluePlacementLocation from "./generation/isValidCluePlacementLocation";
import getAreaClueLocations from "./getAreaClueLocations";
import getColLocations from "./getColLocations";
import getIdentifyingClueLocations from "./getIdentifyingClueLocations";
import getLocation from "./getLocation";
import getLocationsByLens from "./getLocationsByLens";
import getLocationsByType from "./getLocationsByType";
import getLocationsInDirection from "./getLocationsInDirection";
import getLocationsInDirectionUntil from "./getLocationsInDirectionUntil";
import getNeighborLocations from "./getNeighborLocations";
import getRowLocations from "./getRowLocations";
import intersectionByLens from "./intersectionByLens";
import isLocationInBounds from "./isLocationInBounds";
import isTreasureSatisfied from "./isTreasureSatisfied";
import pickRandomlyFromArray from "./random/pickRandomlyFromArray";
import shuffle from "./random/shuffle";
import subtractLocations from "./subtractLocations";
import { Location, Matrix } from "./types";
import randIntBetween from "./random/randIntBetween";

function generateInitialTiles2({
  width = 10,
  height = 15,
  treasureCount = 5,
  bombCount = 15,
} = {}): ITileSnapshotIn[][] | undefined {
  const clueWeightMap = ["arrow", "arrow", "area"];

  const initialTiles = fillMatrix<ITileSnapshotIn>(
    {
      type: "empty",
      arrowDirection: undefined,
      isRevealed: false,
    },
    {
      width,
      height,
    },
  );
  const grid = GridModel.create({
    tiles: initialTiles,
  });

  const getAllLocationsByType = (type: string) => () =>
    getLocationsByType(grid.tiles, type);

  const getAllEmptyLocations = getAllLocationsByType("empty");

  // TODO: randomly pick clue types
  let placedTreasureCount = 0;
  while (placedTreasureCount < treasureCount) {
    const firstClueType = "arrow";
    const firstClueLocation = pickRandomlyFromArray(getAllEmptyLocations());

    // Don't allow clue arrows on the edge to face the edge of the board.
    // TODO: This can still result in fail cases. Need to ensure valid
    //       placement of treasure is possible in a direction to not rule
    //       it out. getLocationsInDirection?
    //       ex. An arrow pointing down at (col: 0, row: 2) and an arrow
    //           pointing left at (col: 1, row: 2). Still impossible to place
    //           a treasure, but less likely than old implementation.
    const validDirections = Object.keys(DIRECTIONS).filter((direction) => {
      if (firstClueLocation.row === 0 && direction === "UP") {
        return false;
      }

      if (firstClueLocation.row === height - 1 && direction === "DOWN") {
        return false;
      }

      if (firstClueLocation.col === 0 && direction === "LEFT") {
        return false;
      }

      if (firstClueLocation.col === width - 1 && direction === "RIGHT") {
        return false;
      }

      return true;
    });
    const firstClueDirection = pickRandomlyFromArray(validDirections);

    if (!firstClueLocation) {
      // We ran out of valid locations
      console.error("Ran out of valid locations to place clues");
      return;
    }

    // two scenarios
    // - if second arrow randomly points at existing treasure already
    //   as it intersects with first arrow, no new treasure can be
    //   placed there.
    // - this means we should disallow this type of second arrow
    //   generation as a validLocation
    // - HOWEVER, this is one more scenario where an apparently valid
    //   firstClue location is actually not valid. We'll need to add this
    //   check before we even try to lock in the first arrow location too.

    let validLocations: Location[] = [];
    switch (firstClueDirection) {
      case DIRECTIONS.UP:
        validLocations = constructMatrix((location) => location, {
          width,
          height: firstClueLocation.row,
        })
          .flat()
          .filter((location) => location.col !== firstClueLocation.col)
          .filter((location) => {
            // TODO:
            // This location is not valid if there is already a
            // treasure at the intersection of this location, its
            // future direction, and the original arrow.
            return true;
          });
        break;
      case DIRECTIONS.DOWN:
        validLocations = constructMatrix(
          (location) =>
            addLocations({ row: firstClueLocation.row + 1, col: 0 }, location),
          {
            width,
            height: height - firstClueLocation.row,
          },
        )
          .flat()
          .filter((location) => location.col !== firstClueLocation.col);
        break;
      case DIRECTIONS.LEFT:
        validLocations = constructMatrix((location) => location, {
          width: firstClueLocation.col + 1,
          height,
        })
          .flat()
          .filter((location) => location.row !== firstClueLocation.row);
        break;
      case DIRECTIONS.RIGHT:
        validLocations = constructMatrix(
          (location) =>
            addLocations({ row: 0, col: firstClueLocation.col }, location),
          {
            width: width - firstClueLocation.col,
            height,
          },
        )
          .flat()
          .filter((location) => location.row !== firstClueLocation.row);
        break;
    }

    validLocations = validLocations
      .filter((location) => isLocationInBounds(grid.tiles, location))
      .filter((location) => {
        const tile = getLocation(grid.tiles, location);

        return tile.type === "empty";
      });
    // TODO: add another filter toggleable as a setting
    //       that can filter out any empty squares with
    //       non-zero neighporing poi counts.

    const secondClueLocation = pickRandomlyFromArray(validLocations);

    let secondClueDirection: string = "";
    switch (firstClueDirection) {
      case DIRECTIONS.UP:
      case DIRECTIONS.DOWN:
        if (secondClueLocation.col < firstClueLocation.col) {
          secondClueDirection = DIRECTIONS.RIGHT;
        } else {
          secondClueDirection = DIRECTIONS.LEFT;
        }
        break;
      case DIRECTIONS.LEFT:
      case DIRECTIONS.RIGHT:
        if (secondClueLocation.row < firstClueLocation.row) {
          secondClueDirection = DIRECTIONS.DOWN;
        } else {
          secondClueDirection = DIRECTIONS.UP;
        }
        break;
    }

    const secondClueType = "arrow";

    const firstValidLocations = getLocationsInDirection(
      firstClueLocation,
      DIRECTION_LOCATIONS[firstClueDirection],
      grid.tiles,
    );
    const secondValidLocations = getLocationsInDirection(
      secondClueLocation,
      DIRECTION_LOCATIONS[secondClueDirection],
      grid.tiles,
    );
    const intersectingLocations = intersectionByLens<Location>(
      areLocationsEqual,
    )(firstValidLocations, secondValidLocations);

    const treasureLocation = pickRandomlyFromArray(intersectingLocations);

    const firstClue = getLocation<ITile>(grid.tiles, firstClueLocation);
    firstClue.update({
      type: "clue-arrow",
      arrowDirection: firstClueDirection,
    });

    const secondClue = getLocation<ITile>(grid.tiles, secondClueLocation);
    secondClue.update({
      type: "clue-arrow",
      arrowDirection: secondClueDirection,
    });

    const treasure = getLocation<ITile>(grid.tiles, treasureLocation);
    treasure.update({
      type: "treasure",
    });

    placedTreasureCount += 1;
  }

  // TODO: remove invalid bomb locations (I think there are none
  //       while we have hardcoded everything to be arrows only)
  const allEmptyLocations = getAllEmptyLocations();
  const shuffledEmptyLocations = shuffle(allEmptyLocations);
  const bombLocations = shuffledEmptyLocations.slice(0, bombCount);
  bombLocations.forEach((bombLocation) => {
    const bomb = getLocation<ITile>(grid.tiles, bombLocation);
    bomb.update({
      type: "bomb",
    });
  });

  return getSnapshot(grid.tiles);
}

function pickClueType(areaClueWeight: number) {
  const choice = randIntBetween(1, 100);

  if (choice < areaClueWeight) {
    return "area";
  }

  return "arrow";
}

function generateInitialTiles3({
  width = 10,
  height = 15,
  treasureCount = 5,
  bombCount = 15,
  areaClueWeight = 25,
} = {}): ITileSnapshotIn[][] | undefined {
  const initialTiles = fillMatrix<ITileSnapshotIn>(
    {
      type: "empty",
      arrowDirection: undefined,
      isRevealed: false,
    },
    {
      width,
      height,
    },
  );
  const grid = GridModel.create({
    tiles: initialTiles,
  });

  const getAllLocationsByType = (type: string) => () =>
    getLocationsByType(grid.tiles, type);

  const getAllEmptyLocations = getAllLocationsByType("empty");
  const getAllTreasureLocations = getAllLocationsByType("treasure");

  let iterations = 0;
  while (iterations < 200) {
    iterations += 1;

    const treasureLocations = getAllTreasureLocations();
    const satisfiedTreasureLocations = treasureLocations.filter(
      (treasureLocation) => isTreasureSatisfied(grid.tiles, treasureLocation),
    );
    const areAllTreasuresSatisfied =
      satisfiedTreasureLocations.length === treasureLocations.length;

    if (areAllTreasuresSatisfied) {
      if (treasureLocations.length === treasureCount) {
        break;
      }

      const treasureLocation = pickRandomlyFromArray(getAllEmptyLocations());
      const treasure = getLocation<ITile>(grid.tiles, treasureLocation);
      treasure.update({
        type: "treasure",
        satisfactionGoal: pickRandomlyFromArray(["2-clue", "1-clue"]),
      });
      continue;
    }

    const unSatisfiedTreasureLocations = treasureLocations.filter(
      (treasureLocation) => !isTreasureSatisfied(grid.tiles, treasureLocation),
    );
    const treasureLocation = pickRandomlyFromArray(
      unSatisfiedTreasureLocations,
    );

    const clueTypePostfix = pickClueType(areaClueWeight);
    const clueType = `clue-${clueTypePostfix}`;
    switch (clueType) {
      case "clue-arrow":
        let rightLocations = getLocationsInDirection(
          treasureLocation,
          DIRECTION_LOCATIONS.RIGHT,
          grid.tiles,
        );
        if (
          rightLocations.some((location) => {
            const tile = getLocation(grid.tiles, location);
            return (
              tile.type === "clue-arrow" &&
              tile.arrowDirection === DIRECTIONS.LEFT
            );
          })
        ) {
          rightLocations = [];
        }
        let leftLocations = getLocationsInDirection(
          treasureLocation,
          DIRECTION_LOCATIONS.LEFT,
          grid.tiles,
        );
        if (
          leftLocations.some((location) => {
            const tile = getLocation(grid.tiles, location);
            return (
              tile.type === "clue-arrow" &&
              tile.arrowDirection === DIRECTIONS.RIGHT
            );
          })
        ) {
          leftLocations = [];
        }
        let upLocations = getLocationsInDirection(
          treasureLocation,
          DIRECTION_LOCATIONS.UP,
          grid.tiles,
        );
        if (
          upLocations.some((location) => {
            const tile = getLocation(grid.tiles, location);
            return (
              tile.type === "clue-arrow" &&
              tile.arrowDirection === DIRECTIONS.DOWN
            );
          })
        ) {
          upLocations = [];
        }
        let downLocations = getLocationsInDirection(
          treasureLocation,
          DIRECTION_LOCATIONS.DOWN,
          grid.tiles,
        );
        if (
          downLocations.some((location) => {
            const tile = getLocation(grid.tiles, location);
            return (
              tile.type === "clue-arrow" &&
              tile.arrowDirection === DIRECTIONS.UP
            );
          })
        ) {
          downLocations = [];
        }

        const possibleEmptyLocations = [
          ...rightLocations,
          ...leftLocations,
          ...upLocations,
          ...downLocations,
        ].filter((location) => {
          return isValidCluePlacementLocation(grid.tiles, location);
        });

        const clueLocation = pickRandomlyFromArray(possibleEmptyLocations);
        const clue = getLocation<ITile>(grid.tiles, clueLocation);
        let arrowDirection = DIRECTIONS.DOWN;
        if (clueLocation.row < treasureLocation.row) {
          arrowDirection = DIRECTIONS.DOWN;
        }
        if (clueLocation.row > treasureLocation.row) {
          arrowDirection = DIRECTIONS.UP;
        }
        if (clueLocation.col < treasureLocation.col) {
          arrowDirection = DIRECTIONS.RIGHT;
        }
        if (clueLocation.col > treasureLocation.col) {
          arrowDirection = DIRECTIONS.LEFT;
        }
        clue.update({
          type: "clue-arrow",
          arrowDirection,
        });
        break;
      case "clue-area": {
        const areaShape = `
          . . . . .
          . . . . .
          . . x . .
          . . . . .
          . . . . .
        `;
        const areaMatrix = convertTemplateToMatrix(areaShape);
        const [startOffset] = getLocationsByLens((tile) => tile === "x")(
          areaMatrix,
        );
        const areaOffsets = getLocationsByLens((tile) => tile === ".")(
          areaMatrix,
        );

        const relativeAreaOffsets = areaOffsets.map((offset) =>
          subtractLocations(offset, startOffset),
        );

        const attemptedTreasureOffset = pickRandomlyFromArray(areaOffsets);
        const relativeOffsetToStart = subtractLocations(
          startOffset,
          attemptedTreasureOffset,
        );
        const clueLocation = addLocations(
          relativeOffsetToStart,
          treasureLocation,
        );

        if (!isLocationInBounds(grid.tiles, clueLocation)) {
          // This placement is invalid! Try again!
          continue;
        }
        const clue = getLocation<ITile>(grid.tiles, clueLocation);

        if (!isValidCluePlacementLocation(grid.tiles, clueLocation)) {
          // This placement is invalid! Try again!
          continue;
        }

        clue.update({
          type: "clue-area",
          areaOffsetLocations: relativeAreaOffsets,
        });
        break;
      }
      default:
        break;
    }
  }

  // Start bomb placement
  const allEmptyLocations = getAllEmptyLocations();
  const shuffledEmptyLocations = shuffle(allEmptyLocations);
  /**
   * We cannot place any bombs adjacent to clues. This would cause clues
   * to generate where a "number clue" should be calculated instead.
   */
  let validLocations = shuffledEmptyLocations.filter((location) => {
    const neighbors = getNeighborLocations(grid.tiles, location);
    const isAnyNeighborClue = neighbors.some((neighborLocation) => {
      const neighbor = getLocation(grid.tiles, neighborLocation);
      return neighbor.type === "clue-area" || neighbor.type === "clue-arrow";
    });
    return !isAnyNeighborClue;
  });

  const treasureLocations = getLocationsByType(grid.tiles, "treasure");
  treasureLocations.forEach((treasureLocation) => {
    const identifyingClueLocations = getIdentifyingClueLocations(
      grid.tiles,
      treasureLocation,
    );
    const identifyingClues = identifyingClueLocations.map((clueLocation) =>
      getLocation(grid.tiles, clueLocation),
    );

    const arrowDirections: string[] = [];
    let areaCount = 0;
    identifyingClues.forEach((clue) => {
      switch (clue.type) {
        case "clue-arrow":
          if (clue.arrowDirection) {
            arrowDirections.push(clue.arrowDirection);
          }
          break;
        case "clue-area":
          areaCount += 1;
          break;
        default:
          break;
      }
    });

    let verticalCount = 0;
    let horizontalCount = 0;
    arrowDirections.forEach((direction) => {
      switch (direction) {
        case DIRECTIONS.UP:
        case DIRECTIONS.DOWN:
          verticalCount += 1;
          break;
        case DIRECTIONS.LEFT:
        case DIRECTIONS.RIGHT:
          horizontalCount += 1;
          break;
        default:
          break;
      }
    });

    const arePerpendicularArrowsPresent =
      verticalCount > 0 && horizontalCount > 0;

    if (!arePerpendicularArrowsPresent) {
      if (arrowDirections.length === 0) {
        // If there are no arrows identifying treasure, we must
        // have one area be totally empty of bombs.
        const areaClueLocation = pickRandomlyFromArray(
          identifyingClueLocations,
        );
        const areaLocations = getAreaClueLocations(
          grid.tiles,
          areaClueLocation,
        );

        validLocations = validLocations.filter(
          (validLocation) =>
            !areaLocations.some((removedLocation) =>
              areLocationsEqual(removedLocation, validLocation),
            ),
        );
        return;
      }

      const allIdentifiedLocations = identifyingClueLocations.map(
        (clueLocation) => {
          const clue = getLocation(grid.tiles, clueLocation);

          switch (clue.type) {
            case "clue-arrow":
              if (!clue.arrowDirection) {
                return [];
              }

              return getLocationsInDirection(
                clueLocation,
                getDirectionLocation(clue.arrowDirection),
                grid.tiles,
              );
            case "clue-area":
              return getAreaClueLocations(grid.tiles, clueLocation);
            default:
              return [];
          }
        },
      );

      const intersection = allIdentifiedLocations.reduce(
        (currentIntersection, currentClueLocations) => {
          return intersectionByLens(areLocationsEqual)(
            currentIntersection,
            currentClueLocations,
          );
        },
      );

      validLocations = validLocations.filter(
        (validLocation) =>
          !intersection.some((removedLocation) =>
            areLocationsEqual(removedLocation, validLocation),
          ),
      );
    }
  });

  const bombLocations = validLocations.slice(0, bombCount);
  bombLocations.forEach((bombLocation) => {
    const bomb = getLocation<ITile>(grid.tiles, bombLocation);
    bomb.update({
      type: "bomb",
    });
  });

  return getSnapshot(grid.tiles);
}

export { generateInitialTiles2, generateInitialTiles3 };
// export default generateInitialTiles;
