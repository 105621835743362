import isIFramedByOtherSite from "./isIFramedByOtherSite";

function getParentOrigin() {
  if (isIFramedByOtherSite()) {
    // Cannot access window.parent.location.href
    // when IFramed, use document.referrer instead
    return new URL(document.referrer).origin;
  }

  return window.location.origin;
}

export default getParentOrigin;
