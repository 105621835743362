import { IGrid } from "../../models/Grid";
import getLocation from "../getLocation";
import getLocationsByType from "../getLocationsByType";

function getAllClues(grid: IGrid) {
  const arrowClues = getLocationsByType(grid.tiles, "clue-arrow");
  const areaClues = getLocationsByType(grid.tiles, "clue-area");
  const clueLocations = [...arrowClues, ...areaClues];

  const clues = clueLocations.map((location) =>
    getLocation(grid.tiles, location),
  );

  return clues;
}

export default getAllClues;
