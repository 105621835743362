import { z } from "zod";
import AmuMessage, { IAmuMessageSnapshotIn } from "./AmuMessage";
import { types } from "mobx-state-tree";
import { areHintsEnabledDefaultValue } from "./constants";

const AmuFile = z.object({
  name: z.string(),
  url: z.string(),
  mimeType: z.string(),
  originalFileName: z.string(),
});

const LoadLevelCommand = z.object({
  issueDate: z.string(),
  files: z.array(AmuFile),
});

// TPS game level settings
const LoadConfigCommand = z.object({
  name: z.string(),
  files: z.array(AmuFile),
});

export const UserSettingsSnapshot = z
  .object({
    isMuted: z.boolean().default(false),
    isOnboardingComplete: z.boolean().default(false),
    areHintsEnabled: z.boolean().default(areHintsEnabledDefaultValue),
  })
  .default({});

export type UserSettingsSnapshotType = z.infer<typeof UserSettingsSnapshot>;

// User level settings
const LoadSettingsCommand = z
  .object({
    snapshot: UserSettingsSnapshot,
  })
  .default({});

// Interop type for zod and mst schemas
export const MstUserSettingsSnapshot = types.custom<
  UserSettingsSnapshotType,
  UserSettingsSnapshotType
>({
  name: "UserSettingsSnapshot",
  fromSnapshot(value: UserSettingsSnapshotType): UserSettingsSnapshotType {
    return UserSettingsSnapshot.parse(value);
  },
  toSnapshot(value: UserSettingsSnapshotType) {
    return value;
  },
  isTargetType(value: string | UserSettingsSnapshotType): boolean {
    return UserSettingsSnapshot.safeParse(value).success;
  },
  getValidationMessage(value: UserSettingsSnapshotType): string {
    const result = UserSettingsSnapshot.safeParse(value);

    if (result.success) {
      return "";
    } else {
      return result.error.message;
    }
  },
});

/**
 * Used to recreate current game progress. snapshot is
 * flexible and can store whatever is needed by the
 * game, including nested objects, arrays, and other
 * types of data.
 */

// MATCH TO AmuMessage.ts GameStateSnapshot
export const GameStateSnapshot = z
  .object({
    appVersion: z.string(),
    levelIssueDateString: z.string(),
    hasDoneStartingRevealAnimation: z.boolean().default(false),
    grid: z.object({
      tiles: z.array(z.array(z.any())),
    }),
    actions: z.object({
      count: z.number(),
    }),
    timer: z.object({
      elapsedTimeMs: z.number(),
    }),
  })
  .strict();

const AmuState = z.object({
  snapshot: GameStateSnapshot.optional().catch(undefined),
  /**
   * true if the user cleared the game board. The default
   * value is false.
   *
   * This action is comparable to the user deleting all
   * answers they submitted, and it does not reset the game
   * timer, statistics like mistakes or hints, or
   * game settings.
   *
   * TODO:
   * This property is required, but I'm not sure what the
   * purpose of this value is...? Why would we store this
   * in state? I think it is unneeded for this game.
   */
  // clearedBoard: z.boolean().optional(),
  /**
   * true if the user completed this level and the game
   * is final. The default value is false.
   *
   * When emitting the "end" event, isCompleted should
   * be true.
   */
  isCompleted: z.boolean().default(false),
  /**
   * true if the user entered an incorrect value during
   * the game. The default value is false.
   */
  madeMistakes: z.boolean().default(false),
  /**
   * true if the user reset the level state, such as
   * when choosing to replay the level after completing
   * it. The default value is false.
   *
   * This action sets everything except for resetLevel
   * and game settings back to initial values, including
   * the game timer, statistics like madeMistakes or
   * usedHints, and any other level progress.
   *
   * When emitting the "reset" event, resetLevel should be true.
   */
  // resetLevel: z.boolean().optional(),
  score: z
    .object({
      value: z.union([z.string(), z.number()]).default(0),
      // formattedScore: z.string().default("0"),
    })
    .default({}),
  // In milliseconds
  totalPlayTime: z.number().default(0),
  // completionMode: z.enum(["Expert", "Casual"]).optional(),
  // differencesSpotted: z.number().optional(),
  // difficultyRating: z.number().optional(),
  // earnedPerfectScore: z.boolean().optional(),
  // enabledDarkMode: z.boolean().optional(),
  // lettersGuessed: z.array(z.string()).optional(),
  // modeChanged: z.boolean().optional(),
  // orderSolved: z.number().optional(),
  // scoreBonuses: z.string().optional(),
  // solvedLevelWithGuesses: z.boolean().optional(),
  // solvedThemeWithGuesses: z.boolean().optional(),
  // usedHints: z.boolean().optional(),
  // usedUndo: z.boolean().optional(),
  // wordsSolved: z.number().optional(),
});

const LoadStateCommand = AmuState;

const GameEventType = z.enum([
  "all",
  "start",
  "pause",
  "resume",
  "end",
  "replay",
  "print",
  "help",
  "change mode",
  "change settings",
  "spot difference",
  "solve word",
  "onboarding complete",
  "onboarding skip",
]);

// Init game MUST load a level
// Everything else is optional
const InitGameMessageSchema = z.object({
  initGame: z.literal(true),
  loadLevel: LoadLevelCommand,
  loadConfig: LoadConfigCommand.optional(),
  loadSettings: LoadSettingsCommand.optional(),
  loadState: LoadStateCommand.optional(),
  pauseGame: z.boolean().optional(),
  getState: z.boolean().optional(),
  onEvent: z.array(GameEventType).default(["all"]),
});

// Everything is optional on non
// init game messages.
const OtherMessageSchema = z.object({
  loadLevel: LoadLevelCommand.optional(),
  loadConfig: LoadConfigCommand.optional(),
  loadSettings: LoadSettingsCommand.optional(),
  loadState: LoadStateCommand.optional(),
  pauseGame: z.boolean().optional(),
  getState: z.boolean().optional(),
  onEvent: z.array(GameEventType).default(["all"]),
});

const IncomingMessageSchema = z.union([
  InitGameMessageSchema,
  OtherMessageSchema,
]);

type IncomingMessageType = z.infer<typeof IncomingMessageSchema>;

function parseAmuMessage(incomingMessage: any) {
  console.log("Trying to parse incoming message: ", { incomingMessage });

  // This function should filter out any unneeded properties of
  // the incomingMessage and create a safe AmuMessage for the rest
  // of the app to work with
  const messageSnapshot: IncomingMessageType =
    IncomingMessageSchema.parse(incomingMessage);

  console.log("Parsed AMU Message: ", { messageSnapshot });

  // Using ts-ignore to disable typechecking here
  // Seems like more headache than it's worth
  // to get to the bottom of mobx-state-tree types
  // @ts-ignore
  const message = AmuMessage.create(messageSnapshot);

  return message;
}

export default parseAmuMessage;
