import { ITile } from "../models/Tile";
import getLocationsByLens from "./getLocationsByLens";
import { Matrix } from "./types";

function createTypeLens(type: string) {
  return (tile: ITile) => tile.type === type;
}

function getLocationsByType(tiles: Matrix<ITile>, type: string) {
  const typeLens = createTypeLens(type);

  return getLocationsByLens(typeLens)(tiles);
}

export default getLocationsByType;
