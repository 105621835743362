import { IGrid } from "../models/Grid";
import { ITile } from "../models/Tile";
import calcNeighboringPoiCount from "./calcNeighboringPoiCount";
import createFloodFill from "./createFloodFill";
import floodableTypes from "./floodableTypes";

function createFloodFillForGrid(grid: IGrid) {
  const floodFill = createFloodFill<ITile>(
    (tile) => {
      if (!floodableTypes.includes(tile.type)) {
        return false;
      }

      if (tile.isFlagged) {
        return false;
      }

      return true;
    },
    (tile, locationUnderTest) => {
      const neighboringPoiCount = calcNeighboringPoiCount(
        grid.tiles,
        locationUnderTest,
      );

      return neighboringPoiCount === 0;
    },
  );

  return floodFill;
}

export default createFloodFillForGrid;
