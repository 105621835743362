// TODO: This seems to function correctly as Parcel
// suggests it should.
//
// However, TypeScript has no concept that this glob
// import is being handled fine by Parcel.
// I guess Parcel is fixing this up for TypeScript
// in an earlier phase, and the actual build works
// fine.
// However, the version of TSC that's running in
// vscode isn't being updated by Parcel and is
// complaining.
//
// It would be nice to remove this error in a
// recommended way.
// @ts-ignore
import * as images from "../../../assets/images/*";

function preloadImages() {
  const imageValues = Object.values<string>(images);
  const imageElements = imageValues.map((imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    return img;
  });

  const loadPromise = new Promise<void>((resolve) => {
    const intervalId = setInterval(() => {
      if (imageElements.every((img) => img.complete)) {
        clearInterval(intervalId);
        resolve();
      }
    }, 100);
  });

  return loadPromise;
}

export default images;
export { preloadImages };
