import { ITile } from "../models/Tile";
import getAreaClueLocations from "./getAreaClueLocations";
import getLocation from "./getLocation";
import { Location, Matrix } from "./types";

function calcAreaValue(tiles: Matrix<ITile>, location: Location) {
  const area = getLocation(tiles, location);
  const locationsInArea = getAreaClueLocations(tiles, location);
  const treasureCount = locationsInArea.reduce((count, locationInArea) => {
    const tile = getLocation(tiles, locationInArea);

    if (tile.type === "treasure") {
      return count + 1;
    }

    return count;
  }, 0);

  return treasureCount;
}

export default calcAreaValue;
