function rkMedian<T>(this: Array<T>) {
  const arrayCopy = [...this.values()];

  arrayCopy.sort(function (a, b) {
    return Number(a) - Number(b);
  });

  const midpoint = Math.floor(arrayCopy.length / 2);

  if (arrayCopy.length % 2) return arrayCopy[midpoint];

  return (Number(arrayCopy[midpoint - 1]) + Number(arrayCopy[midpoint])) / 2.0;
}

export default rkMedian;
