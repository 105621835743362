import { stripIndents } from "common-tags";
import { Location, Matrix } from "./types";

function convertTemplateToMatrix(template: string): Matrix<string> {
  const strippedTemplate = stripIndents(template);
  const rows = strippedTemplate.split("\n");
  const matrix = rows.map((row) => row.trim().split(" "));

  return matrix;
}

export default convertTemplateToMatrix;
